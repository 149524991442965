@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.page-fade-out,
.side-navigation-wrapper,
.thumbnail .overlay-info,
.wrapper {
  -webkit-backface-visibility: hidden;
}

.wrapper-inner,
body,
html {
  max-width: 100%;
}

.column > :first-child {
  margin-top: 0;
}

.row {
  margin-top: 0;

  &.flex {
    &.boxes > .v-align-top {
      align-self: flex-start;
    }

    > .v-align-top {
      align-self: flex-start;

      > .box {
        align-self: flex-start;
      }
    }
  }
}

.thumbnail .caption-over-inner.v-align-top {
  align-self: flex-start;
}

.overlay-navigation ul li a,
.side-navigation ul li a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  word-wrap: break-word;
}

#tml-caption,
.tms-caption {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.tmh-perspective,
.tms-perspective {
  perspective: 800px;
}

html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  background: #fff;
  color: #666;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1.8;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
button,
input,
select,
textarea {
  touch-action: manipulation;
}

.button,
button,
input,
select {
  line-height: 1.4;
}

.page-fade-reset {
  opacity: 1;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.page-fade-out {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.scroll-to-top {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.fixed {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 50%;
    background-color: #333;
    content: "\e63e";
    text-align: center;
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    top: auto;
    z-index: 999;
  }
}

.row,
.wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.scroll-to-top.fixed a {
  color: #fff;
}

.wrapper-inner {
  margin-right: auto;
  margin-left: auto;
}

.row,
body.boxed .wrapper-inner {
  max-width: 114rem;
}

.row {
  margin-bottom: 0;
}

.ie-browser .row {
  max-width: 1140px;
}

.row {
  &.full-width {
    max-width: 100%;
  }

  .row {
    width: auto;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
}

.column,
[class*="content-grid"] .grid-item {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  float: left;
}

.row {
  &.collapse > .column {
    padding-left: 0;
    padding-right: 0;
  }

  &.full-width.collapse .row {
    margin: auto;
  }

  &.flex {
    &.v-align-middle {
      height: 100%;
      display: flex;
      align-items: center;

      > .column > div {
        width: initial;
      }
    }

    &.v-align-top {
      align-items: flex-start;
    }

    &.v-align-bottom {
      align-items: flex-end;
    }
  }
}

.ie-browser:not(.webkit) .row.flex {
  &.v-align-middle {
    height: 100%;
    display: table;
    align-items: center;
    flex-wrap: nowrap;

    > .column {
      display: table-cell;
      vertical-align: middle;
      float: none;
    }
  }

  &.v-align-top {
    vertical-align: top;
  }

  &.v-align-bottom {
    vertical-align: bottom;
  }
}

.row {
  &.flex {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: initial;

    &.boxes {
      > {
        .column,
        .v-align-middle {
          height: auto;
          display: inherit;
        }
      }

      > .v-align-bottom {
        align-self: flex-end;
      }
    }

    > .v-align-middle {
      height: auto;
      display: inherit;

      > .box {
        height: auto;
        display: inherit;
      }
    }

    > {
      .v-align-bottom {
        align-self: flex-end;

        > .box {
          align-self: flex-end;
        }
      }

      .column > .box > div {
        margin-top: auto;
        margin-bottom: auto;
      }

      .v-align-top > .box > div {
        margin-top: 0;
        margin-bottom: auto;
      }

      .v-align-bottom > .box > div {
        margin-top: auto;
        margin-bottom: 0;
      }

      .column > div:not(.row) {
        width: 100%;
        flex-direction: column;
      }
    }

    .row.flex {
      flex-direction: row;
    }

    &:after,
    &:before {
      content: "";
      display: none;
    }
  }

  &.small {
    padding: 3rem 0;
  }

  &.medium {
    padding: 5rem 0;
  }

  &.large {
    padding: 7rem 0;
  }

  &.xlarge {
    padding: 9rem 0;
  }
}

@media only screen and (min-width: 768px) {
  .row.flex {
    .v-align-middle > :not(.box):not([class*="icon-"]),
    &.boxes .v-align-middle > div > :not(.box):not([class*="icon-"]) {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .ie-browser:not(.webkit) .flex > .column {
    align-items: center;
  }
}

.width-1 {
  width: 8.33333%;
}

.width-2 {
  width: 16.66667%;
}

.width-3 {
  width: 25%;
}

.width-4 {
  width: 33.33333%;
}

.width-5 {
  width: 41.66667%;
}

.width-6 {
  width: 50%;
}

.width-7 {
  width: 58.33333%;
}

.width-8 {
  width: 66.66667%;
}

.width-9 {
  width: 75%;
}

.width-10 {
  width: 83.33333%;
}

.width-11 {
  width: 91.66667%;
}

.width-12 {
  width: 100%;
}

.column {
  &[class*="push-"],
  &[class*="pull-"] {
    position: relative;
  }
}

.push-1 {
  left: 8.33333%;
}

.push-2 {
  left: 16.66667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.33333%;
}

.push-5 {
  left: 41.66667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.33333%;
}

.push-8 {
  left: 66.66667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.33333%;
}

.push-11 {
  left: 91.66667%;
}

.pull-1 {
  right: 8.33333%;
}

.pull-2 {
  right: 16.66667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.33333%;
}

.pull-5 {
  right: 41.66667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.33333%;
}

.pull-8 {
  right: 66.66667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.33333%;
}

.pull-11 {
  right: 91.66667%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.alpha {
  padding-left: 0;
}

.omega {
  padding-right: 0;
}

.clear {
  width: 0;
  height: 0;
  display: block;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.clearfix {
  &:after,
  &:before {
    height: 0;
    content: ".";
    display: block;
    overflow: hidden;
  }
}

.row {
  &:after,
  &:before {
    height: 0;
    content: ".";
    display: block;
    overflow: hidden;
  }
}

.clearfix:after,
.row:after {
  clear: both;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center,
.navigation.nav-center > ul {
  text-align: center;
}

.text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

pre {
  white-space: pre-wrap;

  code {
    white-space: pre-wrap;
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
}

.v-align-top {
  align-self: flex-start !important;
  vertical-align: top !important;
}

.v-align-bottom {
  align-self: flex-end !important;
  vertical-align: bottom !important;
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.show {
  display: block !important;
  visibility: visible !important;
}

.mobile {
  .hide-on-mobile,
  .hide-on-tablet {
    display: none !important;
    visibility: visible !important;

    * {
      display: none !important;
      visibility: visible !important;
    }
  }
}

.inline-block,
[class*="bkg-"].inline-block,
[class*="border-"].inline-block {
  width: auto !important;
  display: inline-block !important;
}

.no-margins {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-auto {
  overflow: auto !important;
}

.no-scroll,
.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-scroll {
  overflow-x: auto !important;
}

.overflow-y-scroll {
  overflow-y: auto !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch !important;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto !important;
}

.clear-height {
  height: auto !important;
}

.full-width {
  width: 100% !important;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.freeze,
.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.nav-bar-inner,
.navigation ul li {
  position: relative;
}

.prototype {
  font-size: 1.1rem;
  line-height: 3.5rem;
}

.row.prototype .column {
  &:nth-child(odd) {
    background: #f9f9f9;
  }

  &:nth-child(even) {
    background: #eee;
  }
}

.nav-bar {
  width: 100%;
  min-height: 5rem;

  .logo {
    height: 5rem;
    line-height: 5rem;
    float: left;
    margin-right: 3rem;

    img {
      margin-top: -4px;
    }
  }

  .logo-right {
    margin-left: 3rem;
    margin-right: 0;
    float: right;
  }

  .logo.logo-center {
    margin: 0 auto;
    float: none;
  }
}

.navigation {
  &.nav-left {
    float: left !important;

    > ul > li:first-child > a {
      padding-left: 0;
      margin-left: 0;
    }
  }

  &.nav-right {
    float: right !important;
    margin-left: 2.5rem;

    > ul > li:last-child > a {
      padding-right: 0;
      margin-right: 0;
    }
  }

  &.nav-center {
    float: none !important;
    display: block;
  }

  &.nav-left {
    margin-right: 2.5rem;
  }

  &:last-child {
    margin: 0;
  }

  .dropdown .button {
    width: auto;
    min-width: auto;
  }

  .button [class*="icon-"] {
    margin-right: 0;
  }

  .dropdown-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0;
      float: left;

      &.contains-mega-sub-menu {
        position: inherit;
      }
    }
  }

  &.nav-center > ul > li {
    margin-left: -0.4rem;
    float: none;
    display: inline-block !important;
    vertical-align: top;
  }

  > ul > li > a {
    padding: 0 1.5rem;
    background-color: rgba(0, 0, 0, 0);
    line-height: 5rem;
  }

  .dropdown-list:not(.custom-content) li a,
  ul li > a:not(.button) {
    font-size: 1.1rem;
    color: #878787;
    text-decoration: none;
    display: block;
    word-wrap: break-word;
  }

  .dropdown-list li > a:not(.button):hover {
    background: 0 0;
    color: #fff;
  }

  ul li {
    > a:not(.button):hover {
      background: 0 0;
      color: #fff;
    }

    &:hover > .sub-menu {
      visibility: visible;
      opacity: 1;
    }
  }

  .dropdown-list,
  .mega-sub-menu,
  .sub-menu {
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    z-index: 101;
    line-height: 1.8;
    position: absolute;
  }

  ul li.current > a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .dropdown-list,
  .sub-menu {
    width: 18rem;
    text-align: left;
    background: #222;
    background: rgba(34, 34, 34, 1);
    border: none;
    border-radius: 0;
  }

  .dropdown-list li,
  .sub-menu li {
    background: 0 0;
    border-bottom: 1px solid #303030;
    float: none;
  }

  .dropdown-list.custom-content,
  .sub-menu.custom-content {
    width: auto;
    min-width: 25rem;
  }

  .dropdown-list.custom-content {
    padding: 2rem;
  }

  .sub-menu.custom-content {
    .custom-content-inner {
      padding: 2rem;
    }

    ul {
      padding: 0 2rem 2rem;

      &:first-child {
        padding-top: 2rem;
      }

      &[class*="bkg-"] {
        padding: 2rem;
      }
    }
  }

  .custom-content li:last-child :last-child {
    margin-bottom: 0;
  }

  > ul > li.sub-menu-left .sub-menu {
    left: 0;
  }

  .dropdown.pull-right .dropdown-list {
    right: 0;
  }

  > ul > li {
    &.sub-menu-right .sub-menu,
    &:last-child > .sub-menu {
      right: 0;
    }
  }

  .sub-menu ul {
    top: 0;
    left: 18rem;
  }

  > ul > li {
    &.sub-menu-right .sub-menu ul,
    &:last-child > .sub-menu ul {
      right: 18rem;
      left: auto;
    }
  }

  .dropdown-list:not(.custom-content) li a,
  .sub-menu:not(.custom-content) li a {
    padding: 1.2rem 1.5rem;
    color: #666;
  }

  .dropdown-list:not(.custom-content) li:last-child,
  .sub-menu:not(.custom-content) li:last-child {
    border-bottom: none;
  }

  .mega-sub-menu {
    height: auto;
    padding: 2rem 0 0;
    margin-left: 0;
    margin-right: 0;
    background: #222;
    background: rgba(34, 34, 34, 1);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    left: 1.5rem;
    right: 1.5rem;
  }
}

.mega-sub-menu.row {
  &:after,
  &:before {
    display: none;
    content: "";
  }
}

.navigation {
  .mega-sub-menu {
    > li {
      margin-bottom: 2rem;
      padding: 0 2rem;
      text-align: left;
      float: left;
      border-right: 1px solid #303030;

      &:not(.column) {
        width: 33.33333%;
      }

      > a:not(.button) {
        background: 0 0;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.8;
        text-transform: uppercase;
      }

      &:hover > a:not(.button) {
        background: 0 0;
      }

      &:last-child {
        border-right: none;
      }
    }

    ul li {
      p {
        font-size: 1.2rem;
        margin-bottom: 0;
        padding: 0 1.5rem 0.7rem;
      }

      .title {
        padding: 0 1.5rem;
      }
    }

    > li > a:not(.button) {
      padding: 0 1.5rem 0.7rem;
    }

    li.content-column ul li {
      padding: 0.6rem 1.5rem;
    }

    ul li {
      > a:not(.button) {
        padding: 0.6rem 1.5rem;
      }

      &.current > a:not(.button) {
        color: #fff;
      }

      &:hover > a:not(.button) {
        background: #333;
      }
    }

    li {
      ul > li {
        float: none;
      }

      &.content-column ul li > a:not(.button) {
        padding: 0;
      }
    }
  }

  ul li:hover > .mega-sub-menu {
    visibility: visible;
    opacity: 1;
  }

  .sub-menu li.contains-sub-menu > a:after {
    font-family: icomoon;
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    display: inline-block;
    position: absolute;
  }

  &.sub-menu-indicator > ul > li {
    &.contains-mega-sub-menu > a:after,
    &.contains-sub-menu > a:after {
      font-family: icomoon;
      font-size: inherit;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: inherit;
      display: inline-block;
      position: absolute;
    }

    &.contains-mega-sub-menu,
    &.contains-sub-menu {
      padding-right: 1.2rem;
    }

    &.contains-mega-sub-menu > a:after,
    &.contains-sub-menu > a:after {
      margin-left: 0.5rem;
      content: "\e63e";
    }
  }

  .sub-menu li.contains-sub-menu > a {
    padding-right: 2.2rem;

    &:after {
      right: 0.8rem;
      content: "\e640";
    }
  }

  .mega-sub-menu,
  .sub-menu {
    -webkit-transition-property: -webkit-transform, opacity, visibility, border-color;
    transition-property: transform, opacity, visibility, border-color;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }

  ul li a {
    -webkit-transition-property: background, border-color, color, line-height;
    transition-property: background, border-color, color, line-height;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
}

.aux-navigation {
  display: none;
}

.navigation-hide,
.navigation-show {
  width: 4.4rem;
  height: 4.4rem;
  text-align: center;
  float: right;
}

.navigation-hide a,
.navigation-show a {
  height: 4.4rem;
  text-align: center;
  display: block;
}

.navigation-hide span {
  width: 100%;
  line-height: 4.4rem;
}

.navigation-show {
  span {
    width: 100%;
    line-height: 4.4rem;
  }

  a {
    color: #666;

    &:hover {
      color: #fff;
    }
  }
}

.navigation-hide {
  position: relative;
  z-index: 10;

  a {
    color: #666;

    &:hover {
      color: #fff;
    }
  }
}

.mobile {
  .logo.logo-center {
    float: left;
  }

  header {
    .navigation:not(.secondary-navigation),
    .primary-nav-column {
      display: none !important;
    }

    .logo-column,
    .secondary-nav-column {
      width: auto !important;
    }

    .logo-column {
      float: left;
    }

    .secondary-nav-column {
      float: right;
    }
  }

  .aux-navigation {
    display: table !important;
    visibility: visible !important;
  }
}

.reveal-side-navigation > div:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0);
  content: "";
  opacity: 0;
  z-index: 110;
  -webkit-transition:
    opacity 0.3s,
    width 0s,
    height 0s;
  transition:
    opacity 0.3s,
    width 0s,
    height 0s;
}

.inactive.reveal-side-navigation > div:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.element-reveal-left {
  -webkit-transform: translate3d(35rem, 0, 0);
  transform: translate3d(35rem, 0, 0);
}

.element-reveal-right {
  -webkit-transform: translate3d(-35rem, 0, 0);
  transform: translate3d(-35rem, 0, 0);
}

.side-navigation-wrapper {
  width: 35rem;
  height: 100%;
  padding: 0;
  background: #292929;
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);

  &.active {
    visibility: visible;
  }

  &.enter-right {
    text-align: left;
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.6);
    right: 0;
    left: auto;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  &.no-scrollbar {
    overflow: hidden;
  }
}

.no-scrollbar .side-navigation-scroll-pane {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.side-navigation-inner {
  min-height: 100%;
  position: relative;
}

.side-navigation-wrapper {
  &.no-transition-reset {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  &.slide-in-reset {
    z-index: 104;
  }
}

.element-reveal-left.slide-in,
.element-reveal-right.slide-in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.side-navigation-wrapper {
  &.slide-in {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  &.push-in-reset {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }

  &.enter-right {
    &.push-in-reset {
      -webkit-transform: translate3d(60%, 0, 0);
      transform: translate3d(60%, 0, 0);
    }

    &.push-in,
    &.reveal-reset {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  &.push-in,
  &.reveal-reset {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  &.enter-right.scale-in-reset,
  &.scale-in-reset {
    -webkit-transform: translate3d(0, 0, 0) scale(0.9);
    transform: translate3d(0, 0, 0) scale(0.9);
  }

  &.enter-right.scale-in,
  &.scale-in {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.reveal-side-navigation {
  -webkit-transition:
    -webkit-transform 550ms,
    visibility 0s;
  transition:
    transform 550ms,
    visibility 0s;
}

.side-navigation-wrapper {
  -webkit-transition:
    -webkit-transform 550ms,
    visibility 0s;
  transition:
    transform 550ms,
    visibility 0s;

  &.hide {
    visibility: hidden;
    left: -35rem;
  }

  &.enter-right.hide {
    right: -35rem;
  }
}

.element-show-left {
  left: 35rem;
}

.element-show-right {
  left: -35rem;
}

.element-show-left,
.element-show-right {
  position: relative !important;
}

.side-navigation {
  width: 100%;

  &:after,
  &:before {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    &:not(.list-horizontal) li {
      width: 100%;
      display: block;
      margin-bottom: 0;
    }

    li {
      a {
        font-size: 1.2rem;
        line-height: 1;
        color: #999;
        display: block;
        -webkit-transition-property: background, color;
        transition-property: background, color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;

        &:hover {
          color: #fff;
        }
      }

      &.current > a {
        &:hover {
          color: #fff;
        }

        color: #fff;
        font-weight: 700;
      }
    }
  }

  > ul > li > a {
    padding: 1.3rem 4rem;
  }

  .sub-menu:not(.custom-content) a {
    padding: 1.1rem 4rem 1.1rem 5.5rem;
  }

  &.center .sub-menu:not(.custom-content) a {
    padding: 1.1rem 4rem;
  }

  .sub-menu:not(.custom-content) a {
    padding-left: 7rem;
  }

  &:not(.center) .sub-menu .sub-menu {
    padding-left: 1.5rem;
  }

  ul li:hover > .sub-menu {
    opacity: 1;
  }

  .sub-menu {
    overflow: hidden;
    height: 0;
    -webkit-transition-property: height;
    transition-property: height;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}

.overlay-navigation-inner {
  width: 100vw;
}

.overlay-navigation-wrapper {
  width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
  visibility: hidden;
  position: fixed;
  top: -100%;
  z-index: 101;
  -webkit-transition:
    -webkit-transform 550ms,
    opacity 550ms;
  transition:
    transform 550ms,
    opacity 550ms;

  &.active {
    visibility: visible;
    top: 0 !important;
  }

  &.no-scrollbar {
    overflow: hidden;
  }
}

.overlay-navigation-scroll-pane {
  height: 100%;
}

.no-scrollbar .overlay-navigation-scroll-pane {
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overlay-navigation-inner {
  height: 100%;
  display: table;
  position: relative;

  .v-align-middle {
    min-height: 100%;
    display: table-cell;
    vertical-align: middle;
  }
}

.overlay-navigation-wrapper {
  &.no-transition-reset {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  &.fade-in-reset {
    opacity: 0;
  }

  &.fade-in {
    opacity: 1;
  }

  &.slide-in-reset {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }

  &.enter-top.slide-in-reset {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  &.enter-right.slide-in-reset {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  &.enter-bottom {
    &.slide-in-reset {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      top: 100%;
      opacity: 0;
    }

    &.slide-in {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  &.enter-right.slide-in,
  &.enter-top.slide-in,
  &.slide-in {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.scale-in-reset {
    -webkit-transform: translate3d(0, 0, 0) scale(0.8);
    transform: translate3d(0, 0, 0) scale(0.8);
    opacity: 0;
  }

  &.scale-in {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}

.overlay-navigation {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail video,
.video-container,
img,
pre {
  max-width: 100%;
}

.overlay-navigation {
  &:after,
  &:before {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    &:not(.list-horizontal) li {
      width: 100%;
      display: block;
      margin-bottom: 0;
    }

    li {
      a {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #878787;
        display: block;
        -webkit-transition-property: background, color;
        transition-property: background, color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;

        &:hover {
          color: #fff;
        }
      }

      &.current > a {
        &:hover {
          color: #fff;
        }

        color: #fff;
        font-weight: 700;
      }
    }
  }

  > ul > li > a {
    padding: 0.6rem 0;
  }

  .sub-menu:not(.custom-content) a {
    padding: 1.1rem 4rem 1.1rem 5.5rem;
  }

  &.center .sub-menu:not(.custom-content) a {
    padding: 1.1rem 4rem;
  }

  .sub-menu {
    a {
      color: #666;
    }

    .sub-menu a {
      padding-left: 7rem;
    }
  }

  ul li:hover > .sub-menu {
    opacity: 1;
  }

  .sub-menu {
    overflow: hidden;
    height: 0;
    -webkit-transition-property: height, border-color;
    transition-property: height, border-color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
  border: none;
  border-radius: inherit;
  outline: 0;
}

.thumbnail.pull-left,
img.pull-left {
  margin: 0 1.5rem 1rem 0;
}

.thumbnail.pull-right,
img.pull-right {
  margin: 0 0 1.5rem 1rem;
}

.thumbnail {
  .background-image {
    width: 100%;
    height: 100%;
    display: block;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }

  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
}

.thumbnail-video {
  line-height: 0;
}

.thumbnail.rounded,
img.rounded {
  border-radius: 0.3rem;
}

.thumbnail.circle,
img.circle {
  border-radius: 50%;
}

.thumbnail {
  > {
    a,
    span {
      border-radius: inherit;
      float: left;
    }
  }

  video {
    height: auto;
    position: relative;
    z-index: 1;

    + img {
      position: absolute;
      top: 0;
      z-index: 0;
    }
  }

  &:hover {
    transform: none !important;
    transition: none !important;
  }

  &.background-image-container {
    width: 100%;
    min-height: 30rem;
  }
}

.caption-below {
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-style: italic;
  border: 1px solid #eee;
  float: left;
}

.thumbnail {
  .caption-over-outer {
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }

  .caption-over-inner {
    width: 100%;
    height: auto;

    &.v-align-bottom {
      align-self: flex-end;
    }

    * {
      flex: none;
    }

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.ie-browser:not(.webkit) .thumbnail {
  .caption-over-outer {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: table;
    opacity: 0;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }

  .caption-over-inner {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }
}

.thumbnail > {
  a,
  span {
    width: 100%;
    height: 100%;
    display: block;
    outline: 0;
    border: none;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
  }
}

.overlay-info {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: #fff;
  border: none;
  border-radius: inherit;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

a.overlay-info:hover {
  color: #fff;
}

.overlay-info > {
  div,
  span {
    width: 100%;
    height: 100%;
    padding: 3rem;
    font-size: 1.2rem;
    font-style: normal;
    display: flex;
    align-items: center;
  }

  div > div,
  span > span {
    width: 100%;
    height: auto;
  }
}

.ie-browser:not(.webkit) .overlay-info > {
  div,
  span {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    font-size: 1.2rem;
    font-style: normal;
    display: table;
  }

  div > div,
  span > span {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}

.fill-background {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.overlay-info {
  &.left > span > span {
    text-align: left;
  }

  &.right > span > span {
    text-align: right;
  }

  &.v-align-top > {
    div > div,
    span > span {
      align-self: flex-start;
      vertical-align: top;
    }
  }
}

.thumbnail .caption-over-inner.v-align-top {
  align-self: flex-start;
  vertical-align: top;
}

.overlay-info.v-align-bottom > {
  div > div,
  span > span {
    align-self: flex-end;
    vertical-align: bottom;
  }
}

.thumbnail {
  .caption-over-inner.v-align-bottom {
    align-self: flex-end;
    vertical-align: bottom;
  }

  .overlay-info {
    width: 100.1%;
    opacity: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.overlay-fade-img-scale-in .overlay-info,
.overlay-fade-img-scale-out .overlay-info,
.overlay-fade-out .overlay-info {
  opacity: 1;
}

.thumbnail:hover {
  .overlay-info {
    opacity: 1;
  }

  .background-image,
  .overlay-info,
  img {
    -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
  }
}

.overlay-fade-img-scale-in:hover .overlay-info,
.overlay-fade-img-scale-out:hover .overlay-info,
.overlay-fade-out:hover .overlay-info {
  opacity: 0;
}

.overlay-slide-in-top .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.overlay-slide-in-right .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.overlay-slide-in-bottom .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.overlay-slide-in-left .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.overlay-scale-in .overlay-info {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 1);
  transform: scale3d(0.1, 0.1, 1);
}

.overlay-rotate-in .overlay-info {
  opacity: 0;
  -webkit-transform: rotate3d(0.1, 0.1, 1, -180deg);
  transform: rotate3d(0.1, 0.1, 1, -180deg);
}

.overlay-img-slide-right {
  .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  &:hover {
    .background-image,
    img {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
}

.overlay-img-slide-left {
  .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  &:hover {
    .background-image,
    img {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
}

.overlay-img-slide-down {
  .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  &:hover {
    .background-image,
    img {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
}

.overlay-img-slide-up {
  .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  &:hover {
    .background-image,
    img {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }
}

.img-scale-in:hover {
  .background-image,
  img {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

.img-scale-out {
  .background-image,
  img {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

.overlay-fade-img-scale-in:hover {
  .background-image,
  img {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

.overlay-fade-img-scale-out {
  .background-image,
  img {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

.overlay-img-scale-in {
  &:hover {
    .background-image,
    img {
      opacity: 1;
      -webkit-transform: scale3d(1.2, 1.2, 1);
      transform: scale3d(1.2, 1.2, 1);
    }
  }

  .overlay-info {
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }
}

.thumbnail.shadow {
  -webkit-box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
}

[class*="content-grid"] {
  .grid-item {
    margin: 0 0 3rem;
    float: left;
  }

  .thumbnail {
    max-width: 100%;
    float: none;
  }
}

.content-grid-1 .grid-item {
  width: 100%;
}

.content-grid-2 .grid-item {
  width: 50%;
}

.content-grid-3 .grid-item {
  width: 33.33333%;
}

.content-grid-4 .grid-item {
  width: 25%;
}

.content-grid-5 .grid-item {
  width: 20%;
}

.content-grid-6 .grid-item {
  width: 16.66667%;
}

.row [class*="content-grid-"].no-margins {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

[class*="content-grid-"].no-margins {
  margin-right: 0 !important;
  margin-left: 0 !important;

  .grid-item {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.text-column {
  margin-bottom: 3rem;

  > :last-child {
    margin-bottom: 0;
  }
}

.feature-column-group .feature-column {
  padding: 7rem;
  margin-bottom: 0;
}

.feature-column {
  margin-bottom: 3rem;

  > :last-child {
    margin-bottom: 0;
  }
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
}

.feature-column.left .feature-icon {
  float: left;
}

.feature-text.left {
  padding-left: 5rem;
}

.feature-column.right .feature-text {
  padding-right: 5rem;
  padding-left: 0;
}

.feature-text :first-child {
  margin-top: 0;
}

.feature-column {
  &.small .feature-icon {
    font-size: 2rem;
  }

  &.left.small {
    .feature-text {
      padding-left: 4rem;
    }

    .icon-boxed,
    .icon-circled {
      width: 5.8rem;
      height: 5.8rem;
      line-height: 5.8rem;
    }

    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-left: 9rem;
    }
  }

  &.right.small {
    .feature-text {
      padding-right: 4rem;
      padding-left: 0;
    }

    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-right: 9rem;
      padding-left: 0;
    }
  }
}

.feature-colum.mediumn .feature-icon {
  font-size: 3rem;
}

.feature-column {
  &.left.medium .feature-text {
    padding-left: 5rem;
  }

  &.medium {
    .icon-boxed,
    .icon-circled {
      width: 6.6rem;
      height: 6.6rem;
      line-height: 6.6rem;
    }
  }

  &.left {
    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-left: 9.8rem;
    }

    &.medium {
      .icon-boxed + .feature-text,
      .icon-circled + .feature-text {
        padding-left: 9.8rem;
      }
    }
  }

  &.right {
    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-right: 9.8rem;
      padding-left: 0;
    }

    &.medium {
      .icon-boxed + .feature-text,
      .icon-circled + .feature-text {
        padding-right: 9.8rem;
        padding-left: 0;
      }
    }
  }

  &.large .feature-icon {
    font-size: 4rem;
  }

  &.left.large .feature-text {
    padding-left: 6rem;
  }

  &.large {
    .icon-boxed,
    .icon-circled {
      width: 7.4rem;
      height: 7.4rem;
      line-height: 7.4rem;
    }
  }

  &.left.large {
    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-left: 10.6rem;
    }
  }

  &.right.large {
    .feature-text {
      padding-right: 6rem;
      padding-left: 0;
    }

    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-right: 10.6rem;
      padding-left: 0;
    }
  }

  &.xlarge .feature-icon {
    font-size: 5rem;
  }

  &.left.xlarge .feature-text {
    padding-left: 7rem;
  }

  &.xlarge {
    .icon-boxed,
    .icon-circled {
      width: 8rem;
      height: 8rem;
      line-height: 7.8rem;
    }
  }

  &.left.xlarge {
    .icon-boxed + .feature-text,
    .icon-circled + .feature-text {
      padding-left: 12.2rem;
    }
  }

  &.right {
    &.xlarge {
      .feature-text {
        padding-right: 7rem;
        padding-left: 0;
      }

      .icon-boxed + .feature-text,
      .icon-circled + .feature-text {
        padding-right: 12.2rem;
        padding-left: 0;
      }
    }

    .feature-icon {
      float: right;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.center .feature-icon {
    display: inline-block;
    float: none;
    margin-left: auto;
    margin-right: auto;

    + .feature-text {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .feature-column.center-on-mobile .feature-icon {
    display: inline-block;
    float: none;
    margin-left: auto;
    margin-right: auto;

    + .feature-text {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

input,
textarea {
  width: 100%;
  padding: 1.3rem 2.5rem;
  margin: 0 0 1.5rem;
}

.checkbox-label ~ .checkbox-label,
.radio-label ~ .radio-label,
label.pull-right {
  margin-left: 1rem;
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
}

input {
  &[type="submit"] {
    width: auto;
  }

  &:focus {
    outline: 0;
  }
}

select:focus,
textarea:focus {
  outline: 0;
}

.disabled,
input:disabled,
select:disabled,
textarea:disabled {
  opacity: 0.6;
  cursor: default !important;
}

.disabled * {
  cursor: default !important;
}

.checkbox:disabled,
.radio:disabled {
  opacity: 0;
  cursor: default;
}

.form-element,
textarea {
  background: #f9f9f9;
  font-size: 1.3rem;
  color: #666;
  border: 1px solid #eee;
  border-radius: 0;
}

.form-element:focus {
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
  outline: 0;
}

.input-indication {
  .form-element:focus + .inherit-style,
  &.reverse .form-element:focus + .inherit-style {
    background: #fff;
    color: #333;
    border: 1px solid #ccc;
    box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
    outline: 0;
  }
}

textarea {
  &:focus {
    background: #fff;
    color: #333;
    border: 1px solid #ccc;
    box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
    outline: 0;
  }

  min-width: 10rem;
  min-height: 20rem;
}

.textarea-no-resize {
  resize: none;
}

label {
  margin-bottom: 0.8rem;
  color: #666;
  font-size: 1.2rem;
  display: block;

  &.pull-left,
  &.pull-right {
    margin-bottom: 0;
    padding: 1.4rem 0;
    line-height: normal;
    float: left;
  }

  &.pull-left {
    margin-right: 1rem;
  }

  &.emphasized {
    color: #666;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

select {
  width: 100%;
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
}

.form-select {
  width: 100%;
  padding: 0 !important;
  position: relative;

  select {
    width: 100%;
    padding: 1.3rem 2.5rem;
  }

  &:after {
    font-family: icomoon;
    font-size: 1.5rem;
    content: "\e63e";
    pointer-events: none;
    position: absolute;
    top: 1rem;
    right: 2rem;
  }
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

.checkbox,
.radio {
  opacity: 0;
  position: absolute;
}

.checkbox,
.checkbox-label,
.radio,
.radio-label {
  margin-bottom: 2rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox:disabled + .checkbox-label,
.radio:disabled + .radio-label {
  opacity: 0.6;

  &:before {
    opacity: 0.6;
  }
}

.checkbox-label,
.radio-label {
  color: #666;
  position: relative;
}

.checkbox + .checkbox-label:before,
.radio + .radio-label:before {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: middle;
  background: #f9f9f9;
  border: 1px solid #eee;
  content: "";
  font-family: icomoon;
  text-align: center;
}

.checkbox:checked + .checkbox-label,
.radio:checked + .radio-label {
  opacity: 1;
}

.checkbox:checked + .checkbox-label:before {
  content: "\e63a";
  background: #fff;
  color: #666;
}

.radio:checked + .radio-label:before {
  background: #666;
  box-shadow: inset 0 0 0 4px #fff;
}

.checkbox:focus + .checkbox-label,
.radio:focus + .radio-label {
  outline: 0;
}

.input-indication {
  margin: 0;
  display: flex;

  .form-element,
  .input-icon {
    margin: 0;
    display: flex;
  }
}

.button,
.button-content > span,
button {
  display: inline-block;
}

.input-indication {
  margin: 0 0 1.5rem;

  .input-icon {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    align-items: center;
  }

  &.reverse .form-element + .inherit-style,
  &:not(.reverse) .form-element:first-child {
    border-right: none !important;
  }

  &.reverse .form-element:first-child,
  &:not(.reverse) .form-element + .inherit-style {
    border-left: none !important;
  }

  &.reverse .form-element {
    select,
    &:first-child {
      padding-left: 0;
    }
  }

  &:not(.reverse) .form-element {
    select,
    &:first-child {
      padding-right: 0;
    }
  }
}

.checkbox.rounded + .checkbox-label:before,
.form-element.rounded,
textarea.rounded {
  border-radius: 0.3rem;
}

.radio + .radio-label:before {
  border-radius: 50%;
}

.form-element.pill {
  border-radius: 10.5rem;
}

@media only screen and (min-width: 768px) {
  .merged-form-elements {
    .column {
      + .column:not(:last-child),
      &:first-child {
        padding-right: 0;
      }

      + .column {
        .button,
        .form-element {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }

    .field-wrapper {
      overflow: visible;
    }

    .column {
      + .column,
      &:last-child {
        padding-left: 0;
      }

      &:not(:last-child) .form-element {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      + .column {
        .form-element:not(:focus) {
          border-left-color: transparent;
        }

        .button {
          width: 100%;
        }
      }

      .form-element:focus {
        position: relative;
        z-index: 1;
      }
    }
  }
}

.input-indication {
  &.reverse > :last-child,
  &:not(.reverse) > :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.reverse > :first-child,
  &:not(.reverse) > :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.form-element.small {
  padding: 1rem 1.5rem;
  font-size: 1.1rem;

  select {
    padding: 1rem 3.5rem 1rem 1.5rem;
    font-size: 1.1rem;
  }
}

.form-select.small:after {
  top: 0.5rem;
  right: 1rem;
}

.checkbox.small + .checkbox-label:before,
.radio.small + .radio-label:before {
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
}

.checkbox.small + .checkbox-label,
.radio.small + .radio-label {
  font-size: 1.1rem;
}

.form-element {
  &.medium {
    padding: 1.3rem 2.5rem;

    select {
      padding: 1.3rem 4.5rem 1.3rem 2.5rem;
    }
  }

  &.large {
    padding: 1.6rem 3rem;
    font-size: 1.4rem;

    select {
      padding: 1.6rem 5rem 1.6rem 3rem;
      font-size: 1.4rem;
    }
  }
}

.form-select.large:after {
  top: 1.4rem;
  right: 2.5rem;
}

.checkbox.large + .checkbox-label:before,
.radio.large + .radio-label:before {
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
}

.checkbox.large + .checkbox-label,
.radio.large + .radio-label {
  font-size: 1.4rem;
}

.form-element.xlarge {
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem;

  select {
    padding: 1.9rem 5.5rem 1.9rem 3.5rem;
    font-size: 1.5rem;
  }
}

.form-select.xlarge:after {
  top: 1.7rem;
  right: 3rem;
}

.checkbox.xlarge + .checkbox-label:before,
.radio.xlarge + .radio-label:before {
  width: 2.7rem;
  height: 2.7rem;
  line-height: 2.7rem;
}

.checkbox.xlarge + .checkbox-label,
.radio.xlarge + .radio-label {
  font-size: 1.5rem;
}

div[contenteditable="true"] {
  background: #f9f9f9;
  color: #666;
  border: 1px solid #eee;
  padding: 1.3rem 2.5rem;

  &:focus {
    background: #fff;
    color: #333;
    border: 1px solid #ccc;
    box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
    outline: 0;
  }

  &.small {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }

  &.medium {
    padding: 1.3rem 2.5rem;
  }

  &.large {
    padding: 1.6rem 3rem;
    font-size: 1.4rem;
  }

  &.xlarge {
    padding: 1.9rem 3.5rem;
    font-size: 1.5rem;
  }
}

.button-group {
  margin-bottom: 3rem;
}

.button,
button {
  padding: 1.3rem 2.5rem;
  margin: 0 1rem 1rem 0;
  font-family: inherit;
  font-size: 1.3rem;
  color: #666;
  background-color: #eee;
  border: 1px solid #eee;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.center {
  .button,
  button {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

.right {
  .button,
  button {
    margin-right: 0;
    margin-left: 1rem;
  }
}

form {
  .button,
  button {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.button:focus,
button:focus {
  outline: 0;
}

.button:hover,
button:hover {
  text-decoration: none;
  color: #666;
  background-color: #d0d0d0;
  border-color: #d0d0d0;
}

.button-small {
  .button,
  button {
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
  }
}

.button.small,
button.small {
  padding: 1rem 1.5rem !important;
  font-size: 1.1rem !important;
}

.button-medium {
  .button,
  button {
    padding: 1.3rem 2.5rem;
    font-size: 1.3rem;
  }
}

.button.medium,
button.medium {
  padding: 1.3rem 2.5rem !important;
  font-size: 1.3rem !important;
}

.button-large {
  .button,
  button {
    padding: 1.6rem 3rem;
    font-size: 1.4rem;
  }
}

.button.large,
button.large {
  padding: 1.6rem 3rem !important;
  font-size: 1.4rem !important;
}

.button-xlarge {
  .button,
  button {
    padding: 1.9rem 3.5rem;
    font-size: 1.5rem;
  }
}

.button.xlarge,
button.xlarge {
  padding: 1.9rem 3.5rem !important;
  font-size: 1.5rem !important;
}

.button [class*="icon-"],
button [class*="icon-"] {
  line-height: inherit;
}

.button [class*="icon-"].left,
button [class*="icon-"].left {
  margin-right: 0.5rem;
}

.button [class*="icon-"].right,
button [class*="icon-"].right {
  margin-left: 0.5rem;
}

.button-content small {
  display: block;
}

input[type="submit"]::-moz-focus-inner {
  border: 0 !important;
}

.button-rounded {
  .button,
  button {
    border-radius: 0.3rem;
  }
}

.button.rounded,
button.rounded {
  border-radius: 0.3rem !important;
}

.button-pill {
  .button,
  button {
    border-radius: 10.5rem;
  }
}

.button.pill,
button.pill {
  border-radius: 10.5rem !important;
}

.button.shadow,
button.shadow {
  -webkit-box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.3);
}

.dropdown-list,
.list-up .dropdown-list {
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.3);
}

.button.hard-shadow,
button.hard-shadow {
  border-bottom: 3px solid rgba(0, 0, 0, 0.15);
}

.button.disabled,
button.disabled {
  background-color: #f9f9f9;
  border-color: #eee;

  &:hover {
    background-color: #f9f9f9;
    border-color: #eee;
  }
}

.button.disabled,
button.disabled {
  cursor: default;
  color: #ccc;
}

.button-nav {
  padding: 0;
  margin: 0;
  list-style: none;

  > ul > li {
    margin: 0 1px 0 0;
    float: left;
  }

  li {
    .button {
      &.pill,
      &.rounded {
        border-radius: 0;
      }
    }

    button {
      &.pill,
      &.rounded {
        border-radius: 0;
      }
    }

    &:first-child {
      .button.rounded,
      button.rounded {
        border-radius: 0.3rem 0 0 0.3rem;
      }
    }
  }

  &.rounded li:first-child {
    .button,
    button {
      border-radius: 0.3rem 0 0 0.3rem;
    }
  }

  li:last-child .button.rounded .button-nav li:last-child button.rounded {
    border-radius: 0 0.3rem 0.3rem 0;
  }

  &.rounded li:last-child {
    .button,
    button {
      border-radius: 0 0.3rem 0.3rem 0;
    }
  }

  li:first-child {
    .button.pill,
    button.pill {
      border-radius: 10.5rem 0 0 10.5rem;
    }
  }

  &.pill li:first-child {
    .button,
    button {
      border-radius: 10.5rem 0 0 10.5rem;
    }
  }

  li:last-child {
    .button.pill,
    button.pill {
      border-radius: 0 10.5rem 10.5rem 0;
    }
  }

  &.pill li:last-child {
    .button,
    button {
      border-radius: 0 10.5rem 10.5rem 0;
    }
  }

  &.center li {
    margin-left: -0.3rem;
    display: inline-block;
    float: none;
  }
}

.dropdown {
  position: relative;
  margin-bottom: 1rem;

  .button,
  button {
    margin: 0;
    text-align: left;
  }

  &.disabled {
    .button {
      cursor: default;
      border-color: #ddd !important;
      background: #fff !important;
      color: #ddd !important;

      &:hover {
        border-color: #ddd !important;
        background: #fff !important;
        color: #ddd !important;
      }
    }

    button {
      border-color: #ddd !important;
      background: #fff !important;
      color: #ddd !important;

      &:hover {
        border-color: #ddd !important;
        background: #fff !important;
        color: #ddd !important;
      }
    }
  }
}

.dropdown-list {
  width: 16rem;
  margin: 0.3rem 0 0;
  text-align: left;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  opacity: 0;
  visibility: hidden;
  list-style: none;
  position: absolute;
  z-index: 101;
  -webkit-transition-property: -webkit-transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.right .dropdown-list {
  right: 0;
}

.dropdown-list:not(.custom-content) li a {
  padding: 0.6rem 2.5rem;
  display: block;
  font-size: 1.2rem;
  color: #666;
  font-weight: 400;
  cursor: pointer;
}

.list-horizontal li,
.list-inline li {
  display: inline-block;
}

.dropdown-list {
  li {
    a:hover {
      background: #f4f4f4;
      color: #333;
      text-decoration: none;
    }

    &.current a {
      background: #f4f4f4;
      color: #666;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .list-header {
    padding: 0.6rem 2.5rem;
    font-size: 1.1rem;
    font-weight: 700;
    background: #ddd;
  }
}

.dropdown {
  &.small {
    .button,
    button {
      padding: 1rem 1.5rem;
    }

    .dropdown-list {
      li a {
        padding: 0.4rem 1.5rem;
      }

      width: 14rem;
    }
  }

  &.medium {
    .button,
    button {
      padding: 1.3rem 2.5rem;
    }

    .dropdown-list {
      li a {
        padding: 0.6rem 2.5rem;
      }

      width: 16rem;
    }
  }

  &.large {
    .button,
    button {
      padding: 1.6rem 3rem;
    }

    .dropdown-list {
      li a {
        padding: 0.9rem 3rem;
      }

      width: 18rem;
    }
  }

  &.xlarge {
    .button,
    button {
      padding: 1.9rem 3.5rem;
    }

    .dropdown-list {
      li a {
        padding: 1.2rem 3.5rem;
      }

      width: 20rem;
    }
  }

  &.rounded {
    .button,
    button {
      border-radius: 0.3rem;
    }
  }
}

ol,
ul {
  margin: 0 0 3rem 2rem;
  padding: 0;
  font-size: 1.4rem;
}

dl {
  margin: 0 0 3rem;
}

dt {
  font-weight: 700;
}

dd {
  padding-left: 3rem;
  margin: 0 0 1rem;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;

  ol,
  ul {
    margin: 0.4rem 0 0.4rem 3rem;
  }
}

ul {
  ol,
  ul {
    margin: 0.4rem 0 0.4rem 3rem;
  }
}

.list-inline,
.list-unstyled {
  margin-left: 0;
  list-style: none;
}

.list-inline {
  &.right li {
    margin-left: 1rem;
  }

  &.center li {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &.left li {
    margin-right: 1rem;
  }
}

ol li > [class*="icon-"]:first-child,
ul li > [class*="icon-"]:first-child {
  margin-right: 1rem;
}

.list-group-dotted {
  border: none;
}

.list-group {
  &.dashed,
  &.solid {
    border: none;
  }

  margin-left: 0;
  list-style: none;

  li {
    padding: 1rem 0;
    border-bottom: 1px solid #eee;

    li {
      border-top: 1px solid #eee;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:last-child {
      border-bottom: none;
      border-width: 0;
    }
  }

  > li:first-child {
    padding-top: 0;
  }

  ol,
  ul {
    margin: 0;
  }

  &.boxed li {
    padding: 1rem 2rem;

    li:last-child {
      padding: 1rem 2rem;
      border-top: 1px solid;
    }
  }

  li ul {
    padding-top: 1rem;
  }

  &[class*="border-"] li {
    border-color: inherit !important;

    li,
    ul {
      border-color: inherit !important;
    }
  }

  li :last-child {
    margin-bottom: 0;
  }

  &.small {
    li {
      li {
        padding-left: 1.5rem;
        margin-top: 0;
      }

      padding: 0.7rem 0;
    }

    ol {
      margin-top: 0.7rem !important;
    }
  }
}

.list-groups.small ul {
  margin-top: 0.7rem !important;
}

.list-group.boxed.small li,
.list-groups.boxed.small li {
  padding: 0.7rem 1.5rem;
}

.list-group.medium {
  li {
    padding: 1rem 0;
  }

  ol {
    margin-top: 1rem !important;
  }
}

.list-groups.medium ul {
  margin-top: 1rem !important;
}

.list-group {
  &.boxed.medium li {
    padding: 1rem 2rem;
  }

  &.large {
    li {
      padding: 1.3rem 0;
    }

    ol {
      margin-top: 1.3rem !important;
    }
  }
}

.list-groups.large ul {
  margin-top: 1.3rem !important;
}

.list-group {
  &.boxed.large li {
    padding: 1.3rem 2.5rem;
  }

  &.xlarge {
    li {
      padding: 1.6rem 0;
    }

    ol {
      margin-top: 1.6rem !important;
    }
  }
}

.list-groups.xlarge ul {
  margin-top: 1.6rem !important;
}

.list-group {
  &.boxed.xlarge li {
    padding: 1.6rem 3rem;
  }

  &.dotted li {
    border-bottom-style: dotted;

    li {
      border-top-style: dotted;
    }
  }

  &.dashed li {
    border-bottom-style: dashed;

    li {
      border-top-style: dashed;
    }
  }

  &.thick li {
    border-bottom-width: 0.2rem;

    li {
      border-top-width: 0.2rem;
    }
  }

  &:not(.boxed) li:last-child {
    border-bottom: none;
    padding-bottom: 0 !important;
  }

  li li:last-child {
    border-top: none;
  }

  &.boxed {
    li {
      border: 1px solid;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid;
      }
    }

    &.dotted li {
      border-style: dotted;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px dotted;
      }

      li:last-child {
        border-top-style: dotted;
      }
    }

    &.dashed li {
      border-style: dashed;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px dashed;
      }

      li:last-child {
        border-top-style: dashed;
      }
    }

    &.thick li {
      border-width: 0.2rem;

      &:last-child {
        border-width: 0.2rem;
      }
    }
  }

  &.rounded li {
    &:first-child {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.3rem 0.3rem;
    }
  }

  &.boxed[class*="border-"] {
    border-width: 0;

    &.dashed,
    &.dotted,
    &.thick,
    &:hover {
      border-width: 0;
    }
  }

  &[class*="border-"] {
    border-width: 0;

    &.dashed,
    &.dotted,
    &.thick,
    &:hover {
      border-width: 0;
    }
  }
}

.list-horizontal li {
  list-style: none;
}

.menu-box {
  overflow: visible;
  margin-bottom: 3rem;
}

.menu-list {
  margin: 0 0 3rem;
  list-style: none;

  li {
    margin: 0 0 3rem;
    list-style: none;
  }

  h4,
  li:last-child,
  p {
    margin-bottom: 0;
  }

  &.boxed {
    padding: 2rem;
  }

  li {
    position: relative;
  }

  h4 + p {
    margin-top: 1.3rem;
  }

  .menu-content {
    padding-right: 10rem;
  }

  .menu-description,
  .menu-price,
  .menu-title {
    background-color: #fff;
  }

  .menu-content,
  .menu-description,
  .menu-price,
  .menu-title,
  h4 {
    position: relative;
    z-index: 1;
  }

  .menu-price {
    font-weight: 700;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.accordion > ul > li > a {
  font-weight: 400;
  line-height: 1.4;
  vertical-align: middle;
  color: #666;
  text-decoration: none;
}

.tabs .tab-nav > li {
  a,
  &.disabled a:hover {
    font-weight: 400;
    line-height: 1.4;
    vertical-align: middle;
    color: #666;
    text-decoration: none;
  }
}

.menu-title .menu-price {
  position: relative;
  right: auto;
  left: auto;
  bottom: auto;
}

.menu-list {
  .menu-line {
    height: 1px;
    margin: 0;
    border: 0;
    display: block;
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    z-index: 0;
    background-image: linear-gradient(to right, #ccc 100%, rgba(0, 0, 0, 0) 0);
    background-position: bottom;
    background-size: 6px 2px;
    background-repeat: repeat-x;
  }

  .callout.rounded {
    border-radius: 0.3rem;
  }

  &.rounded {
    border-radius: 0.3rem;

    .callout {
      border-radius: 0.3rem;
    }
  }

  &.menu-items-inline {
    .menu-content,
    h4 {
      display: inline;
    }
  }

  .callout {
    padding: 2rem;
    background-color: #f4f4f4;

    * {
      background-color: inherit;
    }
  }

  .special-item {
    font-size: 1.3rem;
    font-style: italic;
    text-transform: none;
  }

  h4 .menu-line {
    bottom: 4px;
  }

  .menu-content .menu-line {
    bottom: 8px;
  }
}

.menu-box.small,
.menu-list.boxed.small {
  padding: 1.5rem 1.5rem 0;
}

.menu-box.small .menu-list,
.menu-list.boxed.small li:last-child {
  margin-bottom: 1.5rem;
}

.menu-box.medium,
.menu-list.boxed.medium {
  padding: 2rem 2rem 0;
}

.menu-box.medium .menu-list,
.menu-list.boxed.medium li:last-child {
  margin-bottom: 2rem;
}

.menu-box.large,
.menu-list.boxed.large {
  padding: 2.5rem 2.5rem 0;
}

.menu-box.large .menu-list,
.menu-list.boxed.large li:last-child {
  margin-bottom: 2.5rem;
}

.menu-box.xlarge,
.menu-list.boxed.xlarge {
  padding: 3rem 3rem 0;
}

.menu-box.xlarge .menu-list {
  margin-bottom: 3rem;
}

.menu-list {
  &.boxed.xlarge li:last-child {
    margin-bottom: 3rem;
  }

  &.center {
    .menu-line {
      display: none;
    }

    .menu-price {
      position: relative;
    }

    .menu-content {
      padding: 0;
    }

    .menu-description {
      display: block;
    }
  }

  &.right {
    .menu-content {
      padding-left: 10rem;
      padding-right: 0;
    }

    .menu-price {
      left: 0;
      right: auto;
    }
  }

  &.boxed {
    border-style: solid;
    border-width: 1px;

    &.dashed {
      border-style: dashed;
    }

    &.dotted {
      border-style: dotted;
    }
  }
}

.dotted .menu-line {
  height: 2px;
  background-image: radial-gradient(circle closest-side, #ccc 99%, rgba(0, 0, 0, 0) 0);
}

.dashed .menu-line {
  height: 1px;
  background-image: linear-gradient(to right, #ccc 50%, rgba(0, 0, 0, 0) 0);
}

.timeline {
  width: 100%;
  margin: 0 0 3rem;
  list-style: none;
}

.timeline-item {
  padding-left: 3rem;
}

.timeline {
  .timeline-item {
    width: 100%;
    padding-left: 3rem;
    position: relative;
    text-align: left;
  }

  .timeline-content {
    padding-bottom: 4rem;
    position: relative;
  }

  .timeline-item {
    &:after,
    &:before {
      position: absolute;
      content: "";
    }
  }

  .timeline-section {
    &:before {
      position: absolute;
      content: "";
    }

    .timeline-content,
    .timeline-info {
      width: 100% !important;
      padding: 0;
    }
  }

  .timeline-item {
    &:last-child .timeline-content {
      padding-bottom: 0;
    }

    &:before {
      width: 1.4rem;
      height: 1.4rem;
      display: block;
      background: #7e57c2;
      border: 2px solid transparent;
      border-radius: 100%;
      top: 4px;
      left: -7px;
      transition:
        background 0.3s ease-in-out,
        border 0.3s ease-in-out;
    }

    &:after {
      width: 2px;
      background: #ddd;
      display: block;
      top: 2.4rem;
      bottom: 0;
      left: -1px;
    }
  }

  &.no-indication .timeline-item {
    &:after,
    &:before {
      display: none;
    }

    padding-left: 0;
  }

  &.right.no-indication .timeline-item {
    padding-right: 0;
  }

  .timeline-content :last-child {
    margin-bottom: 0;
  }

  .timeline-section {
    :last-child {
      margin-bottom: 0;
    }

    padding: 3rem 0 7rem !important;
  }

  &.right .timeline-section {
    padding: 3rem 0 7rem !important;
  }

  .timeline-section:before {
    width: 1.4rem;
    height: auto;
    background: 0 0 !important;
    border: none;
    border-radius: 0;
    top: 0;
    bottom: 4rem;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
  }
}

.tabs {
  &.right.vertical .tab-nav:not([class*="width-"]),
  &.vertical .tab-nav:not([class*="width-"]) {
    width: 25%;
  }
}

.timeline .timeline-section:after {
  content: "";
  height: 4rem;
  top: auto;
}

.accordion:after,
.tab-nav:after,
.tab-panes > div.active {
  content: ".";
}

.tabs {
  &.vertical:after {
    content: ".";
  }

  margin: 0 0 3rem;
  padding: 0;
}

.tab-nav {
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;

  &:after {
    height: 0;
    display: block;
    overflow: hidden;
    clear: both;
  }
}

.tabs {
  .tab-nav > li {
    margin: 0 1px 0 0;
    display: inline-block;
  }

  &.vertical {
    &:after {
      display: block;
      overflow: hidden;
      clear: both;
    }

    .tab-nav {
      > li {
        margin: 0 0 1px;
        display: block;
        float: none;
      }

      float: left;
    }
  }

  .tab-nav > li:last-child {
    margin-right: 0;
  }

  &.right {
    .tab-nav > li {
      margin: 0 0 0 1px;
    }

    &.vertical .tab-nav {
      > li {
        margin: 0 0 1px;
        float: none;
      }

      float: right;
    }
  }

  .tab-nav > li .active a {
    background: #fff;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid transparent;
    border-left: 1px solid #eee;
    cursor: default;

    &:hover {
      background: #fff;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid transparent;
      border-left: 1px solid #eee;
      cursor: default;
    }
  }

  &.vertical .tab-nav > li.active a {
    background: #fff;
    border-top: 1px solid #eee;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    cursor: default;
    position: relative;

    &:hover {
      background: #fff;
      border-top: 1px solid #eee;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #eee;
      border-left: 1px solid #eee;
      cursor: default;
      position: relative;
    }
  }

  &.right.vertical .tab-nav > li.active a {
    background: #fff;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid transparent;

    &:hover {
      background: #fff;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      border-left: 1px solid transparent;
    }
  }

  .tab-nav > li {
    a,
    &.disabled a:hover {
      padding: 1.3rem 2.5rem;
      margin: 0;
      font-size: 1.3rem;
      display: inline-block;
      background-color: #eee;
      border: 1px solid #eee;
      cursor: pointer;
    }
  }

  &.vertical .tab-nav > li a {
    display: block;
  }

  .tab-nav > li a:hover {
    text-decoration: none;
    color: #666;
    background: #d0d0d0;
    border-color: #d0d0d0;
  }
}

.tab-panes {
  margin-top: -1px;
  padding: 2.5rem;
  background: #fff;
  border: 1px solid #eee;
}

.tabs.vertical .tab-panes {
  float: left;

  &:not([class*="width-"]) {
    width: 75%;
    margin-top: 0;
    margin-left: -1px;
  }
}

.pricing-table.columns-1 .pricing-table-column,
.table {
  width: 100%;
}

.tabs.right.vertical .tab-panes {
  float: right;

  &:not([class*="width-"]) {
    margin-left: 0;
    margin-right: -1px;
  }
}

.tab-panes {
  > div {
    display: none;
    background: 0 0;
  }

  .tab-content {
    opacity: 0;
    -webkit-transition-property: opacity, visibility;
    transition-property: opacity, visibility;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
}

.accordion > ul > li > div,
.collapsable-target {
  -webkit-transition-duration: 0.3s;
  overflow: hidden;
}

.tab-panes {
  .animate-in .tab-content {
    opacity: 1;
  }

  > div {
    &.active {
      display: block;
      overflow: hidden;
      clear: both;
    }

    > :first-child {
      margin-top: 0;
    }

    :not(.form-element):not(.input-indication):not(.radio-label):not(.checkbox-label):last-child {
      margin-bottom: 0;
    }
  }
}

.tabs {
  &.small {
    .tab-nav > li a {
      padding: 1rem 1.5rem;
      font-size: 1.1rem;
    }

    .tab-panes {
      padding: 1.5rem;
    }
  }

  &.medium {
    .tab-nav > li a {
      padding: 1.3rem 2.5rem;
      font-size: 1.3rem;
    }

    .tab-panes {
      padding: 2.5rem;
    }
  }

  &.large {
    .tab-nav > li a {
      padding: 1.6rem 3rem;
      font-size: 1.4rem;
    }

    .tab-panes {
      padding: 3rem;
    }
  }

  &.xlarge {
    .tab-nav > li a {
      padding: 1.9rem 3.5rem;
      font-size: 1.5rem;
    }

    .tab-panes {
      padding: 3.5rem;
    }
  }

  &.rounded .tab-nav > li:first-child a {
    border-radius: 0.3rem 0 0;
  }

  &.right.rounded .tab-nav > li:first-child a,
  &.rounded .tab-nav > li:last-child a {
    border-radius: 0 0.3rem 0 0;
  }

  &.right.rounded .tab-nav > li:last-child a {
    border-radius: 0.3rem 0 0;
  }

  &.vertical.rounded .tab-nav > li:last-child a {
    border-radius: 0 0 0 0.3rem;
  }

  &.right.vertical.rounded .tab-nav > li:last-child a {
    border-radius: 0 0 0.3rem;
  }

  &.rounded .tab-panes {
    border-radius: 0 0.3rem 0.3rem;
  }

  &.right.rounded .tab-panes {
    border-radius: 0.3rem 0 0.3rem 0.3rem;
  }

  &.solid {
    .tab-nav > li.active a {
      border-style: solid;

      &:hover {
        border-style: solid;
      }
    }

    .tab-panes {
      border-style: solid;
    }
  }

  &.dashed,
  &.dotted {
    .tab-nav > li.active a {
      border-style: dashed;

      &:hover {
        border-style: dashed;
      }
    }

    .tab-panes {
      border-style: dashed;
    }
  }

  &.thick {
    .tab-nav > li.active a {
      border-width: 0.2rem;

      &:hover {
        border-width: 0.2rem;
      }
    }

    .tab-panes {
      border-width: 0.2rem;
    }
  }

  &[class*="border-"] {
    border: none;
  }
}

.collapsable-target {
  height: 0;
  -webkit-transition-property: height;
  transition-property: height;
  transition-duration: 0.3s;

  &:not(.include-margin) > :last-child {
    margin-bottom: 0 !important;
  }

  &.active {
    height: auto;
  }
}

.accordion {
  margin: 0 0 3rem;
  padding: 0;
  border: none !important;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &:after {
    height: 0;
    display: block;
    overflow: hidden;
    clear: both;
  }

  > ul > li {
    margin: 0 0 1px;
    display: block;

    &.active > a {
      background: 0 0;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid transparent;
      border-left: 1px solid #eee;
    }

    > a {
      padding: 1.3rem 2.5rem;
      margin: 0;
      font-size: 1.3rem;
      display: block;
      background-color: #eee;
      border: 1px solid #eee;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: #666;
        background: #d0d0d0;
        border-color: #d0d0d0;
      }
    }
  }
}

.table {
  &.striped tbody tr:nth-child(even) {
    td,
    th {
      background-color: #eee;
    }
  }

  > thead {
    background-color: #eee;
  }
}

.accordion {
  > ul > li {
    > div {
      height: 0;
      visibility: hidden;
      border: 1px solid #eee;
      -webkit-transition-property: height, visibility;
      transition-property: height, visibility;
      transition-duration: 0.3s;
    }

    &.active > div {
      height: auto;
      visibility: visible;
    }
  }

  .accordion-content {
    height: auto;
    padding: 2.5rem;
    overflow: hidden;
    display: block;
  }

  > ul > li > div {
    > :first-child {
      margin-top: 0;
    }

    :not(.form-element):not(.input-indication):not(.radio-label):not(.checkbox-label):last-child {
      margin-bottom: 0;
    }
  }

  &.small {
    li > a {
      padding: 1rem 1.5rem;
      font-size: 1.1rem;
    }

    .accordion-content {
      padding: 1.5rem;
    }
  }

  &.medium {
    li > a {
      padding: 1.3rem 2.5rem;
      font-size: 1.3rem;
    }

    .accordion-content {
      padding: 2.5rem;
    }
  }

  &.large {
    li > a {
      padding: 1.6rem 3rem;
      font-size: 1.4rem;
    }

    .accordion-content {
      padding: 3rem;
    }
  }

  &.xlarge {
    li > a {
      padding: 1.9rem 3.5rem;
      font-size: 1.5rem;
    }

    .accordion-content {
      padding: 3.5rem;
    }
  }

  &.rounded li {
    &:first-child > a {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    &:last-child > a,
    > div {
      border-radius: 0 0 0.3rem 0.3rem;
    }

    &.active:last-child > a:hover {
      border-radius: 0;
    }
  }

  &.solid li > {
    a,
    div {
      border-style: solid;
    }
  }

  &.dashed li > {
    a,
    div {
      border-style: dashed;
    }
  }

  &.dotted li > {
    a,
    div {
      border-style: dashed;
    }
  }

  &.thick li > {
    a,
    div {
      border-width: 0.2rem;
    }
  }
}

.table {
  border-width: 1px 1px 0;
  border-color: #eee;
  border-style: solid;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;

  > {
    tbody > tr > {
      td,
      th {
        padding: 1rem 1.5rem;
      }
    }

    tfoot > tr > {
      td,
      th {
        padding: 1rem 1.5rem;
      }
    }

    thead {
      > tr > {
        td,
        th {
          padding: 1rem 1.5rem;
        }
      }

      color: #666;
    }
  }

  td,
  th + th {
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  > thead > tr > th {
    border-bottom: none;
  }

  &.small > {
    tbody > tr > {
      td,
      th {
        padding: 0.7rem 1rem;
        font-size: 1rem;
      }
    }

    tfoot > tr > {
      td,
      th {
        padding: 0.7rem 1rem;
        font-size: 1rem;
      }
    }

    thead > tr > {
      td,
      th {
        padding: 0.7rem 1rem;
        font-size: 1rem;
      }
    }
  }

  &.medium > {
    tbody > tr > {
      td,
      th {
        padding: 1rem 1.5rem;
      }
    }

    tfoot > tr > {
      td,
      th {
        padding: 1rem 1.5rem;
      }
    }

    thead > tr > {
      td,
      th {
        padding: 1rem 1.5rem;
      }
    }
  }

  &.large > {
    tbody > tr > {
      td,
      th {
        padding: 1.3rem 2.5rem;
        font-size: 1.4rem;
      }
    }

    tfoot > tr > {
      td,
      th {
        padding: 1.3rem 2.5rem;
        font-size: 1.4rem;
      }
    }

    thead > tr > {
      td,
      th {
        padding: 1.3rem 2.5rem;
        font-size: 1.4rem;
      }
    }
  }

  &.xlarge > {
    tbody > tr > {
      td,
      th {
        padding: 1.6rem 3rem;
        font-size: 1.5rem;
      }
    }

    tfoot > tr > {
      td,
      th {
        padding: 1.6rem 3rem;
        font-size: 1.5rem;
      }
    }

    thead > tr > {
      td,
      th {
        padding: 1.6rem 3rem;
        font-size: 1.5rem;
      }
    }
  }

  &.rounded {
    border-radius: 0.3rem;
  }
}

.pricing-table {
  width: 100%;
  text-align: center;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.progress-bar {
  text-align: right;
}

.pricing-table {
  &:after,
  &:before {
    content: ".";
    display: block;
    height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  &:after {
    clear: both;
  }

  &.columns-2 .pricing-table-column {
    width: 50%;
  }

  &.columns-3 .pricing-table-column {
    width: 33.33333%;
  }

  &.columns-4 .pricing-table-column {
    width: 25%;
  }

  &.columns-5 .pricing-table-column {
    width: 20%;
  }
}

.pricing-table-column {
  float: left;
}

[class*="border-"] .pricing-table-column {
  border-width: 1px 1px 1px 0;
  border-style: solid;
}

.pricing-table-column {
  &:first-child {
    border-width: 1px;
  }

  > {
    :not(hr) {
      padding: 3rem;
    }

    :not(:first-child) {
      padding-top: 0;
    }

    [class*="bkg-"] {
      padding: 3rem;
    }

    hr {
      margin: 3rem;
      margin-top: 0;
    }
  }

  &.callout {
    margin-left: -1px;
    margin-bottom: -2rem;
    border: 1px solid #fff;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    position: relative;
    top: -1.5rem;
    z-index: 1;
  }
}

.box :not(.form-element):not(.form-submit):last-child {
  margin-bottom: 0;
}

.pricing-table-footer {
  .button,
  button {
    margin-bottom: 0;
  }
}

.pricing-table-text p:last-child {
  margin-bottom: 0;
}

.box.shadow,
.icon-boxed.shadow,
.icon-circled.shadow {
  -webkit-box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
}

.pricing-table-column.callout > {
  .pricing-table-header {
    padding-top: 4.5rem;
  }

  .pricing-table-footer {
    padding-bottom: 4.5rem;
  }
}

.pricing-table-header {
  h2 {
    margin: 0;
    font-size: 2.5rem;
  }

  &[class*="bkg-"] h2 {
    color: #fff;
  }
}

.pricing-table-price {
  color: #666;

  h4 {
    margin: 0;
    font-size: 3rem;
  }

  &[class*="bkg-"] h4 {
    color: #fff;
  }

  .currency {
    margin-right: 0.2rem;
    font-size: 1.3rem;
    position: relative;
    top: -1rem;
  }

  .interval {
    font-size: 1.3rem;
    opacity: 0.5;
  }
}

.pricing-table-options {
  margin: 0;
  list-style: none;
  line-height: inherit;

  ul {
    margin: 0;
    list-style: none;
    line-height: inherit;
  }

  del {
    opacity: 0.5;
    text-decoration: none;
  }

  li {
    margin-bottom: 0;
    padding: 0.7rem 0;
  }

  &.with-icon li:before {
    margin-right: 1rem;
    font-family: icomoon;
    content: "\e63a";
    float: left;
  }
}

.pricing-table-text {
  line-height: inherit;
}

.pricing-table {
  &[class*="border-"] {
    border-width: 0;

    .pricing-table-column {
      border-color: inherit !important;
    }
  }

  &.small {
    .pricing-table-header h2 {
      font-size: 2.5rem;
    }

    .pricing-table-price {
      .currency {
        font-size: 1.3rem;
        top: -1rem;
      }

      .interval {
        font-size: 1.1rem;
      }
    }
  }

  &.medium .pricing-table-header h2,
  &.small .pricing-table-price h4 {
    font-size: 3rem;
  }

  &.medium .pricing-table-price {
    .currency {
      font-size: 1.6rem;
      top: -1.4rem;
    }

    .interval {
      font-size: 1.3rem;
    }

    h4 {
      font-size: 3.6rem;
    }
  }

  &.large {
    .pricing-table-header h2 {
      font-size: 3rem;
    }

    .pricing-table-price {
      .currency {
        font-size: 1.6rem;
        top: -2rem;
      }

      .interval {
        font-size: 1.4rem;
      }

      h4 {
        font-size: 4.5rem;
      }
    }
  }

  &.xlarge {
    .pricing-table-header h2 {
      font-size: 3.2rem;
    }

    .pricing-table-price {
      .currency {
        font-size: 2rem;
        top: -2.7rem;
      }

      .interval {
        font-size: 1.6rem;
      }

      h4 {
        font-size: 6rem;
      }
    }
  }

  &.rounded .pricing-table-column {
    &:first-child {
      border-radius: 0.3rem 0 0 0.3rem;

      > div {
        &:first-child {
          border-top-left-radius: inherit;
        }

        &:left-child {
          border-bottom-left-radius: inherit;
        }
      }
    }

    &:last-child {
      border-radius: 0 0.3rem 0.3rem 0;

      > div {
        &:first-child {
          border-top-right-radius: inherit;
        }

        &:last-child {
          border-bottom-right-radius: inherit;
        }
      }
    }
  }
}

.pricing-table-column.rounded {
  border-radius: 0.3rem;
}

.pricing-table.rounded {
  .pricing-table-column.callout,
  &.columns-1 .pricing-table-column {
    border-radius: 0.3rem;
  }

  .pricing-table-column.callout > div {
    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:lst-child {
      border-bottom-left-radius: inherit;
    }
  }
}

.box {
  width: 100%;
  padding: 2.5rem;
  border: 1px solid #eee;
  overflow: hidden;
  position: relative;

  .box {
    width: auto;
    padding: inherit;
  }
}

.equalize .column > .box,
.grid-item .box {
  height: 100%;
}

.box {
  &.dotted {
    border-style: dotted;
  }

  &.dashed {
    border-style: dashed;
  }

  > {
    :first-child {
      margin-top: 0;
    }

    a:not(.button) {
      color: inherit;
      font-weight: 700;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &.dismissable .close {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.header .logo a,
.shop .product-price ins {
  text-decoration: none;
}

a,
p a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.box {
  &.dismissable {
    max-height: 100rem;

    .close {
      opacity: 0.5;
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 10;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.small {
    padding: 1.5rem;
  }

  &.medium {
    padding: 2.5rem;
  }

  &.large {
    padding: 3rem;
  }

  &.xlarge {
    padding: 3.5rem;
  }

  .ignore-parent-padding {
    border: none;
  }

  &.small > .ignore-parent-padding {
    margin: 0 -1.5rem;
  }

  &.medium > .ignore-parent-padding {
    margin: 0 -2.5rem;
  }

  &.large > .ignore-parent-padding {
    margin: 0 -3rem;
  }

  &.xlarge > .ignore-parent-padding {
    margin: 0 -3.5rem;
  }

  &.small > .ignore-parent-padding.first {
    margin: -1.5rem -1.5rem 3rem;
  }

  &.medium > .ignore-parent-padding.first {
    margin: -2.5rem -2.5rem 3rem;
  }

  &.large > .ignore-parent-padding.first {
    margin: -3rem -3rem 3rem;
  }

  &.xlarge > .ignore-parent-padding.first {
    margin: -3.5rem -3.5rem 3rem;
  }

  &.small > .ignore-parent-padding.last {
    margin: 0 -1.5rem -1.5rem;
  }

  &.medium > .ignore-parent-padding.last {
    margin: 0 -2.5rem -2.5rem;
  }

  &.large > .ignore-parent-padding.last {
    margin: 0 -3rem -3rem;
  }

  &.xlarge > .ignore-parent-padding.last {
    margin: 0 -3.5rem -3.5rem;
  }

  > .ignore-parent-padding:not(.first):not(.last) {
    margin-top: 0;
    margin-bottom: 3rem;
  }

  &.rounded {
    border-radius: 0.3rem;
  }

  &.shadow {
    box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
  }
}

.label {
  padding: 0 0.8rem;
  border: 1px solid #eee;
}

.bar,
.range-active-segment {
  width: 100%;
  padding: 0 1.5rem;
  -webkit-backface-visibility: hidden;
}

.label [class*="icon-"]:before {
  margin-left: 0;
  margin-right: 0.5rem;
}

.highlight {
  background: #eee;
  color: #666;
}

.label {
  background: #eee;
  color: #666;

  &.rounded {
    border-radius: 0.3rem;
  }

  &.pill {
    border-radius: 5rem;
  }
}

.progress-bar-group {
  margin-bottom: 3rem;

  .progress-bar:last-child {
    margin-bottom: 0;
  }
}

.progress-bar {
  width: 100%;
  height: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #666;
  border: 1px solid #eee;
  background: #eee;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  position: relative;

  &.tmh-perspective {
    overflow: hidden !important;
  }
}

.aux-navigation-active .progress-bar {
  -webkit-mask-image: none;
}

.bar {
  height: 100%;
  border: 1px solid #d0d0d0;
  background: #d0d0d0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.progress-bar-label {
  margin-bottom: 0.8rem;
  color: #666;
  font-size: 1.2rem;
  text-align: left;
  display: block;

  + .progress-bar {
    margin-bottom: 1.7rem;
  }
}

.progress-bar {
  &.small {
    height: 1.3rem;
    font-size: 1rem;
    line-height: 0.8rem;
  }

  &.medium {
    height: 2rem;
  }

  &.large {
    height: 3rem;
    font-size: 1.4rem;
    line-height: 2.6rem;
  }

  &.xlarge {
    height: 4rem;
    font-size: 1.5rem;
    line-height: 3.6rem;
  }

  &.rounded {
    border-radius: 0.3rem;

    .bar {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }
  }

  &.pill {
    border-radius: 10.5rem;

    .bar {
      border-top-left-radius: 10.5rem;
      border-bottom-left-radius: 10.5rem;
    }
  }
}

.range-slider {
  width: 100%;
  height: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  text-align: right;
  color: #666;
  border: 1px solid #eee;
  background: #eee;
  overflow: hidden;
  position: relative;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.aux-navigation-active .range-slider {
  -webkit-mask-image: none;
}

.range-active-segment {
  height: 100%;
  border: 1px solid #d0d0d0;
  background: #d0d0d0;
  position: absolute;
  z-index: 0;
}

.range-handle {
  width: 1.8rem;
  height: 1.8rem;
  display: inline-block;
  background: #666;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.range-slider {
  &.small {
    height: 1.3rem;
    font-size: 1rem;
    line-height: 0.8rem;

    .range-handle {
      width: 1.1rem;
      height: 1.1rem;
    }
  }

  &.medium {
    height: 2rem;

    .range-handle {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &.large {
    height: 3rem;
    font-size: 1.4rem;
    line-height: 2.6rem;

    .range-handle {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  &.xlarge {
    height: 4rem;
    font-size: 1.5rem;
    line-height: 3.6rem;

    .range-handle {
      width: 3.8rem;
      height: 3.8rem;
    }
  }

  .range-handle {
    border-radius: 0.3rem;
  }

  &.rounded {
    border-radius: 0.3rem;

    .range-active-segment {
      border-radius: 0.3rem;
    }
  }

  .range-handle {
    border-radius: 10.5rem;
  }

  &.pill {
    border-radius: 10.5rem;

    .range-active-segment {
      border-radius: 10.5rem;
    }
  }
}

.video-container {
  overflow: hidden;
}

.audio-container,
.video-container {
  margin-bottom: 3rem;
}

.audio-container .mejs-container {
  height: 3rem;
}

iframe {
  width: 100%;
  border: none;
  overflow: hidden;
  background: #000;
}

.divider,
hr {
  margin: 3rem 0;
  border-top: 1px solid #eee;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  height: 0;
  clear: both;
}

address,
p {
  margin: 0 0 3rem;
}

.divider.dashed,
hr.dashed {
  border-style: dashed;
}

.divider.dotted,
hr.dotted {
  border-style: dotted;
}

.divider.thick,
hr.thick {
  border-top-width: 0.2rem;
}

.divider[class*="border-"] {
  border-right-style: none !important;
  border-bottom-style: none !important;
  border-left-style: none !important;

  &.dashed,
  &.dotted {
    border-right-style: none !important;
    border-bottom-style: none !important;
    border-left-style: none !important;
  }
}

hr[class*="border-"] {
  border-right-style: none !important;
  border-bottom-style: none !important;
  border-left-style: none !important;

  &.dashed,
  &.dotted {
    border-right-style: none !important;
    border-bottom-style: none !important;
    border-left-style: none !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 2rem;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 3.2rem;
}

h3 {
  font-size: 2.4rem;

  &.widget-title {
    font-size: 2.1rem;
  }
}

h4 {
  font-size: 2.1rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

h1 {
  span {
    &[class*="bkg-"],
    &[class*="border-"] {
      padding: 0.4rem 0.6rem;
    }
  }

  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

h2 {
  span {
    &[class*="bkg-"],
    &[class*="border-"] {
      padding: 0.4rem 0.6rem;
    }
  }

  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

h3 {
  span {
    &[class*="bkg-"],
    &[class*="border-"] {
      padding: 0.4rem 0.6rem;
    }
  }

  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

h4 {
  span {
    &[class*="bkg-"],
    &[class*="border-"] {
      padding: 0.4rem 0.6rem;
    }
  }

  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

h5 {
  span {
    &[class*="bkg-"],
    &[class*="border-"] {
      padding: 0.4rem 0.6rem;
    }
  }

  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

h6 {
  span {
    &[class*="bkg-"],
    &[class*="border-"] {
      padding: 0.4rem 0.6rem;
    }
  }

  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

p {
  &[class*="bkg-"],
  &[class*="border-"] {
    padding: 0.4rem 0.6rem;
  }
}

.lead {
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 200;
}

.separator {
  width: 10rem;
  height: 0.4rem;
  display: block;
  background-color: #333;
  margin-bottom: 2rem;

  &.small {
    width: 7rem;
    height: 0.2rem;
  }

  &.large {
    width: 13rem;
    height: 0.6rem;
  }

  &.xlarge {
    width: 16rem;
    height: 0.8rem;
  }

  &.full-width {
    width: 100%;
  }
}

.center .separator,
.separator.center {
  margin-right: auto;
  margin-left: auto;
}

.right .separator,
.separator.right {
  margin-left: auto;
}

.font-alt-1 {
  font-family: "Times New Roman", Arial, sans-serif;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

small {
  font-size: 65%;
}

[class*="weight-"] {
  a,
  p,
  span {
    font-weight: inherit;
  }
}

.weight-light {
  font-weight: 100;
}

.weight-thin {
  font-weight: 200;
}

.weight-regular {
  font-weight: 400;
}

.weight-semi-bold {
  font-weight: 600;
}

.weight-bold {
  font-weight: 700;
}

.weight-xbold {
  font-weight: 900;
}

.text-shadow {
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.5);
}

[class*="lspacing-"] {
  a,
  p,
  span {
    letter-spacing: inherit;
  }
}

.lspacing-small {
  letter-spacing: 0.2rem;
}

.lspacing-medium {
  letter-spacing: 0.3rem;
}

.lspacing-large {
  letter-spacing: 0.5rem;
}

.lspacing-xlarge {
  letter-spacing: 0.8rem;
}

a,
p a {
  color: #666;
  outline: 0;
}

a:hover,
p a:hover {
  color: #333;
}

a.announcement {
  padding: 1rem 2rem;
  line-height: inherit;
  display: inline-flex;
  align-items: center;

  &.rounded {
    border-radius: 0.3rem;

    .label {
      border-radius: 0.3rem;
    }
  }

  &.pill {
    border-radius: 5rem;

    .label {
      border-radius: 5rem;
    }
  }

  .label {
    border-radius: inherit;
  }

  &.left .label {
    margin-right: 1rem;
  }

  &.right .label {
    margin-left: 1rem;
  }

  .messaage {
    flex: 1;
  }
}

::selection,
::-moz-selection {
  background: #333;
  color: #fff;
}

blockquote {
  margin: 0 0 3rem;
  font-size: 1.8rem;
  line-height: 1.5;

  p {
    margin-bottom: 0;
    font-size: 1.8rem;
  }

  .cite,
  cite {
    margin-top: 2rem;
    font-size: 60%;
    display: block;
  }

  &.small {
    font-size: 1.6rem;

    p {
      font-size: 1.6rem;
    }
  }

  &.medium {
    font-size: 1.8rem;

    p {
      font-size: 1.8rem;
    }
  }

  &.large {
    font-size: 2.1rem;

    p {
      font-size: 2.1rem;
    }
  }

  &.xlarge {
    font-size: 2.4rem;

    p {
      font-size: 2.4rem;
    }
  }

  &.border {
    padding: 0 0 0 2.5rem;
    border-left: 1px solid #eee;

    &.small {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &.medium {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &.large {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &.xlarge {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }

  &.icon [class*="icon-"] {
    font-size: 150%;
    text-align: center;
  }

  &.avatar {
    span {
      margin-bottom: 1.5rem;
      width: 5rem;
      height: 5rem;
      border-radius: 7rem;
      display: inline-block;

      img {
        width: 5rem;
        height: 5rem;
        border-radius: 7rem;
        display: inline-block;
      }
    }

    &.small span {
      width: 4rem;
      height: 4rem;

      img {
        width: 4rem;
        height: 4rem;
      }
    }

    &.medium span {
      width: 5rem;
      height: 5rem;

      img {
        width: 5rem;
        height: 5rem;
      }
    }

    &.large span {
      width: 6rem;
      height: 6rem;

      img {
        width: 6rem;
        height: 6rem;
      }
    }

    &.xlarge span {
      width: 7rem;
      height: 7rem;

      img {
        width: 7rem;
        height: 7rem;
      }
    }
  }

  &.pull-left {
    width: 40%;
    float: left;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }

  &.pull-right {
    width: 40%;
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  &.border.center,
  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.border {
    &.right {
      padding: 0 2.5rem 0 0;
      border-left: none;
      border-right: 1px solid #eee;
    }

    &.center {
      padding: 2.5rem 0 0;
      border-left: none;
      border-top: 1px solid #eee;
    }
  }
}

.breadcrumb {
  margin-left: 0;
  list-style: none;
  overflow: hidden;

  li {
    float: left;
    font-size: 1.2rem;

    a {
      font-size: 1.2rem;
    }

    &:after,
    &:before {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      font-family: icomoon;
    }

    &:after {
      content: "\e640";
    }
  }

  &.center li {
    display: inline-block;
    float: none;
  }

  &.right li {
    &:after {
      padding: 0;
      content: "";
    }

    &:before {
      content: "\e63f";
    }
  }

  li:last-child:after,
  &.right li:first-child:before {
    content: "";
    padding: 0;
  }
}

code,
kbd,
samp {
  font-family: "Courier New", Courier, monospace, sans-serif;
  text-align: left;
}

[class*=" icon-"],
[class*="tms-arrow-nav"]:before,
[class*="tml-nav"]:before,
[class^="tms-arrow-nav"]:before,
[class^="tml-nav"]:before,
[class^="icon-"] {
  font-family: icomoon;
  speak: none;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  margin: 0;
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

#tml-tool-bar .tml-social-list {
  overflow: hidden;

  li {
    overflow: hidden;
  }
}

.modal-open,
.parallax,
.tm-parallax,
.tm-slider-container.tms-carousel,
.tms-slide,
.tms-slides {
  overflow: hidden;
}

pre code {
  line-height: 1.5;
}

.tag {
  color: #074a72;

  .att {
    color: #47a4db;
  }

  .val {
    color: #d74950;
  }

  .comm {
    color: #999;
  }
}

@font-face {
  font-family: icomoon;
  src: url(../font/entypo.eot);
  src:
    url(../font/entypo.eot) format("embedded-opentype"),
    url(../font/entypo.woff) format("woff"),
    url(../font/entypo.ttf) format("truetype"),
    url(../font/entypo.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*=" icon-"],
[class^="icon-"] {
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

[class*="icon-"] + [class*="icon-"] {
  margin-left: 1rem;
}

.icon-add-to-list:before {
  content: "\e600";
}

.icon-add-user:before {
  content: "\e601";
}

.icon-address:before {
  content: "\e602";
}

.icon-adjust:before {
  content: "\e603";
}

.icon-air:before {
  content: "\e604";
}

.icon-aircraft-landing:before {
  content: "\e605";
}

.icon-aircraft-take-off:before {
  content: "\e606";
}

.icon-aircraft:before {
  content: "\e607";
}

.icon-align-bottom:before {
  content: "\e608";
}

.icon-align-horizontal-middle:before {
  content: "\e609";
}

.icon-align-left:before {
  content: "\e60a";
}

.icon-align-right:before {
  content: "\e60b";
}

.icon-align-top:before {
  content: "\e60c";
}

.icon-align-vertical-middle:before {
  content: "\e60d";
}

.icon-archive:before {
  content: "\e60e";
}

.icon-area-graph:before {
  content: "\e60f";
}

.icon-arrow-bold-down:before {
  content: "\e610";
}

.icon-arrow-bold-left:before {
  content: "\e611";
}

.icon-arrow-bold-right:before {
  content: "\e612";
}

.icon-arrow-bold-up:before {
  content: "\e613";
}

.icon-arrow-down:before {
  content: "\e614";
}

.icon-arrow-left:before {
  content: "\e615";
}

.icon-arrow-long-down:before {
  content: "\e616";
}

.icon-arrow-long-left:before {
  content: "\e617";
}

.icon-arrow-long-right:before {
  content: "\e618";
}

.icon-arrow-long-up:before {
  content: "\e619";
}

.icon-arrow-right:before {
  content: "\e61a";
}

.icon-arrow-up:before {
  content: "\e61b";
}

.icon-arrow-with-circle-down:before {
  content: "\e61c";
}

.icon-arrow-with-circle-left:before {
  content: "\e61d";
}

.icon-arrow-with-circle-right:before {
  content: "\e61e";
}

.icon-arrow-with-circle-up:before {
  content: "\e61f";
}

.icon-attachment:before {
  content: "\e620";
}

.icon-awareness-ribbon:before {
  content: "\e621";
}

.icon-back-in-time:before {
  content: "\e622";
}

.icon-back:before {
  content: "\e623";
}

.icon-bar-graph:before {
  content: "\e624";
}

.icon-battery:before {
  content: "\e625";
}

.icon-beamed-note:before {
  content: "\e626";
}

.icon-bell:before {
  content: "\e627";
}

.icon-blackboard:before {
  content: "\e628";
}

.icon-block:before {
  content: "\e629";
}

.icon-book:before {
  content: "\e62a";
}

.icon-bookmark:before {
  content: "\e62b";
}

.icon-bookmarks:before {
  content: "\e62c";
}

.icon-bowl:before {
  content: "\e62d";
}

.icon-box:before {
  content: "\e62e";
}

.icon-briefcase:before {
  content: "\e62f";
}

.icon-browser:before {
  content: "\e630";
}

.icon-brush:before {
  content: "\e631";
}

.icon-bucket:before {
  content: "\e632";
}

.icon-bug:before {
  content: "\e633";
}

.icon-cake:before {
  content: "\e634";
}

.icon-calculator:before {
  content: "\e635";
}

.icon-calendar:before {
  content: "\e636";
}

.icon-camera:before {
  content: "\e637";
}

.icon-ccw:before {
  content: "\e638";
}

.icon-chat:before {
  content: "\e639";
}

.icon-check:before {
  content: "\e63a";
}

.icon-down:before {
  content: "\e63b";
}

.icon-left:before {
  content: "\e63c";
}

.icon-right:before {
  content: "\e63d";
}

.icon-down-open-mini:before {
  content: "\e63e";
}

.icon-left-open-mini:before {
  content: "\e63f";
}

.icon-right-open-mini:before {
  content: "\e640";
}

.icon-up-open-mini:before {
  content: "\e641";
}

.icon-down-open-big:before {
  content: "\e642";
}

#tms-prev:before,
.icon-left-open-big:before {
  content: "\e643";
}

.icon-right-open-big:before {
  content: "\e644";
}

.icon-up-open-big:before {
  content: "\e645";
}

.icon-up:before {
  content: "\e646";
}

.icon-down-circled:before {
  content: "\e647";
}

.icon-left-circled:before {
  content: "\e648";
}

.icon-right-circled:before {
  content: "\e649";
}

.icon-up-circled:before {
  content: "\e64a";
}

.icon-circle-with-cross:before {
  content: "\e64b";
}

.icon-circle-with-minus:before {
  content: "\e64c";
}

.icon-circle-with-plus:before {
  content: "\e64d";
}

.icon-circle:before {
  content: "\e64e";
}

.icon-circular-graph:before {
  content: "\e64f";
}

.icon-clapperboard:before {
  content: "\e650";
}

.icon-classic-computer:before {
  content: "\e651";
}

.icon-clipboard:before {
  content: "\e652";
}

.icon-clock:before {
  content: "\e653";
}

.icon-cloud:before {
  content: "\e654";
}

.icon-code:before {
  content: "\e655";
}

.icon-cog:before {
  content: "\e656";
}

.icon-colours:before {
  content: "\e657";
}

.icon-compass:before {
  content: "\e658";
}

.icon-fast-backward:before {
  content: "\e659";
}

.icon-fast-forward:before {
  content: "\e65a";
}

.icon-jump-to-start:before {
  content: "\e65b";
}

.icon-next:before {
  content: "\e65c";
}

.icon-paus:before {
  content: "\e65d";
}

.icon-play:before {
  content: "\e65e";
}

.icon-record:before {
  content: "\e65f";
}

.icon-stop:before {
  content: "\e660";
}

.icon-volume:before {
  content: "\e661";
}

.icon-copy:before {
  content: "\e662";
}

.icon-creative-commons-attribution:before {
  content: "\e663";
}

.icon-creative-commons-noderivs:before {
  content: "\e664";
}

.icon-creative-commons-noncommercial-eu:before {
  content: "\e665";
}

.icon-creative-commons-noncommercial-us:before {
  content: "\e666";
}

.icon-creative-commons-public-domain:before {
  content: "\e667";
}

.icon-creative-commons-remix:before {
  content: "\e668";
}

.icon-creative-commons-share:before {
  content: "\e669";
}

.icon-creative-commons-sharealike:before {
  content: "\e66a";
}

.icon-creative-commons:before {
  content: "\e66b";
}

.icon-credit-card:before {
  content: "\e66c";
}

.icon-credit:before {
  content: "\e66d";
}

.icon-crop:before {
  content: "\e66e";
}

.icon-cancel:before {
  content: "\e66f";
}

.icon-cup:before {
  content: "\e670";
}

.icon-cw:before {
  content: "\e671";
}

.icon-cycle:before {
  content: "\e672";
}

.icon-database:before {
  content: "\e673";
}

.icon-dial-pad:before {
  content: "\e674";
}

.icon-direction:before {
  content: "\e675";
}

.icon-document-landscape:before {
  content: "\e676";
}

.icon-document:before {
  content: "\e677";
}

.icon-documents:before {
  content: "\e678";
}

.icon-dot-single:before {
  content: "\e679";
}

.icon-dots-three-horizontal:before {
  content: "\e67a";
}

.icon-dots-three-vertical:before {
  content: "\e67b";
}

.icon-dots-two-horizontal:before {
  content: "\e67c";
}

.icon-dots-two-vertical:before {
  content: "\e67d";
}

.icon-download:before {
  content: "\e67e";
}

.icon-drink:before {
  content: "\e67f";
}

.icon-drive:before {
  content: "\e680";
}

.icon-drop:before {
  content: "\e681";
}

.icon-edit:before {
  content: "\e682";
}

.icon-email:before {
  content: "\e683";
}

.icon-emoji-flirt:before {
  content: "\e684";
}

.icon-emoji-happy:before {
  content: "\e685";
}

.icon-emoji-neutral:before {
  content: "\e686";
}

.icon-emoji-sad:before {
  content: "\e687";
}

.icon-erase:before {
  content: "\e688";
}

.icon-eraser:before {
  content: "\e689";
}

.icon-export:before {
  content: "\e68a";
}

.icon-eye-with-line:before {
  content: "\e68b";
}

.icon-eye:before {
  content: "\e68c";
}

.icon-feather:before {
  content: "\e68d";
}

.icon-fingerprint:before {
  content: "\e68e";
}

.icon-flag:before {
  content: "\e68f";
}

.icon-flash:before {
  content: "\e690";
}

.icon-flashlight:before {
  content: "\e691";
}

.icon-flat-brush:before {
  content: "\e692";
}

.icon-flow-branch:before {
  content: "\e693";
}

.icon-flow-cascade:before {
  content: "\e694";
}

.icon-flow-line:before {
  content: "\e695";
}

.icon-flow-parallel:before {
  content: "\e696";
}

.icon-flow-tree:before {
  content: "\e697";
}

.icon-flower:before {
  content: "\e698";
}

.icon-folder-images:before {
  content: "\e699";
}

.icon-folder-music:before {
  content: "\e69a";
}

.icon-folder-video:before {
  content: "\e69b";
}

.icon-folder:before {
  content: "\e69c";
}

.icon-forward:before {
  content: "\e69d";
}

.icon-funnel:before {
  content: "\e69e";
}

.icon-game-controller:before {
  content: "\e69f";
}

.icon-gauge:before {
  content: "\e6a0";
}

.icon-globe:before {
  content: "\e6a1";
}

.icon-graduation-cap:before {
  content: "\e6a2";
}

.icon-grid:before {
  content: "\e6a3";
}

.icon-hair-cross:before {
  content: "\e6a4";
}

.icon-hand:before {
  content: "\e6a5";
}

.icon-heart-outlined:before {
  content: "\e6a6";
}

.icon-heart:before {
  content: "\e6a7";
}

.icon-help-with-circle:before {
  content: "\e6a8";
}

.icon-help:before {
  content: "\e6a9";
}

.icon-home:before {
  content: "\e6aa";
}

.icon-hour-glass:before {
  content: "\e6ab";
}

.icon-image-inverted:before {
  content: "\e6ac";
}

.icon-image:before {
  content: "\e6ad";
}

.icon-images:before {
  content: "\e6ae";
}

.icon-inbox:before {
  content: "\e6af";
}

.icon-infinity:before {
  content: "\e6b0";
}

.icon-info-with-circle:before {
  content: "\e6b1";
}

.icon-info:before {
  content: "\e6b2";
}

.icon-install:before {
  content: "\e6b3";
}

.icon-key:before {
  content: "\e6b4";
}

.icon-keyboard:before {
  content: "\e6b5";
}

.icon-lab-flask:before {
  content: "\e6b6";
}

.icon-landline:before {
  content: "\e6b7";
}

.icon-language:before {
  content: "\e6b8";
}

.icon-laptop:before {
  content: "\e6b9";
}

.icon-layers:before {
  content: "\e6ba";
}

.icon-leaf:before {
  content: "\e6bb";
}

.icon-level-down:before {
  content: "\e6bc";
}

.icon-level-up:before {
  content: "\e6bd";
}

.icon-lifebuoy:before {
  content: "\e6be";
}

.icon-light-bulb:before {
  content: "\e6bf";
}

.icon-light-down:before {
  content: "\e6c0";
}

.icon-light-up:before {
  content: "\e6c1";
}

.icon-line-graph:before {
  content: "\e6c2";
}

.icon-link:before {
  content: "\e6c3";
}

.icon-list:before {
  content: "\e6c4";
}

.icon-location-pin:before {
  content: "\e6c5";
}

.icon-location:before {
  content: "\e6c6";
}

.icon-lock-open:before {
  content: "\e6c7";
}

.icon-lock:before {
  content: "\e6c8";
}

.icon-log-out:before {
  content: "\e6c9";
}

.icon-login:before {
  content: "\e6ca";
}

.icon-loop:before {
  content: "\e6cb";
}

.icon-magnet:before {
  content: "\e6cc";
}

.icon-magnifying-glass:before {
  content: "\e6cd";
}

.icon-mail:before {
  content: "\e6ce";
}

.icon-man:before {
  content: "\e6cf";
}

.icon-map:before {
  content: "\e6d0";
}

.icon-mask:before {
  content: "\e6d1";
}

.icon-medal:before {
  content: "\e6d2";
}

.icon-megaphone:before {
  content: "\e6d3";
}

.icon-menu:before {
  content: "\e6d4";
}

.icon-merge:before {
  content: "\e6d5";
}

.icon-message:before {
  content: "\e6d6";
}

.icon-mic:before {
  content: "\e6d7";
}

.icon-minus:before {
  content: "\e6d8";
}

.icon-mobile:before {
  content: "\e6d9";
}

.icon-modern-mic:before {
  content: "\e6da";
}

.icon-moon:before {
  content: "\e6db";
}

.icon-mouse-pointer:before {
  content: "\e6dc";
}

.icon-mouse:before {
  content: "\e6dd";
}

.icon-music:before {
  content: "\e6de";
}

.icon-network:before {
  content: "\e6df";
}

.icon-new-message:before {
  content: "\e6e0";
}

.icon-new:before {
  content: "\e6e1";
}

.icon-news:before {
  content: "\e6e2";
}

.icon-newsletter:before {
  content: "\e6e3";
}

.icon-note:before {
  content: "\e6e4";
}

.icon-notification:before {
  content: "\e6e5";
}

.icon-notifications-off:before {
  content: "\e6e6";
}

.icon-old-mobile:before {
  content: "\e6e7";
}

.icon-old-phone:before {
  content: "\e6e8";
}

.icon-open-book:before {
  content: "\e6e9";
}

.icon-palette:before {
  content: "\e6ea";
}

.icon-paper-plane:before {
  content: "\e6eb";
}

.icon-pencil:before {
  content: "\e6ec";
}

.icon-phone:before {
  content: "\e6ed";
}

.icon-pie-chart:before {
  content: "\e6ee";
}

.icon-pin:before {
  content: "\e6ef";
}

.icon-plus:before {
  content: "\e6f0";
}

.icon-popup:before {
  content: "\e6f1";
}

.icon-power-plug:before {
  content: "\e6f2";
}

.icon-price-ribbon:before {
  content: "\e6f3";
}

.icon-price-tag:before {
  content: "\e6f4";
}

.icon-print:before {
  content: "\e6f5";
}

.icon-progress-empty:before {
  content: "\e6f6";
}

.icon-progress-full:before {
  content: "\e6f7";
}

.icon-progress-one:before {
  content: "\e6f8";
}

.icon-progress-two:before {
  content: "\e6f9";
}

.icon-publish:before {
  content: "\e6fa";
}

.icon-quote:before {
  content: "\e6fb";
}

.icon-radio:before {
  content: "\e6fc";
}

.icon-remove-user:before {
  content: "\e6fd";
}

.icon-reply-all:before {
  content: "\e6fe";
}

.icon-reply:before {
  content: "\e6ff";
}

.icon-resize-100:before {
  content: "\e700";
}

.icon-resize-full-screen:before {
  content: "\e701";
}

.icon-retweet:before {
  content: "\e702";
}

.icon-rocket:before {
  content: "\e703";
}

.icon-round-brush:before {
  content: "\e704";
}

.icon-rss:before {
  content: "\e705";
}

.icon-ruler:before {
  content: "\e706";
}

.icon-save:before {
  content: "\e707";
}

.icon-scissors:before {
  content: "\e708";
}

.icon-select-arrows:before {
  content: "\e709";
}

.icon-share-alternative:before {
  content: "\e70a";
}

.icon-share:before {
  content: "\e70b";
}

.icon-shareable:before {
  content: "\e70c";
}

.icon-shield:before {
  content: "\e70d";
}

.icon-shop:before {
  content: "\e70e";
}

.icon-shopping-bag:before {
  content: "\e70f";
}

.icon-shopping-basket:before {
  content: "\e710";
}

.icon-shopping-cart:before {
  content: "\e711";
}

.icon-shuffle:before {
  content: "\e712";
}

.icon-signal:before {
  content: "\e713";
}

.icon-sound-mix:before {
  content: "\e714";
}

.icon-sound-mute:before {
  content: "\e715";
}

.icon-sound:before {
  content: "\e716";
}

.icon-sports-club:before {
  content: "\e717";
}

.icon-spreadsheet:before {
  content: "\e718";
}

.icon-squared-cross:before {
  content: "\e719";
}

.icon-squared-minus:before {
  content: "\e71a";
}

.icon-squared-plus:before {
  content: "\e71b";
}

.icon-star-outlined:before {
  content: "\e71c";
}

.icon-star:before {
  content: "\e71d";
}

.icon-stopwatch:before {
  content: "\e71e";
}

.icon-suitcase:before {
  content: "\e71f";
}

.icon-swap:before {
  content: "\e720";
}

.icon-sweden:before {
  content: "\e721";
}

.icon-switch:before {
  content: "\e722";
}

.icon-tablet-mobile-combo:before {
  content: "\e723";
}

.icon-tablet:before {
  content: "\e724";
}

.icon-tag:before {
  content: "\e725";
}

.icon-text-document-inverted:before {
  content: "\e726";
}

.icon-text-document:before {
  content: "\e727";
}

.icon-text:before {
  content: "\e728";
}

.icon-thermometer:before {
  content: "\e729";
}

.icon-thumbs-down:before {
  content: "\e72a";
}

.icon-thumbs-up:before {
  content: "\e72b";
}

.icon-thunder-cloud:before {
  content: "\e72c";
}

.icon-ticket:before {
  content: "\e72d";
}

.icon-time-slot:before {
  content: "\e72e";
}

.icon-tools:before {
  content: "\e72f";
}

.icon-traffic-cone:before {
  content: "\e730";
}

.icon-trash:before {
  content: "\e731";
  color: red;
}

.icon-tree:before {
  content: "\e732";
}

.icon-triangle-down:before {
  content: "\e733";
}

.icon-triangle-left:before {
  content: "\e734";
}

.icon-triangle-right:before {
  content: "\e735";
}

.icon-triangle-up:before {
  content: "\e736";
}

.icon-trophy:before {
  content: "\e737";
}

.icon-tv:before {
  content: "\e738";
}

.icon-typing:before {
  content: "\e739";
}

.icon-uninstall:before {
  content: "\e73a";
}

.icon-unread:before {
  content: "\e73b";
}

.icon-untag:before {
  content: "\e73c";
}

.icon-upload-to-cloud:before {
  content: "\e73d";
}

.icon-upload:before {
  content: "\e73e";
}

.icon-user:before {
  content: "\e73f";
}

.icon-users:before {
  content: "\e740";
}

.icon-v-card:before {
  content: "\e741";
}

.icon-video-camera:before {
  content: "\e742";
}

.icon-video:before {
  content: "\e743";
}

.icon-vinyl:before {
  content: "\e744";
}

.icon-voicemail:before {
  content: "\e745";
}

.icon-wallet:before {
  content: "\e746";
}

.icon-warning:before {
  content: "\e747";
}

.icon-water:before {
  content: "\e748";
}

.icon-px-with-circle:before {
  content: "\e749";
}

.icon-px:before {
  content: "\e74a";
}

.icon-app-store:before {
  content: "\e74b";
}

.icon-baidu:before {
  content: "\e74c";
}

.icon-basecamp:before {
  content: "\e74d";
}

.icon-behance:before {
  content: "\e74e";
}

.icon-creative-cloud:before {
  content: "\e74f";
}

.icon-dribbble-with-circle:before {
  content: "\e750";
}

.icon-dribbble:before {
  content: "\e751";
}

.icon-dropbox:before {
  content: "\e752";
}

.icon-evernote:before {
  content: "\e753";
}

.icon-facebook-with-circle:before {
  content: "\e754";
}

.icon-facebook:before {
  content: "\e755";
}

.icon-flattr:before {
  content: "\e756";
}

.icon-flickr-with-circle:before {
  content: "\e757";
}

.icon-flickr:before {
  content: "\e758";
}

.icon-foursquare:before {
  content: "\e759";
}

.icon-github-with-circle:before {
  content: "\e75a";
}

.icon-github:before {
  content: "\e75b";
}

.icon-google-drive:before {
  content: "\e75c";
}

.icon-google-hangouts:before {
  content: "\e75d";
}

.icon-google-play:before {
  content: "\e75e";
}

.icon-google-with-circle:before {
  content: "\e75f";
}

.icon-google:before {
  content: "\e760";
}

.icon-grooveshark:before {
  content: "\e761";
}

.icon-houzz:before {
  content: "\e762";
}

.icon-icloud:before {
  content: "\e763";
}

.icon-instagram-with-circle:before {
  content: "\e764";
}

.icon-instagram:before {
  content: "\e765";
}

.icon-lastfm-with-circle:before {
  content: "\e766";
}

.icon-lastfm:before {
  content: "\e767";
}

.icon-linkedin-with-circle:before {
  content: "\e768";
}

.icon-linkedin:before {
  content: "\e769";
}

.icon-mail-with-circle:before {
  content: "\e76a";
}

.icon-medium-with-circle:before {
  content: "\e76b";
}

.icon-medium:before {
  content: "\e76c";
}

.icon-mixi:before {
  content: "\e76d";
}

.icon-onedrive:before {
  content: "\e76e";
}

.icon-paypal:before {
  content: "\e76f";
}

.icon-picasa:before {
  content: "\e770";
}

.icon-pinterest-with-circle:before {
  content: "\e771";
}

.icon-pinterest:before {
  content: "\e772";
}

.icon-qq-with-circle:before {
  content: "\e773";
}

.icon-qq:before {
  content: "\e774";
}

.icon-raft-with-circle:before {
  content: "\e775";
}

.icon-raft:before {
  content: "\e776";
}

.icon-rainbow:before {
  content: "\e777";
}

.icon-rdio-with-circle:before {
  content: "\e778";
}

.icon-rdio:before {
  content: "\e779";
}

.icon-renren:before {
  content: "\e77a";
}

.icon-scribd:before {
  content: "\e77b";
}

.icon-sina-weibo:before {
  content: "\e77c";
}

.icon-skype-with-circle:before {
  content: "\e77d";
}

.icon-skype:before {
  content: "\e77e";
}

.icon-slideshare:before {
  content: "\e77f";
}

.icon-smashing:before {
  content: "\e780";
}

.icon-soundcloud:before {
  content: "\e781";
}

.icon-spotify-with-circle:before {
  content: "\e782";
}

.icon-spotify:before {
  content: "\e783";
}

.icon-stumbleupon-with-circle:before {
  content: "\e784";
}

.icon-stumbleupon:before {
  content: "\e785";
}

.icon-swarm:before {
  content: "\e786";
}

.icon-tripadvisor:before {
  content: "\e787";
}

.icon-tumblr-with-circle:before {
  content: "\e788";
}

.icon-tumblr:before {
  content: "\e789";
}

.icon-twitter-with-circle:before {
  content: "\e78a";
}

.icon-twitter:before {
  content: "\e78b";
}

.icon-vimeo-with-circle:before {
  content: "\e78c";
}

.icon-vimeo:before {
  content: "\e78d";
}

.icon-vine-with-circle:before {
  content: "\e78e";
}

.icon-vine:before {
  content: "\e78f";
}

.icon-vk-alternitive:before {
  content: "\e790";
}

.icon-vk-with-circle:before {
  content: "\e791";
}

.icon-vk:before {
  content: "\e792";
}

.icon-windows-store:before {
  content: "\e793";
}

.icon-xing-with-circle:before {
  content: "\e794";
}

.icon-xing:before {
  content: "\e795";
}

.icon-yelp:before {
  content: "\e796";
}

.icon-youko-with-circle:before {
  content: "\e797";
}

.icon-youko:before {
  content: "\e798";
}

.icon-youtube-with-circle:before {
  content: "\e799";
}

.icon-youtube:before {
  content: "\e79a";
}

[class*="icon-"] {
  &.small {
    font-size: 1.6rem;
  }

  &.medium {
    font-size: 2.4rem;
  }

  &.large {
    font-size: 3.2rem;
  }

  &.xlarge {
    font-size: 3.8rem;
  }
}

.icon-boxed,
.icon-circled {
  width: 6.6rem;
  height: 6.6rem;
  margin-bottom: 0.4rem;
  line-height: 6.6rem;
  text-align: center;
  border: 1px solid #eee;
  overflow: hidden;
  position: relative;
}

.icon-boxed.small,
.icon-circled.small {
  width: 5.8rem;
  height: 5.8rem;
  line-height: 5.6rem;
}

.icon-boxed.medium,
.icon-circled.medium {
  width: 6.6rem;
  height: 6.6rem;
  line-height: 6.4rem;
}

.icon-boxed.large,
.icon-circled.large {
  width: 7.4rem;
  height: 7.4rem;
  line-height: 7.2rem;
}

.icon-boxed.xlarge,
.icon-circled.xlarge {
  width: 9rem;
  height: 9rem;
  line-height: 8.8rem;
}

.icon-boxed.left + span,
.icon-circled.left + span {
  margin-left: 0.5rem;
}

.icon-boxed.right + span,
.icon-circled.right + span {
  margin-right: 0.5rem;
}

.icon-boxed.rounded {
  border-radius: 0.3rem;
}

.icon-circled {
  border-radius: 10rem;
}

.icon-boxed.shadow,
.icon-circled.shadow {
  box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
}

.icon-boxed.pulsate,
.icon-circled.pulsate {
  overflow: visible;
}

.icon-boxed.pulsate:after,
.icon-circled.pulsate:after {
  width: inherit;
  height: inherit;
  padding: inherit;
  content: "";
  border: inherit;
  border-radius: inherit;
  position: absolute;
  top: -1px;
  left: -1px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
}

.icon-boxed.thick.pulsate:after,
.icon-circled.thick.pulsate:after {
  top: -2px;
  left: -2px;
}

.icon-boxed.slide-left-right:before,
.icon-circled.slide-left-right:before {
  -webkit-animation: slide-left-right 6s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: slide-left-right 6s ease-in-out;
  animation-iteration-count: infinite;
  display: block;
}

.icon-boxed.slide-top-bottom:before,
.icon-circled.slide-top-bottom:before {
  -webkit-animation: slide-top-bottom 6s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: slide-top-bottom 6s ease-in-out;
  animation-iteration-count: infinite;
  display: block;
}

.icon-boxed.scale-in:before,
.icon-circled.scale-in:before {
  -webkit-animation: scale-in 6s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: scale-in 6s ease-in-out;
  animation-iteration-count: infinite;
  display: block;
}

.icon-boxed.scale-out:before,
.icon-circled.scale-out:before {
  -webkit-animation: scale-out 6s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: scale-out 6s ease-in-out;
  animation-iteration-count: infinite;
  display: block;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes slide-left-right {
  100%,
  15% {
    -webkit-transform: translateX(0);
    opacity: 1;
  }

  5% {
    -webkit-transform: translateX(5rem);
    opacity: 0;
  }

  10% {
    -webkit-transform: translateX(-5rem);
    opacity: 0;
  }
}

@keyframes slide-left-right {
  100%,
  15% {
    transform: translateX(0);
    opacity: 1;
  }

  5% {
    transform: translateX(5rem);
    opacity: 0;
  }

  10% {
    transform: translateX(-5rem);
    opacity: 0;
  }
}

@-webkit-keyframes slide-top-bottom {
  100%,
  15% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }

  5% {
    -webkit-transform: translateY(5rem);
    opacity: 0;
  }

  10% {
    -webkit-transform: translateY(-5rem);
    opacity: 0;
  }
}

@keyframes slide-top-bottom {
  100%,
  15% {
    transform: translateY(0);
    opacity: 1;
  }

  5% {
    transform: translateY(5rem);
    opacity: 0;
  }

  10% {
    transform: translateY(-5rem);
    opacity: 0;
  }
}

@-webkit-keyframes scale-in {
  100%,
  15% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  5% {
    -webkit-transform: scale(0.6);
    opacity: 0;
  }

  10% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes scale-in {
  100%,
  15% {
    transform: scale(1);
    opacity: 1;
  }

  5% {
    transform: scale(0.6);
    opacity: 0;
  }

  10% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-webkit-keyframes scale-out {
  100%,
  15% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  5% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }

  10% {
    -webkit-transform: scale(0.6);
    opacity: 0;
  }
}

@keyframes scale-out {
  100%,
  15% {
    transform: scale(1);
    opacity: 1;
  }

  5% {
    transform: scale(1.2);
    opacity: 0;
  }

  10% {
    transform: scale(0.6);
    opacity: 0;
  }
}

[class*="bkg-"].padded {
  padding: 1rem 2rem;
}

[class*="border-"] {
  &.padded {
    padding: 1rem 2rem;
  }

  border: 1px solid #eee;
}

[class*="border-hover-"]:hover {
  background: 0 0;
  background: rgba(0, 0, 0, 0);
}

[class*="border-"] {
  &:not([class*="bkg-"]),
  &[class*="bkg-hover-"] {
    background: 0 0;
    background: rgba(0, 0, 0, 0);
  }

  &.solid {
    border-style: solid;
    border-width: 1px;
  }

  &.dashed {
    border-style: dashed;
    border-width: 1px;
  }

  &.dotted {
    border-style: dotted;
    border-width: 1px;
  }

  &.thick {
    border-width: 0.2rem;

    * {
      border-width: 0.2rem;
    }
  }
}

.border-turquoise {
  border-color: #00a186;
}

.border-hover-turquoise:hover {
  border-color: #008b73;
}

.border-turquoise-light {
  border-color: #00bd9c;
}

.border-hover-turquoise-light:hover {
  border-color: #00a186;
}

.border-green {
  border-color: #1bb05d;
  color: #1bb05d;
}

.border-hover-green:hover {
  border-color: #179851;
}

.border-green-light {
  border-color: #1ecd6d;
}

.border-hover-green-light:hover {
  border-color: #1bb05d;
}

.border-blue {
  border-color: #227fbb;
}

.border-hover-blue:hover {
  border-color: #1d6ea3;
}

.border-blue-light {
  border-color: #2c96dd;
}

.border-hover-blue-light:hover {
  border-color: #227fbb;
}

.border-navy {
  border-color: #2c3e51;
}

.border-hover-navy:hover {
  border-color: #1f2d3b;
}

.border-navy-light {
  border-color: #33495f;
}

.border-hover-navy-light:hover {
  border-color: #2c3e51;
}

.border-purple {
  border-color: #8f3faf;
}

.border-hover-purple:hover {
  border-color: #7b3697;
}

.border-purple-light {
  border-color: #b078c6;
}

.border-hover-purple-light:hover {
  border-color: #8f3faf;
}

.border-yellow {
  border-color: #f3b100;
}

.border-hover-yellow:hover {
  border-color: #e2a50b;
}

.border-yellow-light {
  border-color: #ffbe12;
}

.border-hover-yellow-light:hover {
  border-color: #f3b100;
}

.border-orange {
  border-color: #e87f04;
}

.border-hover-orange:hover {
  border-color: #df6f0b;
}

.border-orange-light {
  border-color: #f59d00;
}

.border-hover-orange-light:hover {
  border-color: #e87f04;
}

.border-red {
  border-color: #c23824;
}

.border-hover-red:hover {
  border-color: #a9311f;
}

.border-red-light {
  border-color: #ea4b35;
}

.border-hover-red-light:hover {
  border-color: #c23824;
}

.border-hover-white:hover,
.border-white {
  border-color: #fff;
}

.border-grey {
  border-color: #666;
}

.border-hover-grey:hover {
  border-color: #555;
}

.border-grey-light {
  border-color: #999;
}

.border-hover-grey-light:hover {
  border-color: #888;
}

.border-grey-ultralight {
  border-color: #f4f4f4;
}

.border-hover-grey-ultralight:hover {
  border-color: #f1f1f1;
}

.border-charcoal {
  border-color: #333;
}

.border-hover-charcoal:hover {
  border-color: #222;
}

.border-charcoal-light {
  border-color: #555;
}

.border-hover-charcoal-light:hover {
  border-color: #444;
}

.border-black,
.border-hover-black:hover {
  border-color: #000;
}

.border-base {
  border-color: #eee;
}

.border-hover-base:hover {
  border-color: #d0d0d0;
}

.border-behance,
.border-hover-behance:hover {
  border-color: #1769ff;
  color: #1769ff;
}

.border-dribbble,
.border-hover-dribbble:hover {
  border-color: #ea4c89;
  color: #ea4c89;
}

.border-facebook,
.border-hover-facebook:hover {
  border-color: #3b5998;
  color: #3b5998;
}

.border-flickr,
.border-hover-flickr:hover {
  border-color: #0063dc;
  color: #0063dc;
}

.border-github,
.border-hover-github:hover {
  border-color: #333;
  color: #333;
}

.border-google,
.border-hover-google:hover {
  border-color: #d34836;
  color: #d34836;
}

.border-hover-instagram:hover,
.border-instagram {
  border-color: #517fa4;
  color: #517fa4;
}

.border-hover-linkedin:hover,
.border-linkedin {
  border-color: #007bb6;
  color: #007bb6;
}

.border-hover-pinterest:hover,
.border-pinterest {
  border-color: #cb2027;
  color: #cb2027;
}

.border-hover-skype:hover,
.border-skype {
  border-color: #12a5f4;
  color: #12a5f4;
}

.border-hover-soundcloud:hover,
.border-soundcloud {
  border-color: #f80;
  color: #f80;
}

.border-hover-spotify:hover,
.border-spotify {
  border-color: #7ab800;
  color: #7ab800;
}

.border-hover-tumblr:hover,
.border-tumblr {
  border-color: #35465c;
  color: #35465c;
}

.border-hover-twitter:hover,
.border-twitter {
  border-color: #00aced;
  color: #00aced;
}

.border-hover-vimeo:hover,
.border-vimeo {
  border-color: #1ab7ea;
  color: #1ab7ea;
}

.border-hover-youtube:hover,
.border-youtube {
  border-color: #b00;
  color: #b00;
}

[class*="bkg-hover-"] {
  &:hover *,
  &:visited * {
    color: inherit;
  }
}

.bkg-turquoise {
  background: #00a186;
  border-color: #00a186;
}

.bkg-hover-turquoise:hover {
  background: #008b73;
  border-color: #008b73;
}

.bkg-turquoise-light {
  background: #00bd9c;
  border-color: #00bd9c;
}

.bkg-hover-turquoise-light:hover {
  background: #00a186;
  border-color: #00a186;
}

.bkg-green {
  background: #1bb05d;
  border-color: #1bb05d;
}

.bkg-hover-green:hover {
  background: #179851;
  border-color: #179851;
}

.bkg-green-light {
  background: #1ecd6d;
  border-color: #1ecd6d;
}

.bkg-hover-green-light:hover {
  background: #1bb05d;
  border-color: #1bb05d;
}

.bkg-blue {
  background: #227fbb;
  border-color: #227fbb;
}

.bkg-hover-blue:hover {
  background: #1d6ea3;
  border-color: #1d6ea3;
}

.bkg-blue-light {
  background: #2c96dd;
  border-color: #2c96dd;
}

.bkg-hover-blue-light:hover {
  background: #227fbb;
  border-color: #227fbb;
}

.bkg-navy {
  background: #2c3e51;
  border-color: #2c3e51;
}

.bkg-hover-navy:hover {
  background: #1f2d3b;
  border-color: #1f2d3b;
}

.bkg-navy-light {
  background: #33495f;
  border-color: #33495f;
}

.bkg-hover-navy-light:hover {
  background: #2c3e51;
  border-color: #2c3e51;
}

.bkg-purple {
  background: #8f3faf;
  border-color: #8f3faf;
}

.bkg-hover-purple:hover {
  background: #7b3697;
  border-color: #7b3697;
}

.bkg-purple-light {
  background: #b078c6;
  border-color: #b078c6;
}

.bkg-hover-purple-light:hover {
  background: #8f3faf;
  border-color: #8f3faf;
}

.bkg-yellow {
  background: #f3b100;
  border-color: #f3b100;
}

.bkg-hover-yellow:hover {
  background: #e2a50b;
  border-color: #e2a50b;
}

.bkg-yellow-light {
  background: #ffbe12;
  border-color: #ffbe12;
}

.bkg-hover-yellow-light:hover {
  background: #f3b100;
  border-color: #f3b100;
}

.bkg-orange {
  background: #e87f04;
  border-color: #e87f04;
}

.bkg-hover-orange:hover {
  background: #df6f0b;
  border-color: #df6f0b;
}

.bkg-orange-light {
  background: #f59d00;
  border-color: #f59d00;
}

.bkg-hover-orange-light:hover {
  background: #e87f04;
  border-color: #e87f04;
}

.bkg-red {
  background: #c23824;
  border-color: #c23824;
}

.bkg-hover-red:hover {
  background: #a9311f;
  border-color: #a9311f;
}

.bkg-red-light {
  background: #ea4b35;
  border-color: #ea4b35;
}

.bkg-hover-red-light:hover {
  background: #c23824;
  border-color: #c23824;
}

.bkg-black,
.bkg-hover-black:hover {
  background: #000;
}

.bkg-hover-white:hover,
.bkg-white {
  background: #fff;
  border-color: #fff;
}

.bkg-grey {
  background-color: #666;
  border-color: #666;
}

.bkg-hover-grey:hover {
  background-color: #555;
  border-color: #555;
}

.bkg-grey-light {
  background-color: #999;
  border-color: #999;
}

.bkg-hover-grey-light:hover {
  background-color: #888;
  border-color: #888;
}

.bkg-grey-ultralight {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.bkg-hover-grey-ultralight:hover {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

.bkg-charcoal {
  background-color: #333;
  border-color: #333;
}

.bkg-hover-charcoal:hover {
  background-color: #222;
  border-color: #222;
}

.bkg-charcoal-light {
  background-color: #555;
  border-color: #555;
}

.bkg-hover-charcoal-light:hover {
  background-color: #444;
  border-color: #444;
}

.bkg-black,
.bkg-hover-black:hover {
  background-color: #000;
  border-color: #000;
}

.bkg-base {
  background: #eee;
  border-color: #666;
  border-color: #eee;
}

.bkg-hover-base:hover {
  background: #d0d0d0;
  border-color: #666;
  border-color: #eee;
}

.bkg-behance,
.bkg-hover-behance:hover {
  background-color: #1769ff;
  border-color: #1769ff;
}

.bkg-dribbble,
.bkg-hover-dribbble:hover {
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.bkg-facebook,
.bkg-hover-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}

.bkg-flickr,
.bkg-hover-flickr:hover {
  background-color: #0063dc;
  border-color: #0063dc;
}

.bkg-github,
.bkg-hover-github:hover {
  background-color: #333;
  border-color: #333;
}

.bkg-google,
.bkg-hover-google:hover {
  background-color: #d34836;
  border-color: #d34836;
}

.bkg-hover-instagram:hover,
.bkg-instagram {
  background-color: #517fa4;
  border-color: #517fa4;
}

.bkg-hover-linkedin:hover,
.bkg-linkedin {
  background-color: #007bb6;
  border-color: #007bb6;
}

.bkg-hover-pinterest:hover,
.bkg-pinterest {
  background-color: #cb2027;
  border-color: #cb2027;
}

.bkg-hover-skype:hover,
.bkg-skype {
  background-color: #12a5f4;
  border-color: #12a5f4;
}

.bkg-hover-soundcloud:hover,
.bkg-soundcloud {
  background-color: #f80;
  border-color: #f80;
}

.bkg-hover-spotify:hover,
.bkg-spotify {
  background-color: #7ab800;
  border-color: #7ab800;
}

.bkg-hover-tumblr:hover,
.bkg-tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.bkg-hover-twitter:hover,
.bkg-twitter {
  background-color: #00aced;
  border-color: #00aced;
}

.bkg-hover-vimeo:hover,
.bkg-vimeo {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}

.bkg-hover-youtube:hover,
.bkg-youtube {
  background-color: #b00;
  border-color: #b00;
}

[class*="color-"] * {
  color: inherit !important;
}

.color-turquoise {
  color: #00a186;
}

.color-hover-turquoise:hover {
  color: #008b73;
}

.color-turquoise-light {
  color: #00bd9c;
}

.color-hover-turquoise-light:hover {
  color: #00a186;
}

.color-green {
  color: #1bb05d;
}

.color-hover-green:hover {
  color: #179851;
}

.color-green-light {
  color: #1ecd6d;
}

.color-hover-green-light:hover {
  color: #1bb05d;
}

.color-blue {
  color: #227fbb;
}

.color-hover-blue:hover {
  color: #1d6ea3;
}

.color-blue-light {
  color: #2c96dd;
}

.color-hover-blue-light:hover {
  color: #227fbb;
}

.color-navy {
  color: #2c3e51;
}

.color-hover-navy:hover {
  color: #1f2d3b;
}

.color-navy-light {
  color: #33495f;
}

.color-hover-navy-light:hover {
  color: #2c3e51;
}

.color-purple {
  color: #8f3faf;
}

.color-hover-purple:hover {
  color: #7b3697;
}

.color-purple-light {
  color: #b078c6;
}

.color-hover-purple-light:hover {
  color: #8f3faf;
}

.color-yellow {
  color: #f3b100;
}

.color-hover-yellow:hover {
  color: #e2a50b;
}

.color-yellow-light {
  color: #ffbe12;
}

.color-hover-yellow-light:hover {
  color: #f3b100;
}

.color-orange {
  color: #e87f04;
}

.color-hover-orange:hover {
  color: #df6f0b;
}

.color-orange-light {
  color: #f59d00;
}

.color-hover-orange-light:hover {
  color: #e87f04;
}

.color-red {
  color: #c23824;
}

.color-hover-red:hover {
  color: #a9311f;
}

.color-red-light {
  color: #ea4b35;
}

.color-hover-red-light:hover {
  color: #c23824;
}

.color-hover-white:hover,
.color-white {
  color: #fff;
}

.color-grey {
  color: #666;
}

.color-hover-grey:hover {
  color: #555;
}

.color-grey-light {
  color: #999;
}

.color-hover-grey-light:hover {
  color: #888;
}

.color-grey-ultralight {
  color: #f4f4f4;
}

.color-hover-grey-ultralight:hover {
  color: #f1f1f1;
}

.color-charcoal {
  color: #333;
}

.color-hover-charcoal:hover {
  color: #222;
}

.color-charcoal-light {
  color: #555;
}

.color-hover-charcoal-light:hover {
  color: #444;
}

.color-black,
.color-hover-black:hover {
  color: #000;
}

.color-base,
.color-hover-base:hover {
  color: #666;
}

.color-behance,
.color-hover-behance:hover {
  color: #1769ff;
}

.color-dribbble,
.color-hover-dribbble:hover {
  color: #ea4c89;
}

.color-facebook,
.color-hover-facebook:hover {
  color: #3b5998;
}

.color-flickr,
.color-hover-flickr:hover {
  color: #0063dc;
}

.color-github,
.color-hover-github:hover {
  color: #333;
}

.color-google,
.color-hover-google:hover {
  color: #d34836;
}

.color-hover-instagram:hover,
.color-instagram {
  color: #517fa4;
}

.color-hover-linkedin:hover,
.color-linkedin {
  color: #007bb6;
}

.color-hover-pinterest:hover,
.color-pinterest {
  color: #cb2027;
}

.color-hover-skype:hover,
.color-skype {
  color: #12a5f4;
}

.color-hover-soundcloud:hover,
.color-soundcloud {
  color: #f80;
}

.color-hover-spotify:hover,
.color-spotify {
  color: #7ab800;
}

.color-hover-tumblr:hover,
.color-tumblr {
  color: #35465c;
}

.color-hover-twitter:hover,
.color-twitter {
  color: #00aced;
}

.color-hover-vimeo:hover,
.color-vimeo {
  color: #1ab7ea;
}

.color-hover-youtube:hover,
.color-youtube {
  color: #b00;
}

[class*="bkg-"]::-webkit-input-placeholder,
[class*="color-"]::-webkit-input-placeholder,
[class*="border-"]::-webkit-input-placeholder,
[class*="bkg-"]::-moz-placeholder,
[class*="color-"]::-moz-placeholder,
[class*="border-"]::-moz-placeholder,
[class*="bkg-"]:-ms-input-placeholder,
[class*="color-"]:-ms-input-placeholder,
[class*="border-"]:-ms-input-placeholder {
  color: inherit;
}

.highlight {
  background: #eee;
  border-color: #eee;
}

.info {
  background: #d9edf7;
  color: #31708f;
  border-color: #d9edf7;
}

.success {
  background: #dff0d8;
  color: #3c763d;
  border-color: #dff0d8;
}

.alert {
  background: #fcf8e3;
  color: #8a6d3b;
  border-color: #fcf8e3;
}

.warning {
  background: #f2dede;
  color: #a9444a;
  border-color: #f2dede;
}

.shadow-hover:hover {
  -webkit-box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.4rem 2.4rem 0 rgba(0, 0, 0, 0.15);
}

.bar,
.box {
  -webkit-transition-property:
    background,
    border-color,
    color,
    opacity,
    box-shadow,
    -webkit-transform;
  transition-property: background, border-color, color, opacity, box-shadow, transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.form-element {
  -webkit-transition-property:
    background,
    border-color,
    color,
    opacity,
    box-shadow,
    -webkit-transform;
  transition-property: background, border-color, color, opacity, box-shadow, transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;

  + label:before {
    -webkit-transition-property:
      background,
      border-color,
      color,
      opacity,
      box-shadow,
      -webkit-transform;
    transition-property: background, border-color, color, opacity, box-shadow, transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }
}

.overlay-info {
  -webkit-transition-property:
    background,
    border-color,
    color,
    opacity,
    box-shadow,
    -webkit-transform;
  transition-property: background, border-color, color, opacity, box-shadow, transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.thumbnail {
  -webkit-transition-property:
    background,
    border-color,
    color,
    opacity,
    box-shadow,
    -webkit-transform;
  transition-property: background, border-color, color, opacity, box-shadow, transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;

  .background-image,
  img {
    -webkit-transition-property:
      background,
      border-color,
      color,
      opacity,
      box-shadow,
      -webkit-transform;
    transition-property: background, border-color, color, opacity, box-shadow, transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }
}

a,
button,
input,
textarea {
  -webkit-transition-property:
    background,
    border-color,
    color,
    opacity,
    box-shadow,
    -webkit-transform;
  transition-property: background, border-color, color, opacity, box-shadow, transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.no-transition,
.no-transition-reset {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

@media only screen and (max-width: 1140px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 96rem;
  }

  .navigation li:last-child .sub-menu {
    right: 0;
  }

  .content-grid-5 .grid-item,
  .content-grid-6 .grid-item {
    width: 25%;
  }

  .feature-column-group .width-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 1023px) {
  .navigation > ul > li:nth-last-child(2) > .sub-menu {
    right: 0;

    ul {
      right: 18rem;
      left: auto;
    }
  }

  .content-grid-5 .grid-item,
  .content-grid-6 .grid-item {
    width: 33.33333%;
  }
}

@media only screen and (min-width: 960px) {
  .pricing-table .pricing-table-column:not(:first-child):not(.callout),
  .table > tbody > tr > td:first-child {
    border-left: none;
  }
}

@media only screen and (max-width: 960px) {
  .two-columns-on-tablet > [class*="width-"] {
    width: 50%;
  }

  .one-column-on-tablet > [class*="width-"] {
    width: 100%;
    margin-left: 0;
    position: static;
    left: 0;
    right: 0;
  }

  .two-columns-on-tablet > [class*="width-"] {
    margin-left: 0;
    position: static;
    left: 0;
    right: 0;
  }

  .row,
  body.boxed .wrapper-inner {
    max-width: 76rem;
  }

  [class*="content-grid-"] .grid-item {
    width: 50%;
  }

  header {
    .primary-nav-column,
    .primary-navigation {
      display: none !important;
    }

    .logo-column,
    .secondary-nav-column {
      width: auto !important;
    }

    .logo-column {
      float: left;
    }

    .secondary-nav-column {
      float: right;
    }
  }

  .nav-bar .logo {
    margin: 0;
    float: left;

    &.logo-center {
      margin: 0;
      float: left;
    }
  }

  .aux-navigation {
    display: table !important;
    visibility: visible !important;
  }

  .feature-column-group .feature-column {
    padding: 4rem;
  }

  .pricing-table {
    &.columns-2 .pricing-table-column,
    &.columns-3 .pricing-table-column,
    &.columns-4 .pricing-table-column,
    &.columns-5 .pricing-table-column {
      width: 50%;
    }

    &[class*="border-"] {
      &.columns-3 .pricing-table-column:last-child,
      &.columns-4 .pricing-table-column:nth-last-child(2) {
        border-left-width: 1px;
      }

      &.columns-5 .pricing-table-column {
        &:last-child,
        &:nth-last-child(3) {
          border-left-width: 1px;
        }
      }
    }

    &.rounded {
      &.columns-3 .pricing-table-column:last-child,
      &.columns-5 .pricing-table-column:last-child {
        border-radius: 0.3rem;
      }

      &.columns-4 .pricing-table-column:nth-last-child(2),
      &.columns-5 .pricing-table-column:nth-last-child(3) {
        border-radius: 0.3rem 0 0 0.3rem;
      }
    }
  }

  .table {
    &:not(.non-responsive) {
      max-width: 100%;
      display: inline-block;
      position: relative;
    }

    tr:last-child {
      width: 100%;
    }

    &:not(.non-responsive) {
      > {
        thead {
          display: block;
          float: left;

          > tr {
            display: block;
            float: left;

            > th {
              display: block;
            }
          }
        }

        tbody {
          display: flex;
          position: relative;
          overflow-x: auto;
          white-space: nowrap;

          > tr {
            display: inline-block;
            vertical-align: top;
            margin-right: -0.4rem;

            > td {
              display: block;
            }
          }
        }
      }

      th + th {
        border-left: none;
        border-bottom: 1px solid transparent;
      }
    }
  }

  .hide-on-tablet {
    display: none !important;
    visibility: hidden !important;
  }

  .show-on-tablet {
    display: block !important;
    visibility: visible !important;
  }

  .clear-height-on-tablet {
    height: auto !important;
  }

  .one-column-on-tablet {
    .freeze,
    .sticky {
      position: static !important;
    }
  }
}

@media (min-width: 768px) {
  .timeline {
    .timeline-item {
      display: inline-block;
    }

    &.center .timeline-item {
      padding-left: 0;

      &:before {
        margin-left: -0.7rem;
        left: 50%;
      }

      &:after {
        margin-left: -0.1rem;
        left: 50%;
      }
    }

    &.right .timeline-item {
      text-align: right;

      &:before {
        margin-right: -0.7rem;
        right: 0;
        left: auto;
      }

      &:after {
        margin-right: -0.1rem;
        right: 0;
        left: auto;
      }
    }

    &.center {
      .timeline-content,
      .timeline-info {
        width: 50%;
      }

      .timeline-item {
        .timeline-info,
        &.entry-left .timeline-content {
          float: left;
          text-align: right;
          padding-right: 3rem;
          padding-left: 0;
        }

        .timeline-content,
        &.entry-left .timeline-info {
          float: right;
          text-align: left;
          padding-left: 3rem;
        }
      }
    }

    &.right .timeline-item {
      padding-left: 0;
      padding-right: 3rem;
    }

    &.center {
      .timeline-item.timeline-section {
        .timeline-content,
        .timeline-info {
          float: none;
          padding: 0;
          width: 100%;
          text-align: center;
        }
      }

      .timeline-section {
        text-align: center;

        .timeline-marker:after {
          height: 3rem;
          bottom: 0;
          top: auto;
        }

        .timeline-title {
          left: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .two-columns-on-tablet > [class*="width-"],
  .width-1,
  .width-10,
  .width-11,
  .width-12,
  .width-2,
  .width-3,
  .width-4,
  .width-5,
  .width-6,
  .width-7,
  .width-8,
  .width-9,
  [class*="content-grid-"] .grid-item {
    width: 100%;
  }

  .row,
  body.boxed .wrapper-inner {
    max-width: 60rem;
  }

  [class*="offset-"] {
    margin-left: 0;
  }

  .column {
    &[class*="push-"],
    &[class*="pull-"] {
      position: static;
      left: 0;
      right: 0;
    }
  }

  .dropdown .login-form-container .button {
    width: 100%;
    display: block;
    text-align: center;
  }

  .left-on-mobile {
    .dropdown-list {
      left: 0;
    }

    .button,
    button {
      margin-right: 1rem;
      margin-left: 0;
    }
  }

  .center-on-mobile {
    .button,
    button {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .right-on-mobile {
    .button,
    button {
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  .button.no-label-on-mobile span:not([class*="icon-"]),
  button.no-label-on-mobile span:not([class*="icon-"]) {
    display: none;
  }

  .button.no-label-on-mobile [class*="icon-"],
  button.no-label-on-mobile [class*="icon-"] {
    margin: 0;
  }

  blockquote {
    width: 100% !important;
  }

  .lead {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 200;
  }

  .feature-column-group .width-3 {
    width: 100%;
  }

  .pricing-table.columns-3 .pricing-table-column {
    &.callout {
      top: 0;
    }

    margin-bottom: 0.5rem;
  }

  .hide-on-mobile {
    display: none !important;
    visibility: hidden !important;
  }

  .show-on-mobile {
    display: block !important;
    visibility: visible !important;
  }

  .pull-left-on-mobile {
    float: left !important;
  }

  .pull-right-on-mobile {
    float: right !important;
  }

  .clear-float-on-mobile {
    float: none !important;
  }

  .v-align-top,
  .v-align-top-on-mobile {
    padding-top: 2rem !important;
    align-self: flex-start !important;
    vertical-align: top !important;
  }

  .v-align-bottom,
  .v-align-bottom-on-mobile {
    padding-bottom: 2rem !important;
    align-self: flex-end !important;
    vertical-align: bottom !important;
  }

  .no-padding-on-mobile {
    padding: 0 !important;
  }

  .no-background-on-mobile {
    background: 0 0 !important;
    border: none !important;
  }

  .thumbnail {
    .v-align-bottom,
    .v-align-bottom-on-mobile,
    .v-align-top,
    .v-align-top-on-mobile {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .left-on-mobile {
    text-align: left !important;
  }

  .center-on-mobile {
    text-align: center !important;
  }

  .right-on-mobile {
    text-align: right !important;
  }

  .no-transition-on-mobile {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
  }

  .clear-height-on-mobile {
    height: auto !important;
  }

  .freeze,
  .sticky {
    position: static !important;
  }
}

@media only screen and (max-width: 600px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 48rem;
  }

  .pricing-table {
    &.columns-2 .pricing-table-column,
    &.columns-3 .pricing-table-column,
    &.columns-4 .pricing-table-column,
    &.columns-5 .pricing-table-column {
      width: 100%;
    }

    &.rounded .pricing-table-column {
      border-radius: 0.3rem !important;
    }

    .pricing-table-column {
      &.callout {
        top: 0;
      }

      margin-bottom: 0.5rem;
    }

    &[class*="border-"] .pricing-table-column {
      border-left-width: 1px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 35rem;
  }

  [class*="content-grid-"] .grid-item {
    width: 100%;
  }

  .side-navigation-wrapper {
    width: 30rem;
  }

  .element-reveal-left {
    -webkit-transform: translate3d(30rem, 0, 0);
    transform: translate3d(30rem, 0, 0);
  }

  .element-reveal-right {
    -webkit-transform: translate3d(-30rem, 0, 0);
    transform: translate3d(-30rem, 0, 0);
  }

  .side-navigation-wrapper {
    &.hide {
      right: 30rem;
    }

    &.enter-right.hide {
      right: -30rem;
    }
  }

  .element-show-left {
    left: 30rem;
  }

  .element-show-right {
    left: -30rem;
  }

  .list-group {
    margin-bottom: 0;
  }

  blockquote {
    width: 100% !important;
  }

  .tabs.vertical {
    .tab-nav,
    .tab-panes {
      width: 100% !important;
      float: none;
    }

    &.right {
      .tab-nav,
      .tab-panes {
        width: 100% !important;
        float: none;
      }
    }
  }

  .tab-nav li {
    width: 100%;
    float: none;

    a {
      width: 100%;
    }
  }

  .tabs {
    &.rounded {
      .tab-nav > li {
        &:first-child a {
          border-radius: 0.3rem 0.3rem 0 0 !important;
        }

        &:last-child a {
          border-radius: 0 !important;
        }
      }

      .tab-panes {
        border-radius: 0 0 0.3rem 0.3rem;
      }
    }

    li.active:last-child a {
      border-bottom: 1px solid #eee;
    }

    &.vertical {
      li.active a,
      &.right li.active a {
        border-left: 1px solid #eee;
      }

      .tab-panes,
      &.right .tab-panes {
        border-top: none;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 100%;
  }
}

.mobile .tm-slider-parallax-container .tm-slider-container,
.tm-slider-container {
  background: 0 0;
  position: relative;
}

.tm-slider-parallax-container .tm-slider-container:not(.out-of-view) {
  position: fixed;
}

.tm-slider-container:not(.out-of-view) .tms-content-inner > .row > .column,
.tm-slider-parallax-container .tm-slider-container:not(.out-of-view),
.tms-slide {
  will-change: transform, opacity;
}

.tm-slider-container {
  &.background-slider {
    position: absolute;
    z-index: 0;
  }

  &.full-width-slider {
    width: 100%;
  }

  &.fullscreen {
    width: 100%;
    width: 100%;
    height: 100%;
    height: 100vh;
  }
}

.tms-overlay,
.tms-slide,
.tms-slides {
  height: 100%;
  width: 100%;
}

.tm-slider-container {
  &.tms-carousel > ul {
    opacity: 1;

    > li {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &.tms-scalable-height {
    -webkit-transition-property: height, opacity;
    -moz-transition-property: height, opacity;
    -o-transition-property: height, opacity;
    -ms-transition-property: height, opacity;
    transition-property: height, opacity;
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    -ms-transition-duration: 0.6s;
    transition-duration: 0.6s;
  }
}

.tms-content-scalable {
  -webkit-transition-property: height, opacity;
  -moz-transition-property: height, opacity;
  -o-transition-property: height, opacity;
  -ms-transition-property: height, opacity;
  transition-property: height, opacity;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  -ms-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.tms-slides {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.tms-slide {
  visibility: hidden;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  z-index: 1;
}

.tms-forcefit {
  background-size: cover;
}

.tms-carousel .tms-slide {
  float: left;
  position: relative;
}

.tms-slide.active {
  visibility: visible;
}

.tms-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.tms-error {
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
  position: relative;
  top: 50%;
}

.tms-slide > {
  embed,
  iframe,
  object {
    position: relative;
    opacity: 0;
  }

  video {
    position: relative;
    opacity: 0;
    z-index: 1;
  }
}

.tms-caption,
.tms-content,
.tms-progress-bar {
  z-index: 2;
  position: absolute;
}

.tms-slide {
  &.tms-video-no-ratio:not(.tms-forcefit) > {
    embed,
    iframe,
    object,
    video {
      width: 100% !important;
      height: 100% !important;
      position: static;
    }
  }

  img {
    position: relative;
  }

  &.tms-forcefit > {
    embed,
    iframe,
    object {
      position: relative;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    display: block;
    float: none;
    transform: translate(0, 0);
  }
}

.tms-carousel .tms-slide {
  img {
    max-height: none;
  }

  .thumbnail {
    height: auto !important;
  }
}

.tms-fullscreen .tms-slide > img {
  max-width: none;
  max-height: none;
  float: none;
  position: absolute;
}

.tms-slide {
  &.tms-forcefit > img,
  > video + img {
    max-width: none;
    max-height: none;
    float: none;
    position: absolute;
  }
}

.mobile {
  [data-video-bkg-vimeo] iframe,
  [data-video-bkg-youtube] iframe {
    opacity: 0 !important;
  }
}

[data-video-bkg-vimeo] {
  iframe,
  img {
    opacity: 0 !important;
  }
}

[data-video-bkg-youtube] {
  iframe,
  img {
    opacity: 0 !important;
  }
}

[data-video-bkg] img {
  opacity: 0 !important;
}

[data-video-bkg-vimeo],
[data-video-bkg-youtube] {
  background-size: 0;
}

.mobile .tms-slide,
.tms-slide.error,
.video-bkg-loaded iframe {
  background-size: cover !important;
  opacity: 1 !important;
}

.img-loaded .tms-overlay,
.mobile .tms-bkg-video .tms-overlay,
.video-bkg-loaded .tms-overlay {
  opacity: 1 !important;
}

.mobile .tms-slide,
.tms-overlay,
.tms-slide.error,
.video-bkg-loaded iframe {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
}

.tms-progress-bar {
  width: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.tms-caption {
  &:not([class*="width-"]) {
    width: auto;
  }

  display: inline-block !important;
  opacity: 0;

  &.scale {
    line-height: 1;
  }

  embed,
  iframe,
  object,
  video {
    width: 100% !important;
    height: 100% !important;
  }

  &.no-transition {
    opacity: 1 !important;
    visibility: hidden;
    display: block !important;
  }

  + .tms-caption [class*="icon-"] {
    margin-left: 1rem;
  }
}

.tms-content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: table;
  table-layout: fixed;
  text-align: center;
}

.tms-content-inner {
  height: 100%;
  vertical-align: middle;
  display: table-cell;
  float: none;
}

.tms-content-scalable {
  padding-bottom: 3rem;
  opacity: 0;
}

.tms-content-inner .tms-caption {
  position: static;
}

.tms-fullscreen .tms-caption,
.tms-slide .row .tms-content {
  position: relative;
}

.tms-content-inner {
  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.v-align-top {
    padding-top: 7rem;
    vertical-align: top;
  }

  &.v-align-bottom {
    padding-bottom: 7rem;
    vertical-align: bottom;
  }
}

.tms-slide {
  .column,
  .row {
    height: 100%;
  }
}

.tms-content {
  .column,
  .row {
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .tms-slide .row {
    .tms-content,
    .tms-content-inner {
      display: block;
    }
  }

  .tms-caption.hide-on-mobile {
    display: none !important;
  }
}

.tms-arrow-nav,
.tms-pagination {
  display: none;
  z-index: 10;
  position: absolute;
  text-align: center;
}

.tms-arrow-nav {
  margin-top: -2.2rem;
  width: 4.4rem;
  height: 4.4rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ccc;
  font-size: 3rem;
  line-height: 5rem;

  &:hover {
    width: 7rem;
    color: #fff;
  }
}

.tms-carousel.tms-scalable-height .tms-arrow-nav {
  margin-top: -3.7rem;
}

#tms-next {
  top: 50%;
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &:before {
    content: "\e644";
  }
}

#tms-prev {
  top: 50%;
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tms-pagination {
  width: 100%;
  bottom: 2rem;
  line-height: 0;
}

.tms-carousel .tms-pagination {
  bottom: 0;
}

.tms-bullet-nav {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  display: inline-block;
  background: #fff;
  opacity: 0.5;
  border-radius: 50%;
}

.tm-lightbox,
.tm-slider-container.pagination-below .tms-bullet-nav {
  background: #000;
}

.tms-thumb-nav {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
}

.tms-bullet-nav {
  &.active,
  &:hover {
    opacity: 1;
  }
}

.tms-thumb-nav {
  &.active,
  &:hover {
    opacity: 1;
  }
}

.tm-slider-container.show-on-hover {
  .tms-arrow-nav,
  .tms-pagination {
    opacity: 0;
  }

  &:hover {
    .tms-arrow-nav,
    .tms-pagination {
      opacity: 1;
    }
  }
}

#tml-caption,
#tml-content,
.tm-lightbox {
  opacity: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
}

.tm-slider-container {
  &.background-slider {
    .tms-arrow-nav,
    .tms-pagination {
      display: none !important;
    }
  }

  &.pagination-below .tms-pagination {
    bottom: -3rem;
  }
}

.tms-arrow-nav,
.tms-pagination {
  -webkit-transition-property: width, background, color, opacity;
  -moz-transition-property: width, background, color, opacity;
  -o-transition-property: width, background, color, opacity;
  -ms-transition-property: width, background, color, opacity;
  transition-property: width, background, color, opacity;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@font-face {
  font-family: icomoon;
  src: url(../font/entypo.eot);
  src:
    url(../font/entypo.eot) format("embedded-opentype"),
    url(../font/entypo.woff) format("woff"),
    url(../font/entypo.ttf) format("truetype"),
    url(../font/entypo.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*="tms-arrow-nav"]:before,
[class^="tms-arrow-nav"]:before {
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

#tml-content,
#tml-content-wrapper,
#tml-lightbox {
  position: absolute;
}

.tm-lightbox {
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
}

#tml-caption,
#tml-tool-bar,
.tml-nav {
  position: absolute;
}

#tml-content {
  visibility: hidden;
}

.tml-modal-mode {
  .modal-dialog {
    background: #fff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  }

  &.height-auto .modal-dialog {
    height: auto !important;
  }

  &.rounded .modal-dialog {
    border-radius: 0.3rem;
  }

  .modal-dialog .modal-dialog-inner {
    padding: 3rem;
  }
}

#tm-lightbox.tml-modal-mode {
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.2);
}

.tml-modal-mode {
  &.rounded .modal-dialog {
    .modal-header,
    div {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
  }

  .modal-dialog {
    .modal-header {
      padding: 1rem 3rem;
      background-color: #f4f4f4;
    }

    .modal-dialog-inner {
      .full-width {
        margin: 0 -3rem 3rem;
      }

      .modal-header {
        margin: 0 -3rem 3rem;
        margin-top: -3rem;
      }
    }

    .modal-header-title {
      margin-bottom: 0;
    }
  }

  .slide-in-top {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
  }

  .slide-in-bottom {
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
  }

  .scale-in {
    -webkit-transform: scale(0.9);
    transform: translateY(0.9);
  }

  .scale-out {
    -webkit-transform: scale(1.1);
    transform: translateY(1.1);
  }

  .scale-in,
  .slide-in-bottom,
  .slide-in-top {
    -webkit-duration: 0.5s;
    duration: 0.5s;
  }
}

#tml-content-wrapper.zoomed {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

#tml-content {
  &.scrollable-content:not(.modal-dialog) {
    background: #fff;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  &.tml-error {
    width: 100%;
    background: 0 0;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    top: 50%;
    left: 0;
    box-shadow: none;
  }
}

@media only screen and (max-width: 768px) {
  .tml-modal-mode .modal-dialog .modal-dialog-inner {
    padding: 1rem;

    .full-width {
      margin: 0 -1rem 3rem;
    }

    .modal-header {
      margin: 0 -1rem 3rem;
      margin-top: -1rem;
    }
  }
}

#tml-next,
#tml-prev {
  top: 50%;
  margin-top: -2.1rem;
}

@media only screen and (max-width: 479px) {
  #tml-content-wrapper {
    left: 10% !important;
    right: 10% !important;
  }

  .tml-modal-mode #tml-content-wrapper {
    left: 5% !important;
    right: 5% !important;
  }
}

#tml-content {
  embed,
  iframe,
  object,
  video {
    width: 100% !important;
    height: 100% !important;
  }
}

#tml-caption {
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
  bottom: 0;

  span {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 1rem 1.5rem;
    border-top: 0.1rem solid #eee;
  }
}

.tml-nav {
  width: 4.4rem;
  height: 4.4rem;
  font-size: 2rem;
  text-align: center;
  text-transform: capitalize;
  line-height: 4.4rem;
  opacity: 0.4;
  z-index: 10;

  &:hover {
    opacity: 1;
  }
}

#tml-next {
  right: 3rem;

  &:before {
    content: "\e644";
  }
}

#tml-prev {
  left: 3rem;

  &:before {
    content: "\e643";
  }
}

#tml-exit {
  top: 1.5rem;
  right: 3rem;
}

.tml-modal-mode #tml-exit {
  top: 0;
  right: 0;
}

#tml-tool-bar {
  margin: 0;
  display: inline-block;
  top: 1.5rem;
  left: 3rem;

  .tml-nav {
    font-size: 1.5rem;
  }

  #tml-share-wrapper,
  .tml-nav {
    float: left;
    position: relative;
  }

  .tml-social-list {
    float: left;
    position: relative;

    &.list-vertical {
      float: none;
      text-align: left;

      .tml-nav {
        float: none;
        text-align: left;
      }
    }

    margin: 0;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: 101;
    -webkit-transition-property: -webkit-transform, opacity, visibility;
    -moz-transition-property: -moz-transform, opacity, visibility;
    -o-transition-property: -o-transform, opacity, visibility;
    transition-property: transform, opacity, visibility;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;

    li a {
      margin-right: 1rem;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .tml-nav {
      width: auto;
      min-width: 4.4rem;
      font-size: 1.1rem;
    }
  }

  #tml-zoom:before {
    content: "\e6cd";
  }

  #tml-share:before {
    content: "\e70b";
  }
}

#tml-exit:before {
  content: "\e66f";
}

.tml-nav {
  -webkit-transition-property: color, opacity;
  -moz-transition-property: color, opacity;
  -o-transition-property: color, opacity;
  -ms-transition-property: color, opacity;
  transition-property: color, opacity;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@font-face {
  font-family: icomoon;
  src: url(../font/entypo.eot);
  src:
    url(../font/entypo.eot) format("embedded-opentype"),
    url(../font/entypo.woff) format("woff"),
    url(../font/entypo.ttf) format("truetype"),
    url(../font/entypo.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*="tml-nav"]:before,
[class^="tml-nav"]:before {
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

.parallax {
  height: 55rem;
  background: #000;
  position: relative;
  will-change: contents;

  &.fullscreen {
    width: 100%;
    height: 100%;
    height: 100vh;
  }

  .horizon {
    opacity: 0;
    visibility: hidden;
  }
}

.tm-parallax,
.tmp-content,
.tmp-content-inner {
  width: 100%;
  height: 100%;
}

.tmp-content {
  max-width: 114rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: table;
}

.tm-parallax {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  &:not(.out-of-view) {
    will-change: transform, opacity;
  }
}

.tmp-content-inner {
  height: 100%;
  vertical-align: middle;
  display: table-cell;
  float: none;
  position: relative;
  z-index: 1;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.v-align-top {
    padding-top: 7rem;
    vertical-align: top;
  }

  &.v-align-bottom {
    padding-bottom: 7rem;
    vertical-align: bottom;
  }
}

.tmp-mobile {
  height: "auto";
}

.tm-parallax img {
  width: 100%;
  max-width: none;
  position: absolute;
  opacity: 1;
  -webkit-backface-visibility: hidden;
}

.grid-container.full-width .row,
.header .header-inner,
.masonry-set-dimensions.full-width .row {
  max-width: 100%;
}

.tm-loader {
  width: 5rem;
  height: 5rem;
  margin: -2.5rem 0 0 -2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  #circle {
    background: 0 0;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: center center;
    animation: rotate 2s linear infinite;
  }
}

.content,
.section-block {
  position: relative;
}

.stroke {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke-width: 0.2rem;
}

.ie-browser:not(.webkit) .tm-loader #circle {
  border: 3px solid transparent;
  border-bottom-color: #ddd;
  animation:
    rotate 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  0%,
  100%,
  66% {
    stroke: #ddd;
    border-bottom-color: #ddd;
  }

  40%,
  80%,
  90% {
    stroke: #666;
    border-bottom-color: #666;
  }
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tmh-perspective {
  overflow: visible !important;
}

.tmh-perspective-parallax {
  perspective: 1600px;
  overflow: visible !important;
}

.feature-1,
.feature-2,
.feature-3,
.header .logo-inner,
.masonry-set-dimensions .grid-item {
  overflow: hidden;
}

.tmh-parallax-item {
  opacity: 1;
  visibility: visible !important;
}

.no-js {
  opacity: 1;
  visibility: visible;

  .horizon {
    opacity: 1;
    visibility: visible;
  }
}

.content {
  background-color: #fff;
}

body {
  background-color: #fff;

  &.boxed {
    background-color: #000;

    .wrapper-inner {
      background-color: #fff;
    }
  }
}

.section-block {
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-size: cover;
  background-position: center;
}

.replicable-content {
  padding-bottom: 4rem;
}

.audio-container,
.box,
.button-group,
.contact-form-container,
.content-slider,
.feature-column,
.lead,
.menu-box {
  margin-bottom: 3rem;
}

.menu-list {
  margin-bottom: 3rem;

  li {
    margin-bottom: 3rem;
  }
}

.pricing-table,
.progress-bar-group,
.recent-slider,
.signup-form-container,
.table,
.testimonial-2 blockquote,
.text-column,
.thumbnail,
.video-container {
  margin-bottom: 3rem;
}

.section-block.featured-media {
  padding-top: 0;
  padding-bottom: 0;
}

body.boxed .section-block {
  &.featured-media {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.grid-container.small-margins {
  .grid,
  &.full-width .grid {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .section-block.section-overlap {
    padding-top: 0;
    margin-top: -7rem;
    background-color: transparent !important;
    z-index: 2;
  }
}

@media only screen and (max-width: 960px) {
  .content-inner:not([class*="offset-"]),
  .sidebar {
    width: 100%;
  }

  .blog [class*="pagination-"] .column {
    &[class*="push-"],
    &[class*="pull-"] {
      position: static;
      left: 0;
      right: 0;
    }
  }

  .content-inner.column {
    &[class*="push-"],
    &[class*="pull-"] {
      position: static;
      left: 0;
      right: 0;
    }
  }

  .sidebar.column {
    &[class*="push-"],
    &[class*="pull-"] {
      position: static;
      left: 0;
      right: 0;
    }
  }
}

.background-fixed {
  background-attachment: fixed;

  &:after,
  &:before {
    background-attachment: fixed;
  }
}

.mobile .background-fixed {
  background-attachment: scroll;

  &:after,
  &:before {
    background-attachment: scroll;
  }
}

.background-cover {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;

  &:after,
  &:before {
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.fullscreen-section.background-cover .background-image {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-contain {
  background-position: center center;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;

  &:after,
  &:before {
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.fullscreen-section.background-contain .background-image {
  background-position: center center;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.background-full,
.fullscreen-section.background-full .background-image {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.background-none {
  background: 0 0 !important;
}

.background-image-none {
  background-image: none !important;
}

.horizon {
  opacity: 0;
  visibility: hidden;
}

.aux-navigation-active {
  .one-page-nav + .wrapper {
    position: absolute;
  }

  .overlay-navigation-wrapper.one-page-nav,
  .side-navigation-wrapper.one-page-nav {
    position: fixed;
  }
}

.wrapper.inactive {
  .header-fixed,
  .tm-slider-parallax-container .tm-slider-container {
    position: relative !important;
  }

  .header-fixed-on-mobile .header-inner {
    position: absolute !important;
  }
}

.media-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  + * {
    position: relative;
  }
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.contact-form-container input[disabled="disabled"][type="submit"],
.opacity-06,
.signup-form-container input[disabled="disabled"][type="submit"] {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.grid-container {
  position: relative;

  .grid {
    max-width: 100%;
    min-height: 30rem;
    margin-bottom: -3rem;
    visibility: hidden;
    z-index: 1;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.masonry {
  &.fade-in-progressively {
    .grid-item,
    .masonry-stamp {
      opacity: 0;
      -webkit-transition-property: -webkit-transform, opacity;
      transition-property: transform, opacity;
      -webkit-transition-duration: 0.6s;
      transition-duration: 0.6s;
      will-change: transform, opacity;
    }

    &.slide-in-bottom {
      .grid-item,
      .masonry-stamp {
        -webkit-transform: translateY(2rem);
        transform: translateY(2rem);
      }
    }

    &.scale-out {
      .grid-item,
      .masonry-stamp {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }

    &.scale-in {
      .grid-item,
      .masonry-stamp {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }
    }

    .grid-item.animate-in,
    .masonry-stamp.animate-in {
      opacity: 1;
      -webkit-transform: translateY(0) scale(1);
      transform: translateY(0) scale(1);
    }
  }

  .filtering {
    -webkit-transition-property: height;
    transition-property: height;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
  }

  .tm-loader {
    top: 6rem;
  }
}

.grid-container {
  > .row > .column {
    padding-left: 0;
    padding-right: 0;
  }

  .grid .grid-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .thumbnail {
    margin-bottom: 0;
    display: block;
    float: none;
  }

  &.small-margins {
    > .row > .column {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.full-width > .row > .column {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

body.boxed .grid-container.small-margins.full-width > .row > .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-container {
  &.small-margins {
    &.no-margins.full-width > .row > .column {
      padding-left: 0;
      padding-right: 0;
    }

    .grid {
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;

      .grid-item {
        margin-bottom: 0;
        padding: 0.5rem;
        position: relative;
      }
    }
  }

  &.no-margins {
    > .row > .column {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .grid {
      margin-bottom: 0;
      padding: 0 !important;
      margin: 0 !important;

      .grid-item {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  &.full-width {
    .grid {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    > .row > .column {
      padding-left: 0;
      padding-right: 0;
    }

    &.no-margins {
      padding: 0 !important;
    }

    &.no-padding-bottom .grid {
      margin-bottom: 0;
    }
  }
}

.masonry-set-dimensions {
  &.full-width {
    &.no-padding-bottom {
      padding-bottom: 1.5rem !important;
    }

    &.small-margins.no-padding-bottom {
      padding-bottom: 0.5rem !important;
    }

    .grid {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }

    > .row > .column {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  padding-top: 7rem;
  padding-bottom: 6rem;

  &.small-margins.full-width > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.no-margins .grid {
    padding: 0 !important;
    margin: 0 !important;

    .grid-item {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .grid,
  > .row > .column {
    padding-left: 0;
    padding-right: 0;
  }
}

.masonry > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.masonry-set-dimensions {
  &.full-width.no-margins {
    padding: 0;
  }

  .grid {
    margin-top: -1.5rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -1.5rem;

    .grid-item {
      margin-bottom: 0;
      padding: 1.5rem;
      position: relative;
    }
  }

  &.small-margins {
    > .row > .column {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .grid {
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;

      .grid-item {
        padding: 0.5rem;
      }
    }
  }

  &.no-margins {
    > .row > .column {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &.full-width > .row > .column {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .thumbnail {
    height: 100%;
    overflow: hidden;
    margin-bottom: 0;
  }

  .grid-item > img {
    width: 100%;
  }

  .content-outer {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
    bottom: 0;
  }

  .thumbnail + .content-outer {
    position: absolute;
  }

  .content-inner {
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: table-cell;
    vertical-align: middle;

    > {
      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .content-slider,
  iframe,
  video {
    width: 100% !important;
    height: 100% !important;
  }

  .content-slider img {
    width: auto;
  }

  .mejs-container {
    width: 20rem;
    height: 3rem;
    margin-left: -10rem;
    margin-top: -1.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.masonry-stamp {
  padding: 1.5rem;
}

.small-margins .masonry-stamp {
  padding: 0.5rem;
}

.no-margins .masonry-stamp {
  padding: 0;
}

.grid-filter-menu {
  padding-top: 3.5rem;
  padding-bottom: 0;
  text-align: center;

  &.left {
    text-align: left;

    li:first-child a {
      padding-left: 0;
      margin-left: 0;
    }
  }

  &.right {
    text-align: right;

    li:last-child a {
      padding-right: 0;
      margin-right: 0;
    }
  }

  ul {
    width: 100%;
    padding-top: 3.5rem;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-left: 0;
    list-style: none;
  }

  li {
    padding: 0 2rem;
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
  }

  a {
    padding: 1rem 0.8rem;
    -webkit-transition-property: background, border-color, color, opacity;
    transition-property: background, border-color, color, opacity;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;

    &:hover {
      color: #333;
    }

    &.active {
      color: #333;
      border-bottom: 1px solid #333;
    }
  }
}

.grid {
  .project-description {
    display: block;
  }

  .project-title {
    display: block;

    + .project-description {
      margin-top: 0.5rem;
    }
  }

  .item-description {
    width: 100%;
    margin-top: 2rem;

    .project-title {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;

  .isotope-item {
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;

    &.no-transition {
      -webkit-transition-duration: 0s;
      transition-duration: 0s;
    }
  }

  -webkit-transition-property: height, width;
  transition-property: height, width;

  &.no-transition {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;

    .isotope-item {
      -webkit-transition-duration: 0s;
      transition-duration: 0s;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .grid-container {
    .content-grid-5 .grid-item,
    .content-grid-6 .grid-item {
      width: 25%;
    }
  }
}

@media only screen and (max-width: 1140px) {
  .grid-container .grid .grid-item {
    width: 33.33333%;
  }

  .content-inner .grid-container .grid .grid-item {
    width: 50%;
  }

  .grid-container {
    .content-grid-2 .grid-item {
      width: 50%;
    }

    &.full-width {
      &.no-margins .content-grid-2 .grid-item,
      &.small-margins .content-grid-2 .grid-item {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .content-inner .grid-container .grid .grid-item {
    width: 50%;
  }

  .grid-container {
    .grid .grid-item {
      width: 50%;
    }

    &.full-width {
      &.no-margins .grid:not(.content-grid-2) .grid-item,
      &.small-margins .grid .grid-item {
        width: 33.33333%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .grid-container {
    .grid .grid-item {
      &.large,
      &.portrait.large {
        width: 100%;
      }
    }

    &.full-width {
      .grid .grid-item,
      &.no-margins .grid .grid-item,
      &.small-margins .grid .grid-item {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .content-inner .grid-container .grid .grid-item {
    width: 100%;
  }

  .grid-container {
    .grid .grid-item,
    &.full-width .grid .grid-item {
      width: 100%;
    }

    &:not(.small-margins):not(.no-margins) .grid .grid-item.no-image {
      height: auto !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  .grid-container {
    &.full-width {
      &.no-margins .grid .grid-item,
      &.small-margins .grid .grid-item {
        width: 100%;
      }
    }

    .grid .grid-item.no-image {
      height: auto !important;
    }
  }
}

.header {
  width: 100%;
  position: relative;

  .header-inner {
    width: 100%;
    background-color: #232323;

    > .nav-bar {
      margin-bottom: -1px;
    }
  }

  .header-inner-top > .nav-bar {
    min-height: auto;
  }
}

.header-transparent .header-inner {
  background-color: transparent;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-bottom,
.ie-browser .wrapper.inactive .header-bottom {
  position: absolute;
}

.header-bottom {
  bottom: 0;
  top: auto;
  z-index: 100;
}

.header .header-inner {
  opacity: 1;
  position: relative;
  z-index: 100;
  -webkit-transform: translateZ(0);
}

.header-fixed-width .header-inner,
body.boxed .header .header-inner {
  max-width: 114rem;
  margin-left: auto;
  margin-right: auto;
}

.header-sticky .header-inner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
}

body.boxed .header .header-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header {
  .header-inner {
    .dropdown > .nav-icon {
      height: 8rem;
      line-height: 8rem;
    }

    .navigation > ul > li {
      height: 8rem;
      line-height: 8rem;

      > a:not(.button) {
        height: 8rem;
        line-height: 8rem;
      }
    }
  }

  .logo {
    height: 8rem;
    line-height: 8rem;
  }
}

.header-compact {
  .header-inner {
    .dropdown > .nav-icon {
      height: 6rem;
      line-height: 6rem;
    }

    .navigation > ul > li {
      height: 6rem;
      line-height: 6rem;

      > a:not(.button) {
        height: 6rem;
        line-height: 6rem;
      }
    }
  }

  .logo {
    height: 6rem;
    line-height: 6rem;
  }
}

.header {
  .header-inner .navigation > ul > li,
  .logo {
    display: table;
  }

  .logo-inner,
  .v-align-middle {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    position: relative;
  }

  .logo {
    width: 13rem;

    span {
      font-size: 2rem;
      line-height: 2;
    }
  }
}

.header-compact .logo {
  width: 10rem;

  span {
    font-size: 1.5rem;
    line-height: 2;
  }
}

.header .logo {
  a {
    font-weight: 700;
    color: #fff;
    display: block;
    -webkit-transition-property:
      opacity,
      background,
      color,
      visibility,
      -webkit-transform;
    transition-property: opacity, background, color, visibility, transform;

    &:hover {
      opacity: 0.6 !important;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  a {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: table-cell;
    }
  }
}

.header-background .logo a:first-child,
.mobile .header .logo a:first-child {
  display: table-cell;
}

.header-background .logo a + a {
  display: none !important;
}

.header {
  .navigation {
    float: right;
  }

  .secondary-navigation {
    &.nav-left:not(.with-division) > ul {
      margin-left: -1.5rem;
    }

    &.nav-right:not(.with-division) > ul {
      margin-right: -1.5rem;
    }
  }

  .navigation {
    .nav-icon,
    .v-align-middle,
    &.nav-left :first-child > .nav-icon,
    &.nav-right :last-child > .nav-icon {
      padding: 0 1.5rem;
    }
  }

  .with-division {
    .nav-icon,
    .v-align-middle,
    > ul > li:last-child > .nav-icon {
      padding: 0 2rem !important;
    }
  }

  .navigation {
    &.nav-left li + li {
      .nav-icon,
      .v-align-middle {
        padding-left: 0;
      }
    }

    &.nav-right li + li {
      .nav-icon,
      .v-align-middle {
        padding-left: 0;
      }
    }

    .nav-icon {
      margin: 0;
      line-height: inherit;
      float: none;

      [class*="icon-"] {
        margin: 0;
        line-height: inherit;
        float: none;

        &:before {
          margin: 0;
          line-height: inherit;
          float: none;
        }
      }
    }
  }

  .social-list [class*="icon-"] {
    margin: 0;
    line-height: inherit;
    float: none;
  }

  .navigation .nav-icon {
    [class*="icon-"] {
      display: initial;
    }

    font-size: 1.4rem;
    background: 0 0;
    border: none;
  }

  .with-division {
    ul {
      float: left;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }

    li {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  .button,
  .dropdown,
  .navigation-show {
    margin: 0;
    line-height: 1.2;
  }

  .dropdown > .nav-icon {
    min-width: initial;
  }

  .navigation-show {
    &.nav-icon {
      width: auto;
      height: auto;
    }

    display: block;

    * {
      padding: 0;
      margin: 0;
    }
  }

  .nav-left .dropdown-list {
    left: 0;
  }

  .nav-right .dropdown-list {
    right: 0;
  }

  .sub-menu.custom-content .thumbnail {
    line-height: 0;
  }

  .cart-indication {
    position: relative;

    .badge {
      background: #232323;
      top: -0.8rem;
      right: -0.8rem;
    }
  }

  .cart + {
    .dropdown-list,
    .sub-menu {
      width: 30rem;
      padding: 2rem;
      margin-top: 0;
      right: 0;
    }
  }

  .search + {
    .dropdown-list,
    .sub-menu {
      padding: 2rem;
      margin-top: 0;
      width: 25rem;
      right: 0;
    }
  }

  .social-list {
    &.pull-left li:last-child span {
      margin-left: 0;
    }

    &.center li:last-child span,
    &.pull-right li:last-child span {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 960px) {
  .header-transparent .with-division {
    ul {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    li {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}

.header-background .header-inner {
  background: #232323;
}

.mobile {
  .header {
    position: static !important;
  }

  .header-fixed-on-mobile .header-inner {
    width: 100%;
    position: fixed !important;
    left: 0;
    top: 0;
  }

  .header-sticky .header-inner {
    position: relative;
  }

  .header {
    height: 8rem !important;
    padding-top: 0;
    background: #232323;

    .header-inner {
      height: 8rem !important;
      padding-top: 0;
      background: #232323;
      border-top: none !important;
    }

    .nav-bar {
      border-top: none !important;
    }
  }
}

.header .header-inner-top :last-child {
  margin-bottom: 0;
}

.mobile .header {
  .header-inner-top {
    display: none;
  }

  .nav-bar,
  .nav-bar-inner {
    height: 100%;
  }

  .logo {
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    display: table;
    float: left;
  }

  .logo-inner {
    display: table-cell;
    vertical-align: middle;

    a {
      display: block;
      position: relative;
    }
  }

  .logo a {
    &:first-child {
      display: table-cell;
      vertical-align: middle;
    }

    + a {
      display: none !important;
    }
  }

  .navigation-show {
    display: block !important;
    visibility: visible !important;
  }

  .header-inner {
    transition: none;
  }

  .logo {
    transition: none;

    span {
      transition: none;
    }
  }

  .navigation-show {
    transition: none;

    a span {
      transition: none;
    }
  }
}

.header.header-animated {
  -webkit-transition-property:
    height,
    -webkit-transform,
    visibility;
  transition-property: height, transform, visibility;
}

.header-animated {
  .header-inner {
    -webkit-transition-property: height, background, border, padding, margin, font-size, line-height, color, opacity;
    transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity;
  }

  .dropdown > .nav-icon {
    -webkit-transition-property:
      height,
      background,
      border,
      padding,
      margin,
      font-size,
      line-height,
      color,
      opacity,
      -webkit-transform;
    transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity, transform;
  }

  .header-inner .navigation {
    -webkit-transition-property:
      height,
      background,
      border,
      padding,
      margin,
      font-size,
      line-height,
      color,
      opacity,
      -webkit-transform;
    transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity, transform;

    > ul > li {
      -webkit-transition-property:
        height,
        background,
        border,
        padding,
        margin,
        font-size,
        line-height,
        color,
        opacity,
        -webkit-transform;
      transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity, transform;

      > a {
        -webkit-transition-property:
          height,
          background,
          border,
          padding,
          margin,
          font-size,
          line-height,
          color,
          opacity,
          -webkit-transform;
        transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity, transform;
      }
    }
  }

  .navigation-show a {
    -webkit-transition-property:
      height,
      background,
      border,
      padding,
      margin,
      font-size,
      line-height,
      color,
      opacity,
      -webkit-transform;
    transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity, transform;
  }

  .logo {
    -webkit-transition-property: width, height, background, border, padding, margin, font-size, line-height, opacity;
    transition-property: width, height, background, border, padding, margin, font-size, line-height, opacity;

    span {
      -webkit-transition-property: width, height, background, border, padding, margin, font-size, line-height, opacity;
      transition-property: width, height, background, border, padding, margin, font-size, line-height, opacity;
    }
  }

  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;

  .dropdown > .nav-icon {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  .header-inner {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;

    .navigation {
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;

      > ul > li {
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;

        > a {
          -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
        }
      }
    }
  }

  .logo {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;

    a,
    span {
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
    }
  }

  .navigation-show a {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
}

.header-hide .header-inner {
  opacity: 0;
}

.header-positioned .header-inner {
  -webkit-transform: translate3d(0, -10rem, 0);
  transform: translate3d(0, -10rem, 0);
}

.header-in .header-inner,
.header-out .header-inner {
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.header-in .header-inner {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.header-out .header-inner {
  -webkit-transform: translate3d(0, -10rem, 0);
  transform: translate3d(0, -10rem, 0);
}

.header-in {
  .header-inner {
    .logo,
    .navigation {
      transition: none !important;
    }
  }

  .navigation-show {
    transition: none !important;
  }
}

@media only screen and (max-width: 1140px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 96rem;
  }
}

@media only screen and (max-width: 960px) {
  .header {
    background: 0 0;
    position: static !important;
  }

  .header-fixed-on-mobile .header-inner {
    width: 100%;
    position: fixed !important;
    left: 0;
    top: 0;
  }

  body.boxed .header-fixed-on-mobile {
    width: 100%;
    position: fixed !important;
    left: 0;
    top: 0;

    .header-inner {
      max-width: 76rem;
      position: static !important;
    }
  }

  .header-sticky .header-inner {
    position: relative;
  }

  .header {
    .header-inner {
      background: #232323;
      border-top: none !important;
    }

    .nav-bar {
      border-top: none !important;
    }

    height: 8rem !important;
    padding-top: 0;
    border: none;

    .header-inner {
      height: 8rem !important;
      padding-top: 0;
      border: none;

      .dropdown > .nav-icon {
        height: 8rem !important;
        line-height: 8rem !important;
      }

      .navigation > ul > li {
        height: 8rem !important;
        line-height: 8rem !important;

        > a:not(.button) {
          height: 8rem !important;
          line-height: 8rem !important;
        }
      }
    }

    .logo {
      height: 8rem !important;
      line-height: 8rem !important;
    }

    .header-inner-top {
      display: none;
    }

    .nav-bar,
    .nav-bar-inner {
      height: 100%;
    }

    .logo-inner a {
      display: block;
      position: relative;
    }

    .logo a + a {
      display: none !important;
    }

    .header-inner {
      transition: none;
    }

    .logo {
      transition: none;

      span {
        transition: none;
      }
    }

    .navigation-show {
      transition: none;

      a span {
        transition: none;
      }

      display: block !important;
      visibility: visible !important;
    }
  }
}

.hero-2,
.hero-3,
.intro-title-2 {
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 60rem;
  }
}

@media only screen and (max-width: 600px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 48rem;
  }

  .header .v-align-middle {
    position: static;
  }

  .navigation {
    .dropdown,
    > ul > li {
      position: static;
    }

    .dropdown-list {
      width: auto !important;
      left: 1.5rem !important;
      right: 1.5rem !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 35rem;
  }
}

@media only screen and (max-width: 350px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 100%;
  }
}

.side-navigation-header {
  min-height: 8rem;
  line-height: 8rem;

  .logo {
    min-height: 8rem;
    line-height: 8rem;
  }
}

.side-navigation-footer {
  font-size: 1.4rem;
  line-height: 1.8;
}

.side-navigation-header {
  font-size: 1.4rem;
  line-height: 1.8;
  padding: 0 4rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #232323;

  .logo {
    width: 100%;

    a {
      font-size: 1.5rem;
      font-weight: 700;
      text-decoration: none;
      color: #666;
    }
  }
}

.hero-1 {
  color: #fff;

  h2,
  h6 {
    color: #fff;
  }
}

.hero-2 {
  color: #fff;

  h2,
  h6 {
    color: #fff;
  }
}

.intro-title-2 {
  .subtitle,
  h1 {
    color: #fff;
  }
}

.overlay-navigation-footer .social-list a:hover,
.side-navigation-footer .social-list a:hover {
  color: #fff;
}

.side-navigation-wrapper .navigation-hide {
  position: absolute;
  right: 0;
  top: 0;
}

.side-navigation {
  margin-bottom: 3rem;
}

.side-navigation-inner > :nth-last-child(2) {
  padding-bottom: 8rem;
  margin-bottom: 0;
}

.side-navigation {
  .cart-indication {
    margin-left: 0.5rem;
    position: relative;

    .badge {
      top: 0.3rem;
      left: 0.3rem;
    }
  }

  .cart-overview li {
    &:first-child {
      padding-top: 2rem;
    }

    &:last-child {
      padding-bottom: 2rem;
    }
  }
}

.side-navigation-wrapper .custom-content {
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 0;
  right: 0;
}

.side-navigation-footer {
  width: 100%;
  padding: 1rem 4rem;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;

  .social-list {
    margin-bottom: 1rem;
  }
}

.side-navigation-wrapper.center {
  .logo,
  .side-navigation,
  .side-navigation-footer {
    text-align: center;
  }

  .side-navigation .sub-menu a {
    padding-left: 4rem;
  }
}

.overlay-navigation-header {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 3rem;
  position: absolute;
  top: 2rem;

  .logo {
    width: 13rem;
    margin: 0 auto;
  }
}

.hero-content-inner > :first-child,
.title-container-inner > :first-child {
  margin-top: 0;
}

.overlay-navigation-wrapper .navigation-hide {
  min-height: 4rem;
  line-height: 4rem;
  position: absolute;
  right: 3rem;
  top: 0;
}

.overlay-navigation {
  &:first-child {
    padding-top: 5rem;
  }

  padding-bottom: 8rem;
  margin-bottom: 0;

  .cart-indication {
    margin-left: 0.5rem;
    position: relative;

    .badge {
      top: 0.3rem;
      left: 0.3rem;
    }
  }

  .cart + {
    .dropdown-list,
    .sub-menu {
      width: 100%;
      padding: 0;
      margin-top: 0;
      right: 0;
    }
  }

  .cart-overview li {
    &:first-child {
      padding-top: 2rem;
    }

    &:last-child {
      padding-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .overlay-navigation:not(:last-child) {
    padding-bottom: 0;
  }
}

.overlay-navigation-footer {
  padding: 1rem 4rem;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
}

.hero-3 {
  &.center:not(.middle),
  &.top {
    padding-top: 0;
  }
}

.intro-title-2,
[class*="hero-"] {
  position: relative;
}

.overlay-navigation-footer {
  .copyright {
    font-size: 1.1rem;
  }

  .social-list {
    margin-bottom: 1rem;
  }
}

.hero-content-inner > :last-child,
.title-container-inner > :last-child {
  margin-bottom: 0;
}

.title-container {
  &[class^="title-"] {
    margin-bottom: 0;
  }

  height: 100%;
}

[class*="intro-title-"] {
  .column,
  .row {
    height: 100%;
  }
}

.title-container {
  width: 100%;
  display: table;
}

.title-container-inner {
  display: table-cell;
  vertical-align: middle;
}

[class*="intro-title-"] .subtitle {
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 300;
  font-style: italic;
}

.call-to-action-2 p,
.call-to-action-3 p {
  font-size: 2.5rem;
}

.testimonial-1 cite,
.testimonial-2 cite,
.testimonial-5 cite {
  font-style: normal;
  letter-spacing: 0.1rem;
}

.intro-title-1 {
  height: 40rem;
}

.intro-title-2 {
  height: 40rem;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

[class*="hero-"] > .row:not(.flex) {
  height: 100%;

  > .column {
    height: 100%;
  }
}

.hero-content {
  &:not([class*="width-"]) {
    width: 100%;
  }

  height: 100%;
  display: table !important;
  table-layout: fixed;
}

.hero-slider {
  .tms-caption.no-transition {
    height: 100%;
    display: table !important;
    table-layout: fixed;
  }

  .hero-content-inner {
    display: table-cell;
    vertical-align: middle;
  }
}

.section-block .hero-content-inner {
  display: table-cell;
  vertical-align: middle;

  .column,
  .row {
    height: auto;
  }
}

.hero-1,
.hero-2,
.hero-3 {
  height: 50rem;
  text-align: left;
}

.hero-1 {
  background-color: #1ecd6d;
}

.hero-3 {
  display: table;

  > div {
    display: table-cell;
    vertical-align: middle;
    float: none;

    > .row {
      height: auto;

      > .column {
        height: auto;
      }
    }
  }

  &.right > div > .row > .column {
    float: right;
  }

  &.top {
    > div {
      vertical-align: top;
    }

    .hero-content {
      border-bottom: 8px solid #333;
    }
  }

  &.bottom {
    padding-bottom: 0;

    > div {
      vertical-align: bottom;
    }

    .hero-content {
      border-top: 8px solid #333;
    }
  }

  &.center-left > div > .row > .column {
    text-align: left;
  }

  &.center > div > .row > .column {
    margin: auto;
    float: none;
  }
}

.hero-slider {
  margin-bottom: 0;

  .hero-content :last-child {
    margin-bottom: 0;
  }
}

.hero-3 {
  &.center-right > div > .row > .column {
    text-align: right;
    float: right;
  }

  > div > .row {
    width: 100%;
    bottom: 0;
  }

  .hero-content {
    background: #fff;
    position: relative;
    bottom: 0;
  }

  .hero-content-inner {
    padding: 3rem;
  }

  h2,
  h6 {
    color: #666;
  }
}

.hero-4 {
  height: 50rem;
  text-align: left;
  color: #fff;
  background-repeat: no-repeat;

  > .row:before {
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }

  &.right > .row:before {
    top: 0;
    right: 0;
    left: auto;
  }

  .column {
    position: relative;
  }

  h2,
  h6 {
    color: #fff;
  }
}

.hero-5 {
  > .row > .column {
    position: relative;
    z-index: 1;
  }

  .media-column {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
    top: 0;
  }
}

.hero-slider .tms-slide > .media-column {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
  top: 0;
}

.hero-5 {
  .media-column {
    height: 100%;
    position: absolute;
    left: 0;

    .map-container,
    iframe,
    video {
      width: 100%;
      height: 100%;
    }

    .hero-content-inner {
      padding: 8.5rem;
    }

    + .media-column {
      left: auto;
      right: 0;
    }
  }

  &.right > .media-column {
    left: auto;
    right: 0;
  }

  .media-column .content-slider {
    height: 100% !important;
  }
}

.hero-slider {
  width: 100% !important;
  height: auto;
  min-height: 50rem;

  &.window-height {
    min-height: 100vh;
  }

  .hero-content,
  .tms-slide {
    height: 100%;
  }

  .tms-slides {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.hero-5 .tms-slide,
.hero-slider .tms-slide {
  padding: 0 !important;
  display: inherit;
  position: absolute;
}

.call-to-action-3,
.feature-2,
.feature-3,
.hero-5 .testimonial-slider .tms-slide {
  position: relative;
}

.hero-slider {
  .tms-slide {
    > .media-column {
      height: 100%;
      position: absolute;
      left: 0;
    }

    &.right > .media-column {
      left: auto;
      right: 0;
    }
  }

  .tms-pagination {
    width: 50%;
    text-align: right;
    padding-right: 5rem;
  }

  .hero-content {
    &:not([class*="width-"]) {
      width: 50%;
    }

    min-height: 50rem;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
  }

  .media-column + .row .hero-content {
    background: 0 0;
  }

  .tms-content-scalable {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .tms-slide[class*="bkg-"] .hero-content {
    background-color: inherit;
  }

  .hero-content.right {
    text-align: left;
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 1040px) {
  .hero-5 .media-column .hero-content-inner {
    padding: 2.5rem;
  }
}

@media only screen and (min-width: 960px) {
  .tml-modal-mode .hero-5 {
    padding-right: 4%;
  }
}

@media only screen and (max-width: 960px) {
  .tml-modal-mode {
    .hero-5 .media-column {
      width: 100%;
      margin-left: 0;
    }

    [class*="hero-"] > .row:not(.flex) {
      width: 100%;
      margin-left: 0;

      > .column {
        width: 100%;
        margin-left: 0;
      }
    }

    .hero-5 {
      height: auto !important;

      .media-column {
        display: none;
        visibility: hidden;
      }

      &.show-media-column-on-mobile {
        padding-top: 0;

        .media-column {
          display: block;
          visibility: visible;
          width: 100%;
          position: relative;
          height: 25rem;

          iframe,
          video {
            height: 25rem;
          }
        }
      }

      .media-column {
        .split-hero-content {
          max-width: 60rem;
          margin: 0 auto;
        }

        .hero-content-inner {
          padding: 1.5rem;
        }
      }

      .column {
        &:first-child:not(.media-column) .split-hero-content {
          margin-top: 4rem;
        }

        &:not(.media-column) .split-hero-content {
          margin-top: 5rem;
          margin-bottom: 0;
        }
      }

      .row + .media-column {
        margin-top: 8rem;
        margin-bottom: -11rem;
      }

      .tms-pagination {
        text-align: center !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .hero-1,
  .hero-2,
  .hero-3,
  .hero-4,
  .hero-5 {
    height: auto !important;
  }

  [class*="hero-"] .column {
    &:first-child .hero-content {
      margin-bottom: 5rem;
    }

    &:last-child .hero-content {
      margin-bottom: 0;
    }
  }

  .hero-content {
    text-align: center;
  }

  .hero-3[class*="center"] {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .hero-4 > .row {
    &:after,
    &:before {
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .hero-5 {
    .media-column {
      display: none;
      visibility: hidden;
    }

    &.show-media-column-on-mobile {
      padding-top: 0;

      .media-column {
        display: block;
        visibility: visible;
        width: 100%;
        position: relative;
        height: 40rem;

        iframe,
        video {
          height: 40rem;
        }
      }
    }

    .media-column {
      .split-hero-content {
        max-width: 60rem;
        margin: 0 auto;
      }

      .hero-content-inner {
        padding: 1.5rem;
      }
    }

    .column {
      &:first-child:not(.media-column) .split-hero-content {
        margin-top: 11rem;
      }

      &:not(.media-column) .split-hero-content {
        margin-top: 5rem;
        margin-bottom: 0;
      }
    }

    .row + .media-column {
      margin-top: 8rem;
      margin-bottom: -11rem;
    }

    .tms-pagination {
      text-align: center !important;
    }
  }

  .hero-slider {
    .tms-pagination {
      width: 100%;
      padding: 0;
      text-align: center;
    }

    .hero-content-inner {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .hero-content {
      width: 100%;
      height: inherit;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.6);
    }

    .media-column + .row {
      max-width: 100% !important;
    }

    .tms-slide > .media-column {
      width: 100%;
      height: 40rem;
    }

    .media-column + .row .tms-content-scalable {
      padding-top: 50rem;
    }
  }
}

.call-to-action-1,
.call-to-action-2 {
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .hero-5 .media-column .split-hero-content,
  [class*="hero-"] > .row > .column {
    max-width: 48rem;
  }
}

@media only screen and (max-width: 350px) {
  .hero-5 .media-column .split-hero-content,
  [class*="hero-"] > .row > .column {
    width: 100%;
  }
}

.feature-content,
.feature-image,
.feature-slider {
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
}

.feature-content-inner,
.feature-image-inner,
.feature-slider-inner {
  display: table-cell;
  vertical-align: middle;
}

.feature-slider {
  .tm-slider-container {
    width: 100% !important;
  }

  .tms-pagination {
    bottom: 0;
  }
}

.feature-content-inner :first-child {
  margin-top: 0;
}

.feature-1 {
  padding-bottom: 0;
  text-align: center;

  &.overlap-bottom {
    overflow: visible;
    z-index: 3;

    .feature-image {
      margin-bottom: -20rem;
    }

    + .section-block:before {
      width: 100%;
      content: close-quote;
      padding-bottom: 20rem;
      z-index: 0;
      position: relative;
      background: 0 0;
      display: block;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 768px) {
  .feature-1.overlap-bottom {
    .feature-image {
      margin-bottom: -15rem;
    }

    + .section-block:before {
      padding-bottom: 15rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  .hero-5 .media-column .split-hero-content,
  [class*="hero-"] > .row > .column {
    max-width: 35rem;
  }

  .feature-1.overlap-bottom {
    .feature-image {
      margin-bottom: -10rem;
    }

    + .section-block:before {
      padding-bottom: 10rem;
    }
  }

  .feature-3 {
    .feature-image img,
    .feature-slider {
      max-width: 100%;
    }
  }
}

.feature-2 {
  padding-bottom: 0;

  .row {
    div.feature-image,
    &.flex div.feature-image {
      width: 170%;
      margin-right: -70%;
    }
  }

  &.right .row .feature-image {
    margin-left: -70%;
  }
}

.feature-3 {
  .feature-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .feature-image-inner {
    max-height: 53rem;
  }
}

@media only screen and (max-width: 960px) {
  .feature-2 {
    padding-bottom: 7rem;

    .row .feature-image {
      height: 100%;
    }
  }

  .header + .content > [class*="feature-"]:first-child {
    padding-top: 5rem;
  }
}

@media only screen and (min-width: 768px) {
  .feature-2 > .row > .column,
  .feature-3 > .row > .column {
    height: auto;
    display: inherit;

    > .box {
      height: auto;
      display: inherit;
    }
  }

  .feature-2 > .row > .column > {
    :not(.box),
    div > :not(.box) {
      height: auto;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .feature-3 > .row > .column > {
    :not(.box),
    div > :not(.box) {
      height: auto;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .feature-2 .feature-content-inner :last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .feature-2.left,
  .feature-3 .column:last-child .feature-content {
    padding-bottom: 0;
  }

  .feature-2 .column,
  .feature-3 .column {
    height: auto !important;
  }

  .feature-2 {
    .row .feature-image {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      float: none;
    }

    &.right .column + .column .feature-content {
      margin-bottom: 0;
    }
  }

  .feature-3 {
    .feature-image {
      text-align: center;

      img {
        max-width: 100%;
        margin: 0 auto;
      }
    }

    .feature-slider {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .feature-2.right .feature-image {
    margin-bottom: 5rem;
  }

  .feature-3 {
    .feature-image,
    .feature-slider {
      margin-bottom: 5rem;
    }

    .column:last-child {
      .feature-column:last-child,
      .feature-image {
        margin-bottom: 0;
      }
    }
  }
}

[class*="call-to-action-"] {
  .column,
  .row {
    height: 100%;
  }
}

.call-to-action-content {
  width: 100%;
  height: 100%;
  display: table !important;
}

.call-to-action-content-inner {
  display: table-cell;
  vertical-align: middle;
}

[class*="call-to-action-"] .column:last-child .call-to-action-content-inner {
  padding-bottom: 0;
}

.call-to-action-content-inner {
  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}

[class*="call-to-action-"] {
  background-color: inherit;
}

.call-to-action-1 {
  width: 100%;
  display: block;

  &:hover {
    background-color: #1ecd6d;
  }

  span {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .call-to-action-2 {
    .inline {
      display: block;
    }

    p + .button {
      margin-left: 2rem;
    }
  }
}

.contact-form-container .row,
.signup-form-container .row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.call-to-action-3 {
  background-color: #ffbe12;
  color: #fff;

  .call-to-action-button {
    padding-right: 0;
    position: absolute;
    top: 0;
    right: 0;

    .button {
      padding: 2rem 0;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      font-size: 1.5rem;
      font-weight: 300;
      text-align: center;
      text-transform: uppercase;
      border-radius: 0;
      display: table;
    }

    > .button > span {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

@media only screen and (max-width: 960px) {
  .call-to-action-1,
  .call-to-action-2 p,
  .call-to-action-3 p {
    font-size: 1.9rem;
    line-height: 2.9rem;
  }
}

@media only screen and (max-width: 768px) {
  [class*="call-to-action"] {
    height: auto;
  }

  .call-to-action-2 .call-to-action-content-inner {
    padding-bottom: 2.5rem;
    text-align: center;
  }

  .call-to-action-3 {
    .call-to-action-content-inner {
      padding-bottom: 2.5rem;
      text-align: center;
    }

    .row {
      max-width: 100%;
    }

    .call-to-action-button {
      position: static;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.contact-form-container [class*="width-"],
.signup-form-container [class*="width-"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-element[class*="border-"] {
  background: #fff;
}

.form-honeypot {
  display: none;
}

.contact-form-container .form-response,
.register-form-container .form-response,
.signup-form-container .form-response {
  width: 100%;
  font-size: 1.2rem;
  -ms-word-break: break-all;
  word-break: break-word;
  position: absolute;
  bottom: 0;
}

.contact-form-container .form-element,
.login-form-container .form-element,
.register-form-container .form-element,
.search-form-container .form-element,
.signup-form-container .form-element {
  margin-bottom: 2rem;
}

.contact-1 .contact-form-container,
.search-form-container input[type="submit"],
.signup-1 .signup-form-container,
.signup-3 .signup-form-container,
.signup-form-container input[type="submit"] {
  margin-bottom: 0;
}

.contact-form-container {
  position: relative;

  .contact-form {
    position: relative;
  }
}

.signup-form-container {
  position: relative;

  .signup-form {
    padding-bottom: 3rem;
    position: relative;
  }
}

.team-3 .social-list a:hover,
.team-4 .thumbnail .social-list a:hover {
  opacity: 0.5;
}

.signup-form-container.boxed {
  padding: 4rem;
}

.signup-2 {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;

  .signup-form-container {
    padding: 4rem;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

.login-form-container [class*="width-"],
.search-form-container [class*="width-"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.signup-2 .signup-form-container .form-response {
  bottom: 4rem;
}

.signup-3 {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;

  .signup-inner {
    background: rgba(0, 0, 0, 0);
  }

  .row {
    text-align: center;
  }

  .signup-form-container {
    width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.login-form-container .row,
.search-form-container .row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.signup-3 .form-element {
  width: 100%;
}

.login-form-container .recuperate-password {
  font-size: 1rem;
  display: block;
}

.testimonial-1 h6,
.testimonial-2 h6,
.testimonial-3 h6,
.testimonial-4 h6 {
  font-size: 1.3rem;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) {
  .signup-form-container .merged-form-elements {
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .signup-form-container input[type="submit"] {
    width: auto;
  }
}

@media only screen and (max-width: 480px) {
  .signup-3 .signup-form-container {
    width: 100%;
  }
}

.testimonial-1 {
  h6 {
    margin-bottom: 4rem;
  }

  blockquote {
    width: 70%;
    padding: 0;
    margin-bottom: 0;

    &.avatar span {
      width: 9rem;
      height: 9rem;

      img {
        width: 9rem;
        height: 9rem;
      }
    }
  }

  p {
    margin-top: 3rem;
  }

  cite {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
  }
}

.testimonial-2 {
  h6 {
    margin-bottom: 1rem;
  }

  .brief {
    height: 100%;
    border-right: 1px solid #ddd;
    display: table;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .brief-inner {
    display: table-cell;
    vertical-align: middle;
  }

  blockquote {
    width: 100%;
    padding: 0;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  cite {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.testimonial-3 {
  [class*="content-grid"] {
    margin-bottom: -3rem;
  }

  h6 {
    margin-bottom: 4rem;
  }

  blockquote {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  cite {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
}

.testimonial-4 {
  [class*="content-grid"] {
    margin-bottom: -3rem;
  }

  h6 {
    margin-bottom: 4rem;
  }

  blockquote {
    height: 100%;
    padding: 2.5rem;
    margin: 0;
    border-radius: 0.3rem;
    border: 1px solid #ddd;
  }

  cite {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
}

.testimonial-5 {
  color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  blockquote {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  cite {
    text-transform: uppercase;
    font-weight: 700;
  }
}

.testimonial-slider {
  width: 100% !important;
  min-height: 10rem;
  margin: 0 auto;
  background: 0 0;

  .tms-pagination {
    bottom: 0;
  }

  &.left,
  &.right {
    margin: 0;
  }
}

.center .testimonial-slider blockquote {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.testimonial-slider {
  blockquote {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.left {
    .tms-pagination {
      text-align: left;
    }

    blockquote {
      text-align: left;
      padding-left: 0;
      padding-right: 1rem;
    }
  }

  &.right {
    .tms-pagination {
      text-align: right;
    }

    blockquote {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
  }

  blockquote span {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 960px) {
  .testimonial-1 p,
  .testimonial-5 .testimonial-slider p {
    font-size: 1.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .testimonial-2 .brief {
    border-right: none;

    h6:last-child,
    p:last-child {
      margin-bottom: 3rem;
    }
  }

  .testimonial-3 {
    blockquote {
      height: 100%;
      padding-bottom: 3rem;
      border-bottom: 1px solid #ddd;
    }

    .grid-item:last-child blockquote {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .testimonial-slider.center-on-mobile {
    margin: 0 auto;

    .tms-pagination {
      text-align: center;
    }

    blockquote {
      text-align: center;
      padding: 0 !important;
    }
  }
}

.team-1 .occupation,
.team-2 .occupation {
  margin-top: 0;
  font-size: 1.2rem;
  color: #999;
  letter-spacing: 0.1rem;
}

[class*="team-"] {
  .signature {
    width: 100%;
  }

  .thumbnail {
    float: none;
  }

  .social-list {
    margin-bottom: 0;
  }
}

.social-list {
  margin-left: 0;
  list-style: none;

  a {
    margin-right: 1rem;
  }

  &.right li:last-child a,
  &:not(.center) li:last-child a {
    margin-right: 0;
  }
}

.right .social-list a,
.social-list.right a {
  margin-left: 1rem;
  margin-right: 0;
}

.center .social-list {
  a,
  li:last-child a {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

.social-list {
  &.center a {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  [class*="icon-"] {
    margin: 0;
  }
}

.team-slider .social-list {
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
  }
}

.team-1 {
  [class*="content-grid"] {
    margin-bottom: -3rem;
  }

  .team-content-info {
    .name,
    h4 {
      margin-bottom: 1rem;
    }
  }

  .occupation {
    margin-bottom: 2rem;
  }
}

.team-2 {
  [class*="content-grid"] {
    margin-bottom: -3rem;
  }

  .thumbnail {
    margin-bottom: 0;
  }

  .team-content {
    background: #fff;
  }

  .team-content-info {
    padding: 2rem;

    .name,
    h4 {
      margin-bottom: 1rem;
    }
  }

  .occupation {
    margin-bottom: 2rem;
  }

  .social-list .list-label {
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 700;
    color: #999;
    display: inline-block;
  }
}

.team-3 {
  color: #fff;

  .team-content-info {
    color: #fff;
  }
}

.team-2 .social-list.boxed {
  margin: 0 -2rem -2rem;
  padding: 2rem;
  background: #e7e7e7;
}

.team-3 {
  text-align: left;

  .slider-column {
    padding-left: 0;
    padding-right: 0;
  }
}

body.boxed .team-3 {
  padding-left: 0;
  padding-right: 0;
}

.team-3 {
  .team-slider {
    height: 45rem;
    margin-bottom: 0;
    background: 0 0;

    .tms-slides {
      margin-bottom: 2rem;
    }
  }

  .team-content {
    margin-bottom: 4rem;
  }

  .team-content-info {
    .name,
    h4 {
      margin-bottom: 1rem;
    }
  }

  .occupation {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: #999;
    letter-spacing: 0.1rem;
  }

  .social-list a {
    color: #fff;
  }
}

.section-block.team-4 {
  padding-bottom: 0;
}

.team-4 {
  text-align: center;
  overflow: hidden;

  .grid-item {
    margin-bottom: 0;
  }

  .overlay-info > span {
    padding: 3rem;
    font-size: 1.4rem;
    line-height: 1.8;
    font-weight: 400;
  }

  .team-content-info {
    .name,
    h4 {
      margin-bottom: 1rem;
    }
  }

  .occupation {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: #999;
    letter-spacing: 0.1rem;
  }

  .social-list {
    display: block;
    margin-top: 2rem;
  }

  .thumbnail .social-list a {
    margin: 0 0.5rem;
    color: #fff;
  }
}

.mobile .team-4 {
  .overlay-info {
    height: auto;
    background: 0 0;
    position: relative;
    color: #999;
    transition: none;
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    > span {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .social-list a {
    color: #999;
  }
}

@media only screen and (min-width: 768px) {
  .team-4 .thumbnail {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 479px) and (max-width: 960px) {
  .team-2 .content-grid-4 .grid-item,
  .team-4 .content-grid-4 .grid-item {
    width: 50%;
  }
}

.masonry-set-dimensions-2 {
  .thumbnail {
    float: left;
  }

  .description {
    padding: 2rem;
    text-align: center;
    background: #fff;
  }

  h5:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  .half {
    &.image-left .thumbnail,
    &.image-right .thumbnail {
      width: 50%;
    }
  }

  .two-third {
    &.image-left .thumbnail,
    &.image-right .thumbnail {
      width: 66.66667%;
    }
  }

  .half {
    &.image-bottom .thumbnail,
    &.image-top .thumbnail {
      width: 100%;
    }
  }

  .image-left.no-description .thumbnail,
  .image-right.no-description .thumbnail {
    width: 100%;
  }

  .two-third {
    &.image-bottom .thumbnail,
    &.image-top .thumbnail {
      width: 100%;
    }
  }

  .half.image-right .thumbnail,
  .two-third.image-right .thumbnail {
    float: right;
  }

  .item-description {
    margin-top: 0;
    height: 100%;
    background: #f9f9f9;
    text-align: center;
    display: table;
    position: relative;
  }

  .item-description-inner {
    height: 100%;
    padding: 1rem 3rem 3rem;
    display: table-cell;
    vertical-align: middle;
  }

  .half {
    &.image-left .item-description {
      width: 50%;
      text-align: left;
      float: right;
    }

    &.image-right .item-description {
      width: 50%;
      text-align: right;
      float: left;
    }
  }

  .two-third {
    &.image-left .item-description {
      width: 33.33333%;
      text-align: left;
      float: right;
    }

    &.image-right .item-description {
      width: 33.33333%;
      text-align: right;
      float: left;
    }
  }

  .half {
    &.image-bottom .item-description,
    &.image-top .item-description {
      width: 100%;
      height: 50%;
      text-align: center;
    }
  }

  .two-third.image-top .item-description {
    width: 100%;
    height: 33.33333%;
    text-align: center;
  }

  .item-description:after {
    width: 0;
    height: 0;
    margin-top: -10px;
    content: " ";
    position: absolute;
    pointer-events: none;
    z-index: 10;
    margin-left: -10px;
    border: solid transparent;
    border-bottom-color: #f9f9f9;
    border-width: 10px;
    top: auto;
    bottom: 100%;
    left: 50%;
  }

  .image-left .item-description:after {
    margin-top: -10px;
    border: solid transparent;
    border-right-color: #f9f9f9;
    border-width: 10px;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
  }

  .image-right .item-description:after {
    margin-top: -10px;
    border: solid transparent;
    border-left-color: #f9f9f9;
    border-width: 10px;
    top: 50%;
    bottom: auto;
    right: -20px;
    left: auto;
  }

  .image-bottom .item-description:after {
    margin-left: -10px;
    border: solid transparent;
    border-top-color: #f9f9f9;
    border-width: 10px;
    top: auto;
    bottom: -2rem;
    left: 50%;
  }
}

.recent-slider {
  .column.slider-column {
    padding-left: 0;
    padding-right: 0;
  }

  .recent-slider {
    padding: 0;
    margin-bottom: 0;
    background: 0 0;
  }

  .next-recent-slider,
  .previous-recent-slider {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    line-height: 3rem;
    text-align: center;
    display: inline-block;
    border: 1px solid #ddd;
  }
}

.logos-2 [class*="content-grid"],
.logos-3 [class*="content-grid"] {
  margin-right: 0;
  margin-left: 0;
}

.recent-slider {
  [class*="icon-"] {
    margin: 0;
  }

  .grid-description {
    width: 100%;
    height: 100%;
    padding: 0;
    display: block;
  }
}

.project-details {
  h6 {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 960px) {
  .recent-slider {
    .column {
      width: 100%;
    }

    .grid-description {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .masonry-set-dimensions-2 .two-third {
    &.horizontal {
      width: 100% !important;
      height: auto !important;

      .thumbnail {
        width: 100% !important;
        height: auto !important;
      }
    }

    &.image-left .item-description,
    &.image-right .item-description {
      width: 100%;
      float: left;
    }

    &.image-left .item-description:after,
    &.image-right .item-description:after {
      margin-left: -1rem;
      border: solid transparent;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      top: auto !important;
      bottom: 100%;
      left: 50%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .masonry-set-dimensions-2 {
    .half {
      &.horizontal {
        width: 100% !important;
        height: auto !important;

        .thumbnail {
          width: 100% !important;
          height: auto !important;
        }
      }

      &.image-left .item-description,
      &.image-right .item-description {
        width: 100%;
        float: left;
      }
    }

    .image-left .item-description:after,
    .image-right .item-description:after {
      margin-left: -1rem;
      border: solid transparent;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      top: auto !important;
      bottom: 100%;
      left: 50%;
    }
  }
}

.logos-3 .grid-item:after,
.logos-4 .grid-item:after {
  border-bottom: 1px solid #ddd;
  bottom: -1px;
  width: 100%;
}

[class*="logos-"] .grid-item {
  margin-bottom: 0;
  text-align: center;
}

.logos-1 {
  [class*="content-grid"] {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  a,
  span {
    padding: 2rem 0;
    display: block;
    opacity: 1;
  }

  a:hover {
    opacity: 0.5;
  }
}

.logos-2 {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  .grid-item {
    padding: 0 0 1px 1px;
  }

  a,
  span {
    width: 100%;
    padding: 5rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    opacity: 1;
    display: block;
  }

  a:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.logos-3 {
  .grid-item {
    padding: 0;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    position: relative;

    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      border-left: 1px solid #ddd;
      top: 0;
      left: -1px;
    }

    &:after {
      left: 0;
    }
  }

  a,
  span {
    width: 100%;
    padding: 5rem 1.5rem;
    opacity: 1;
    display: block;
  }

  a:hover {
    background-color: #ddd;
  }
}

.logos-4 {
  [class*="content-grid"] {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
  }

  .grid-item {
    padding: 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      height: 100%;
      border-left: 1px solid #ddd;
      top: 0;
      left: -1px;
    }

    &:after {
      left: 0;
    }
  }
}

.fullscreen-section,
.fullscreen-sections-wrapper {
  position: relative;
  z-index: 0;
}

.logos-4 {
  a,
  span {
    width: 100%;
    padding: 5rem 1.5rem;
    opacity: 1;
    display: block;
  }

  a:hover {
    opacity: 0.5;
  }
}

.logo-slider {
  min-height: 10rem;

  .tms-pagination {
    bottom: 0;
  }

  .tms-bullet-nav {
    background-color: #333;
  }

  .tms-content-scalable {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 960px) {
  [class*="logos-"] {
    height: auto;

    .grid-item {
      width: 33.3333%;
    }
  }

  .logos-2 {
    a,
    span {
      padding: 4.5rem 1.5rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  [class*="logos-"] .grid-item {
    width: 33.3333%;
  }

  .logos-2 {
    a,
    span {
      padding: 3rem 1.5rem;
    }
  }

  .logos-3 {
    a,
    span {
      padding: 3rem 1.5rem;
    }
  }

  .logos-4 {
    a,
    span {
      padding: 3rem 1.5rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  [class*="logos-"] .grid-item {
    width: 50%;
  }

  .logos-2 {
    a,
    span {
      padding: 2rem 1.5rem;
    }
  }

  .logos-3 {
    a,
    span {
      padding: 2rem 1.5rem;
    }
  }

  .logos-4 {
    a,
    span {
      padding: 2rem 1.5rem;
    }
  }
}

[class*="clients-"] [class*="content-grid-"] {
  margin-bottom: -3rem;
}

.clients-1 {
  line-height: 1;

  .client-name {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .client-description {
    font-size: 1.1rem;
    opacity: 0.7;
  }
}

@media only screen and (max-width: 768px) {
  [class*="clients-"] {
    .grid-item {
      width: 33.3333%;
    }

    .client-description {
      display: none;
    }

    .client-name {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  [class*="clients-"] .grid-item {
    width: 50%;
  }
}

[class*="social-"] ul {
  margin-left: 0;
  list-style: none;
}

.social-1 {
  text-align: center;

  h6 {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  ul {
    margin-bottom: 0;

    li {
      padding: 0 1rem;
      margin-bottom: 1rem;
      display: inline-block;
    }
  }
}

.social-2 {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

body.boxed .social-2 {
  padding-left: 0;
  padding-right: 0;
}

.safari-browser .social-2 {
  width: 100.4%;
}

.social-2 {
  [class*="content-grid-"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.full-width [class*="content-grid-"] {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .grid-item {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
}

.social-3 ul li,
.social-4 ul li {
  padding: 0 1rem;
  display: inline-block;
}

.social-2 {
  a {
    width: 100%;
    height: 15rem;
    margin: 0;
    font-size: 2rem;
    line-height: 2rem;
    font-family: "Times New Roman", serif;
    font-style: italic;
    display: table;

    &:hover {
      background: 0 0;
      color: #999;
    }

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  [class*="icon"] {
    width: 100%;
    margin: 0 0 1rem;
    font-size: 3rem;
    display: inline-block;
  }
}

.social-3 {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  ul {
    margin-bottom: -1rem;
    line-height: 1;

    li {
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  a {
    &[class*="icon"] {
      width: 5rem;
      height: 5rem;
      margin: 0;
      line-height: 5rem;
      font-weight: 400;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
    }

    color: #fff;

    &:hover {
      background: #fff;
    }
  }
}

.social-4 {
  text-align: center;

  h6 {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  ul {
    margin-bottom: -1rem;

    li {
      margin: 0 2rem 1rem;

      a {
        font-size: 1.7rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-weight: 700;
        color: #999;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .social-2 {
    ul li {
      width: 50%;
    }

    a {
      width: 100%;
    }
  }

  .social-4 ul li {
    display: block;
  }
}

[class*="stats-"] {
  > .row:not(.flex) {
    height: 100%;

    > .column {
      height: 100%;
    }
  }

  .stat-content {
    width: 100%;
    height: 100%;
    display: table;
  }

  .stat-content-inner {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .stat {
    width: 100%;
    height: 100%;
    display: table;
  }

  .stat-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  &.left .stat-inner {
    text-align: left;
  }

  &.center .stat-inner {
    text-align: center;
  }

  &.right .stat-inner {
    text-align: right;
  }
}

.stats-1 {
  color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  [class*="content-grid"] {
    margin-bottom: -3rem;
  }

  .counter {
    font-size: 6rem;
    line-height: 1;
    font-weight: 400;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.stats-2 {
  color: #666;

  [class*="content-grid"] {
    margin-bottom: -2rem;
  }

  .grid-item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
    border-right: 1px solid #ddd;

    &:last-child {
      border: none;
    }
  }

  .counter {
    margin-bottom: 0.5rem;
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
  }

  .description {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.stats-3 {
  [class*="content-grid"] {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .grid-item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
    border-right: 1px dotted #ddd;
  }

  .counter,
  .mega-stat .counter {
    margin-bottom: 0.5rem;
    line-height: 1;
    font-weight: 700;
  }

  .grid-item:last-child {
    border: none;
  }

  .counter {
    font-size: 3rem;
  }

  .mega-stat .counter {
    font-size: 7rem;
  }

  .description {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #999;
  }

  .mega-stat .description {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
  }

  .description {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dotted #ddd;
    font-weight: 700;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  &[class*="border-"] {
    border-width: 0;

    * {
      border-color: inherit;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .stats-3 .mega-stat .counter {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 768px) {
  .stats-2 {
    .grid-item,
    .row-1 {
      border-bottom: 1px solid #ddd;
    }
  }

  [class*="stats-"] {
    height: auto !important;
  }

  .stats-2 .grid-item {
    border-right: none;
  }

  .stats-3 {
    .description,
    .grid-item.row-1 {
      border-bottom: 1px dotted #ddd;
    }
  }

  .stats-2 {
    .row-1:nth-of-type(even),
    .row-2:nth-of-type(even) {
      border-right: none !important;
    }
  }

  .stats-3 {
    .grid-item {
      border-right: none;

      &.row-1 {
        border-right: none;
      }
    }

    .row-1:nth-of-type(even),
    .row-2:nth-of-type(even) {
      border-right: none !important;
    }
  }

  [class*="stats-"].center-on-mobile .stat-inner {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .stats-3 {
    .counter {
      font-size: 4rem;
    }

    .mega-stat {
      .counter {
        font-size: 4rem;
      }

      .description {
        font-size: 1.2rem;
      }
    }

    .grid-item.row-1 {
      border: none;
    }

    .description {
      margin-bottom: 4rem;
      padding-bottom: 0;
      border: none;
    }
  }
}

.section-block {
  &.fullscreen-section,
  &.fullscreen-sections-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fullscreen-section {
  width: 100%;
  height: 100%;
  height: 100vh;
  display: table;
  table-layout: fixed;
  overflow: hidden;
}

body.boxed .fullscreen-section {
  padding-left: 0;
  padding-right: 0;
}

.fullscreen-section {
  &.in-view {
    z-index: 1;
  }

  .background-image {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 0;
  }

  .background-slider-wrapper,
  &.background-fixed .background-image {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-attachment: fixed;
    z-index: 0;
  }

  .background-slider-wrapper {
    height: 100%;
  }

  &.background-fixed .background-slider-wrapper {
    position: fixed;
  }
}

.ie-browser.webkit .fullscreen-section.background-fixed {
  .background-image-wrapper {
    height: 100vh;
    position: absolute !important;
  }

  .background-image {
    background-attachment: fixed;
  }
}

.webkit.side-nav-open .background-fixed .background-image-wrapper {
  position: absolute;
}

.mobile .fullscreen-section {
  height: auto;

  .background-image,
  .background-image-wrapper {
    width: 100vw;
    position: relative !important;
  }

  &.background-fixed {
    .background-image-wrapper {
      width: 100vw;
      position: relative !important;
    }

    .background-image {
      background-attachment: scroll;
    }
  }
}

.fs-pagination {
  width: 2.8rem;
  padding: 1rem;
  text-align: center;
  visibility: visible;
  opacity: 0;
  position: fixed;
  right: 25px;
  top: 50%;
  z-index: 10;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-backface-visibility: hidden;
}

.fs-bullet-nav {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background: #fff;
  opacity: 0.5;
  border-radius: 50%;
}

.fullscreen-sections-wrapper.nav-dark .fs-bullet-nav,
.nav-dark .fs-bullet-nav {
  background: #333;
}

.fs-bullet-nav {
  &.active,
  &:hover {
    opacity: 1;
  }
}

.mobile .fs-pagination {
  display: none;
}

.fullscreen-section {
  .fullscreen-inner {
    width: 100%;
    height: 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    background: rgba(0, 0, 0, 0.6);
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 2;
  }

  &.no-overlay .fullscreen-inner {
    background: rgba(0, 0, 0, 0);
  }
}

body.boxed {
  .fullscreen-section .fullscreen-inner,
  .fullscreen-sections-wrapper .fullscreen-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.fullscreen-section {
  footer {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: absolute;
    bottom: 0;
    z-index: 3;
  }

  .copyright {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    color: #fff;
  }

  .social-list {
    margin-bottom: 0;
  }
}

.fullscreen-inner {
  &.v-align-top {
    vertical-align: top;
  }

  &.v-align-bottom {
    vertical-align: bottom;
  }
}

.fullscreen-section {
  .hero-4,
  .hero-5,
  .hero-6 {
    height: 100vh;
  }
}

.coming-soon-2 h1 {
  font-size: 10rem;
  line-height: 1;
}

.error-404 h1,
.error-500 h1 {
  font-size: 15rem;
  line-height: 1;
}

.error-404 {
  .search-form-container {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }

  .form-submit {
    width: 100%;
  }
}

.error-500 .button {
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
}

[class*="error-"] .background-image,
[class*="coming-soon-"] .background-image {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  .fs-pagination {
    display: none;
  }

  .fullscreen-section {
    footer {
      position: relative;
    }

    .column:last-child > :last-child:not(.hero-content):not(input[type="submit"]) {
      margin-bottom: 0;
    }

    &.content-below-on-mobile {
      height: auto;
      display: block;

      .fullscreen-inner {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
        background-color: transparent;
        display: block;
        text-align: left;
      }
    }

    &.no-overlay.content-below-on-mobile .background-on-mobile {
      padding: 0;
      margin-bottom: 0;
      background-color: #f4f4f4;
    }

    .background-on-mobile *,
    &.no-overlay.content-below-on-mobile * {
      color: #666;
    }

    &.content-below-on-mobile .alt-color-on-mobile {
      color: #fff;
    }

    .background-image,
    .background-image-wrapper {
      max-width: 100vw;
      background-attachment: scroll;
    }

    &.background-fixed {
      .background-image,
      .background-image-wrapper {
        max-width: 100vw;
        background-attachment: scroll;
      }
    }
  }

  .webkit .fullscreen-section {
    .background-image-wrapper {
      max-width: 100vw;
      background-attachment: scroll;
    }

    &.background-fixed {
      .background-image,
      .background-image-wrapper {
        max-width: 100vw;
        background-attachment: scroll;
      }
    }
  }

  .content-below-on-mobile {
    .background-image,
    .background-image-wrapper,
    .background-slider-wrapper {
      position: relative !important;
    }
  }

  .fs-image-scale {
    .background-image,
    .background-image-wrapper {
      position: relative !important;
    }
  }

  .fullscreen-section.background-contain .background-image {
    background-position: center !important;
    background-size: 100% !important;
  }

  .mobile .fullscreen-section {
    .background-image {
      max-width: 114rem;
      height: inherit;
    }

    margin-top: -1px;
  }

  .coming-soon-2 h1 {
    font-size: 6rem;
  }

  .error-404 {
    .form-submit {
      width: auto;
    }

    h1 {
      font-size: 10rem;
    }
  }

  .error-500 h1 {
    font-size: 10rem;
  }
}

.pagination-1 a.disabled,
.pagination-2 a.disabled {
  color: #ddd;

  &:hover {
    color: #ddd;
  }
}

.mobile {
  .fullscreen-section {
    &[class*="error-"],
    &[class*="coming-soon-"] {
      height: 100vh;
      display: table;
    }
  }

  [class*="error-"] .fullscreen-inner,
  [class*="coming-soon-"] .fullscreen-inner {
    display: table-cell;
  }

  .fullscreen-section {
    &[class*="error-"] .background-image-wrapper,
    &[class*="coming-soon-"] .background-image-wrapper {
      position: fixed;
    }
  }
}

.pagination-next.disabled,
.pagination-previous.disabled {
  transition: none;
  cursor: default;
  background: 0 0 !important;

  &:hover {
    transition: none;
    cursor: default;
    background: 0 0 !important;
  }
}

[class*="pagination-"] ul {
  margin-left: 0;
  list-style: none;
}

.pagination-1 {
  small {
    font-size: 1.3rem;
    display: block;

    span {
      font-size: 1.3rem;
      display: inline-block;
    }
  }

  [class*="icon-"] {
    height: 3.6rem;
    font-size: 3.6rem;
    display: inline-block;
  }

  .pagination-previous {
    text-align: left;

    [class*="icon-"] {
      margin-right: 1rem;
      float: left;
    }
  }

  .pagination-next [class*="icon-"] {
    margin-left: 1rem;
    float: right;
  }
}

.pagination-2 .pagination-previous [class*="icon-"] {
  margin-left: 1rem;
  float: right;
}

.pagination-1 {
  .pagination-next {
    text-align: right;
  }

  span {
    font-size: 2rem;
    font-weight: 700;
    display: block;
  }

  .page-list,
  .return-to-index {
    margin-top: 0.3rem;
    margin-bottom: 0;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
  }

  .page-list li {
    padding: 0 0.7rem;
  }
}

.section-block.pagination-2 {
  padding-top: 0;
  padding-bottom: 0;
}

.pagination-2 .column {
  background-size: cover;
  background-position: center;
  -webkit-transform: translate(0, 0);

  &:hover {
    background-size: cover;
    background-position: center;
    -webkit-transform: translate(0, 0);
  }
}

body.boxed .pagination-2 {
  padding-left: 0;
  padding-right: 0;
}

.pagination-2 {
  .pagination-next {
    padding-top: 7rem;
    padding-bottom: 7rem;
    display: block;
    position: relative;
  }

  .pagination-previous {
    padding-top: 7rem;
    padding-bottom: 7rem;
    display: block;
    position: relative;
    padding-left: 3rem;
    padding-right: 5rem;
    background: #f9f9f9;
    text-align: right;
  }

  .pagination-next {
    padding-right: 3rem;
    padding-left: 5rem;
    background: #eee;
    text-align: left;
  }

  a:hover {
    background: red;
  }

  small {
    font-size: 1.3rem;
    display: block;

    span {
      font-size: 1.3rem;
      display: inline-block;
    }
  }

  [class*="icon-"] {
    height: 3.6rem;
    font-size: 3.6rem;
    display: inline-block;
  }

  .pagination-next [class*="icon-"] {
    margin-right: 1rem;
    float: left;
  }
}

.pagination-3 {
  a,
  ul {
    margin: 0;
    text-align: center;
  }
}

.pagination-2 span {
  font-size: 2rem;
  font-weight: 700;
  display: block;
}

.pagination-3 {
  ul li {
    display: inline-block;
  }

  a {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    display: block;
    border: 1px solid #ddd;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;

    &.current,
    &:hover {
      background: #333;
      color: #fff;
      border: transparent;
    }
  }

  li {
    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }

  a.disabled {
    color: #ddd;
    border-color: #ddd;

    &:hover {
      color: #ddd;
      border-color: #ddd;
    }
  }
}

.pagination-4 {
  text-align: center;

  .button {
    width: auto;
    margin: 0 0.5rem;
    font-size: 1.6rem;
    font-weight: 700;

    &.disabled {
      color: #ddd;
      background: 0 0;
      border-color: #ddd;

      &:hover {
        color: #ddd;
        background: 0 0;
        border-color: #ddd;
      }
    }

    [class*="icon-"]:before {
      margin: 0;
    }
  }

  .pagination-next [class*="icon-"],
  .pagination-previous [class*="icon-"] {
    display: none;
  }
}

.pagination-5 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 1px solid #ddd;

  ul {
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
      margin-left: -0.4rem;
    }
  }
}

.footer ul,
.post-info span:first-child {
  margin-left: 0;
}

.pagination-5 {
  a {
    width: 6rem;
    height: 6rem;
    color: #333;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 6rem;
    display: block;

    &.current {
      color: #999;
    }

    &:hover {
      background: #333;
      color: #fff;
    }
  }

  li {
    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }

  a {
    &.disabled {
      color: #ddd;
      border-color: #ddd;

      &:hover {
        color: #ddd;
        border-color: #ddd;
      }
    }

    &.pagination-next {
      width: auto;
      padding: 0 2rem;
      font-weight: 700;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }

    &.pagination-previous {
      width: auto;
      padding: 0 2rem;
      font-weight: 700;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      padding-right: 3.5rem;
    }

    &.pagination-next {
      padding-left: 3.5rem;
    }

    span {
      width: auto;
      float: left;
    }

    [class*="icon-"] {
      height: 100%;
      margin-right: 0;
      line-height: 6rem;
      font-size: 3.2rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .pagination-1 {
    .page-list,
    .pagination-next,
    .return-to-index {
      text-align: left;
    }
  }

  .pagination-2 .pagination-previous {
    text-align: left;
  }

  .pagination-1 {
    .page-list,
    .pagination-previous,
    .return-to-index {
      padding-bottom: 3.8rem;
      margin-bottom: 3.8rem;
      border-bottom: 1px solid #ddd;
      display: block;
    }

    .pagination-previous [class*="icon-"] {
      margin-right: 0;
      float: right;
    }

    .pagination-next [class*="icon-"] {
      margin-left: 0;
    }
  }

  .pagination-2 {
    [class*="pagination-"] {
      padding: 4rem 1.5rem;
    }

    .pagination-previous {
      &:after {
        width: 10rem;
        height: 1rem;
        bottom: 0;
      }

      [class*="icon-"] {
        margin-left: 0;
        margin-right: 1rem;
        float: left;
      }
    }
  }

  .pagination-5 ul li a {
    &.pagination-next,
    &.pagination-previous {
      width: 6rem;
      padding: 0;
      text-align: center;
    }

    span {
      float: none;
    }

    &.pagination-next span:first-child,
    &.pagination-previous span:last-child {
      display: none;
    }
  }
}

@media only screen and (max-width: 490px) {
  .pagination-1,
  .pagination-3 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pagination-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;

    span {
      display: none;
    }

    .back-to-index [class*="icon-"],
    .pagination-next [class*="icon-"],
    .pagination-previous [class*="icon-"] {
      display: block;
    }
  }

  .pagination-3 ul li,
  .pagination-5 ul li {
    display: none;
  }

  .pagination-3 ul li {
    &:first-child,
    &:last-child {
      display: inline-block;
    }
  }

  .pagination-5 ul li {
    &:first-child,
    &:last-child {
      display: inline-block;
    }
  }
}

.section-block.map-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.map-container {
  width: 100%;
  height: 40rem;

  > div {
    width: 100%;
    height: 100%;

    img {
      max-width: none;
    }
  }
}

.gm-style .gm-style-iw {
  padding: 0.3rem;
  color: #666;
  line-height: 1.5;
}

.footer {
  width: 100%;
  background-color: #222;
  position: relative;
  z-index: 1;

  .row.flex > .column {
    flex-direction: column;
  }
}

body.boxed .footer {
  .footer-bottom,
  .footer-top {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.footer {
  .footer-top {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .widget {
    margin-bottom: 3rem;
  }

  ul {
    list-style: none;
  }

  .footer-top-inner > :first-child,
  .widget:last-child > :first-child {
    margin-top: 0;
  }

  .footer-top-inner > :last-child,
  .widget:last-child > :last-child {
    margin-bottom: 0;
  }

  .footer-bottom {
    padding-bottom: 2rem;
    color: #555;
  }

  .footer-bottom-inner {
    padding-top: 3rem;
    border-top: 1px solid #333;
  }

  .footer-logo {
    width: 17rem;
    margin-bottom: 1rem;
    display: inline-block;
    line-height: 1;

    a {
      -webkit-transition-property:
        opacity,
        background,
        color,
        visibility,
        -webkit-transform;
      transition-property: opacity, background, color, visibility, transform;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .footer-bottom .copyright {
    font-size: 1.1rem;
    float: left;
  }

  .navigation,
  .social-list {
    float: none;
  }

  .navigation {
    a {
      line-height: 2rem;
      text-transform: uppercase;
      background: 0 0;
      padding: 0 1rem;

      &:hover {
        background: 0 0;
        color: #fff;
      }
    }

    li {
      &:hover a {
        background: 0 0;
      }

      &:first-child a {
        padding-left: 0;
        margin-left: 0;
      }

      &:last-child a {
        padding-right: 0;
        margin-right: 0;
      }

      &.current a {
        background: 0 0;
        color: #fff;

        &:hover {
          background: 0 0;
          color: #fff;
        }
      }
    }
  }

  .footer-bottom {
    .navigation,
    .social-list {
      float: right;
    }

    .copyright,
    .navigation,
    .social-list {
      margin-bottom: 1rem;
    }
  }
}

.mobile .footer-fixed {
  position: relative;
  bottom: auto;
}

@media only screen and (min-width: 960px) {
  .footer-fixed {
    position: fixed;
    bottom: 0;
    z-index: 0;
  }

  .content.reveal-footer {
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0);
    position: relative;
    z-index: 1;
  }
}

@media only screen and (max-width: 960px) {
  .content.reveal-footer {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-top > .row > .column {
    width: 100%;
  }

  [class*="footer-"] .footer-bottom {
    .copyright,
    .navigation,
    .social-list {
      display: block;
      float: none;
    }
  }

  .footer .navigation li {
    display: inline-block;
    float: none;
  }
}

.post {
  margin-bottom: 7rem;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.post-content {
  position: relative;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;

  + {
    .post-content,
    .post-media {
      margin-top: 3rem;
    }
  }
}

.post-media {
  + .post-info-aside,
  ~ .post-content {
    margin-top: 3rem;
  }
}

.blog-masonry.masonry-set-dimensions {
  .post,
  .post-media {
    height: 100%;
  }

  .post-content {
    width: 100%;
    height: auto;
    padding: 0;
    color: #fff;
    display: table;
    position: absolute;
    bottom: 0;
    z-index: 2;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .post-content-inner {
    height: 100%;
    padding: 2rem;
    display: table-cell;
    vertical-align: bottom;
  }
}

.post-content {
  > :last-child {
    margin-bottom: 0;
  }

  &.with-background {
    padding: 3rem;
    margin-top: 0;
    background: #f9f9f9;

    > :first-child {
      margin-top: 0;
    }
  }
}

.blog-masonry.masonry-set-dimensions {
  .post-content.with-background {
    background: rgba(0, 0, 0, 0.2);
  }

  .post-media:hover .post-content {
    background: rgba(0, 0, 0, 0.8);
  }
}

.post-info {
  margin-bottom: 3rem;
}

.blog-masonry.masonry-set-dimensions .post-info {
  margin-bottom: 1rem;
}

.post-content .read-more,
.post-media > * {
  margin-bottom: 0;
}

.post-author-aside,
.post-comments-aside,
.post-info-aside {
  position: absolute;
  z-index: 1;
}

.post-author-aside + .author-bio,
.post-comments-aside + .comments-inner,
.post-info-aside + .post-content {
  padding-left: 10rem;
}

.post-info {
  padding: 0;
  margin-right: 0.2rem;
  margin-top: 0;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  border: none;

  span {
    padding-top: 0;
    margin-top: 0;
    border: none;
  }
}

.post-author .author-avatar img,
.post-comments .user-avatar img {
  border-radius: 50%;
}

.post-info {
  span:last-child {
    margin-right: 0;
  }

  [class*="icon-"] {
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .post-love [class*="icon-"] {
    margin-right: 0.2rem;
  }
}

.post-comments a [class*="icon-"],
.post-love a [class*="icon-"] {
  width: 1.2rem;
  text-align: center;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.2s;
  transform-transition-duration: 0.2s;
}

.post-comment a:hover [class*="icon-"],
.post-love a:hover [class*="icon-"] {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

.post-media {
  max-width: 114rem;

  > * {
    float: none;
  }
}

.blog-masonry {
  &.masonry-set-dimensions {
    .post-media {
      position: relative;
      overflow: hidden;
    }

    &.no-margins .post-media {
      position: static;
      overflow: hidden;
    }
  }

  .post-media .post-slider {
    width: 82.5rem;
    height: 55rem;
  }
}

.blog-regular .post-media .post-slider,
.blog-single-post .post-media .post-slider {
  width: 82.5rem;
  height: 55rem;
}

.blog-wide .post-media .post-slider {
  width: 111rem;
  height: 74rem;
}

.post-media .mejs-audio {
  height: 4.9rem !important;

  .mejs-button {
    &.mejs-mute,
    &.mejs-pause,
    &.mejs-play {
      height: 4.9rem !important;
    }
  }

  .mejs-container .mejs-button {
    height: 4.9rem !important;
  }

  .mejs-controls {
    height: 4.9rem !important;

    div {
      &.mejs-horizontal-volume-slider,
      &.mejs-time-rail {
        height: 4.9rem !important;
      }
    }

    .mejs-time {
      margin-top: 1rem;
    }
  }

  .mejs-time-rail .mejs-time-total {
    margin-top: 2.1rem;
  }

  .mejs-horizontal-volume-slider {
    .mejs-horizontal-volume-current,
    .mejs-horizontal-volume-total {
      top: 2.1rem;
    }
  }

  .mejs-button {
    button {
      margin: 1.6rem 0.7rem;
    }

    &.mejs-pause,
    &.mejs-play {
      width: 4.4rem !important;
    }

    &.mejs-pause button,
    &.mejs-play button {
      margin: 1.6rem 1.4rem;
    }
  }
}

.blog-masonry {
  &.no-padding-top {
    padding-top: 1rem;
  }

  &.no-padding-bottom {
    padding-bottom: 0.5rem;
  }

  &.full-width.no-margins {
    padding: 0;
  }

  [class*="content-grid"] {
    margin-bottom: -3rem;
  }

  &.masonry-set-dimensions [class*="content-grid"] {
    margin-bottom: -1.5rem;
  }

  &.no-margins [class*="content-grid"] {
    padding: 0 !important;
    margin: 0 !important;

    .grid-item {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  > .row > .column {
    padding-left: 0;
    padding-right: 0;
  }

  &.full-width [class*="content-grid"] {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

body.boxed .blog-masonry.full-width [class*="content-grid"] {
  margin-left: 0;
  margin-right: 0;
}

.blog-masonry {
  [class*="content-grid"] .grid-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-item {
    .video,
    .video-container {
      margin-bottom: 0;
    }
  }

  h2.post-title {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.blog-regular h2.post-title,
.blog-single-post h2.post-title,
.blog-wide h2.post-title {
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-masonry.small-margins {
  > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &.no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.full-width > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.no-margins.full-width > .row > .column {
    padding-left: 0;
    padding-right: 0;
  }

  &.full-width [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-top: -0.5rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -0.5rem;

    .grid-item {
      margin-bottom: 0;
      padding: 0.5rem;
      position: relative;
    }
  }
}

.post-author,
.post-aux-info,
.post-comments,
.single-post-tags {
  padding-top: 3rem;
  border-top: 1px solid #ddd;
}

.blog-masonry {
  &.no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.full-width {
    &.no-margins > .row > .column {
      padding-left: 0;
      padding-right: 0;
    }

    .row {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 1140px) {
  .blog-wide .post-media .post-slider {
    width: 93rem;
    height: 62rem;
  }

  .blog-regular .post-media .post-slider {
    width: 69rem;
    height: 46rem;
  }

  .blog-masonry [class*="content-grid"] .grid-item {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 960px) {
  .blog-wide .post-media .post-slider {
    width: 73rem;
    height: 48.7rem;
  }

  .blog-regular .post-media .post-slider {
    width: 54rem;
    height: 36rem;
  }

  .blog-masonry [class*="content-grid"] .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider {
    width: 57rem;
    height: 38rem;
  }

  .post-info {
    .show-on-mobile a,
    [class*="icon-"],
    span.show-on-mobile {
      display: inline-block !important;
    }
  }

  .post-author-aside,
  .post-comments-aside,
  .post-info-aside {
    display: none !important;
  }

  .post-author-aside + .author-bio,
  .post-comments-aside + .comments-inner,
  .post-info-aside + .post-content {
    padding-left: 0 !important;
  }

  .comment-list ul {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider {
    width: 45rem;
    height: 30rem;
  }

  .blog-masonry [class*="content-grid"] .grid-item {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider {
    width: 32rem;
    height: 21.3rem;
  }
}

.single-post {
  .post {
    margin-bottom: 0;
  }

  .post-content {
    .post-media {
      margin-bottom: 3rem;
    }

    &.with-background .post-media {
      margin-bottom: 0;
    }
  }
}

.single-post-tags {
  width: 100%;
  margin-top: 3rem;
  font-size: 1.2rem;

  .tags-title {
    font-weight: 700;
    margin-top: 0;
  }

  a {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    display: inline-block;
    letter-spacing: 0.1rem;
  }
}

.post-author,
.post-aux-info {
  margin-top: 3rem;
}

.post-author {
  .author-avatar {
    max-width: 7rem;
    float: left;
  }

  &.center .author-avatar {
    margin: 0 auto 2rem;
    float: none;
  }

  &:not(.center) .author-bio .author-avatar + .author-content {
    margin-left: 10rem;
  }

  .author-content :last-child {
    margin-bottom: 0;
  }

  .name {
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
  }
}

.post-comments {
  margin-top: 3rem;

  .comments-title,
  ol,
  ul {
    margin-bottom: 3rem;
  }

  .comment-list {
    margin-left: 0;
  }

  ol:not(.comment-list),
  ul:not(.comment-list) {
    margin-left: 2rem;
  }

  ol:not(.comment-list) {
    ol,
    ul {
      margin: 0.4rem 0 0.4rem 3rem;
    }
  }

  ul:not(.comment-list) {
    ol,
    ul {
      margin: 0.4rem 0 0.4rem 3rem;
    }
  }

  .comment + ul {
    list-style: none;
  }

  .comment-list {
    list-style: none;

    > li {
      margin-bottom: 4rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .comment {
      ol,
      ul {
        margin: 0 0 3rem 2rem;
        padding: 0;
      }

      ol {
        ol,
        ul {
          margin: 0.4rem 0 0.4rem 3rem;
        }
      }

      ul {
        ol,
        ul {
          margin: 0.4rem 0 0.4rem 3rem;
        }
      }

      li {
        margin: 0;
      }
    }
  }

  .user-avatar {
    max-width: 7rem;
    float: left;
  }

  .comment-content {
    margin-left: 10rem;
  }

  .name {
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
  }

  .comment-meta {
    width: 100%;
    margin-bottom: 2rem;
    line-height: 1;
  }
}

.post-comment-respond .reply-title,
.sidebar .widget {
  margin-bottom: 3rem;
}

.post-comments .comment-meta a {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;

  &:first-child {
    margin-left: 0;
  }
}

.post-comment-respond {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #ddd;

  .comment-form .column {
    float: none;
  }
}

.sidebar {
  &.left .sidebar-inner {
    padding-right: 1rem;
  }

  &.right .sidebar-inner {
    padding-left: 1rem;
  }

  &.left .sidebar-inner,
  &.right .sidebar-inner {
    text-align: left;
  }

  .widget {
    ul {
      margin-left: 0;
      list-style: none;
    }

    li {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }

    &:last-child,
    > :last-child {
      margin-bottom: 0;
    }

    &:first-child .widget-title {
      margin-top: 0;
    }
  }
}

.widget .post-info {
  display: block;
  margin-bottom: 0;
  line-height: 1;
}

@media only screen and (max-width: 960px) {
  .sidebar .sidebar-inner {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding: 3rem;
    margin-top: 3rem;
    border-top: 1px solid #ddd;
  }
}

@media only screen and (max-width: 480px) {
  .post-comments {
    .user-avatar {
      margin-bottom: 1.5rem;
      float: none;
    }

    .comment-content {
      margin-left: 0;
    }
  }
}

[class^="title-"] {
  margin-bottom: 2rem;
}

.title-xlarge {
  font-size: 9rem;
  line-height: 9rem;
}

.title-large {
  font-size: 7rem;
  line-height: 7rem;
}

.title-medium {
  font-size: 5rem;
  line-height: 5rem;
}

.title-small {
  font-size: 3rem;
  line-height: 3rem;
}

.text-xlarge {
  font-size: 2.2rem;
}

.text-large {
  font-size: 1.8rem;
}

.text-medium {
  font-size: 1.5rem;
}

.text-small {
  font-size: 1.2rem;
}

.text-uppercase {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .title-xlarge {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  .title-large {
    font-size: 4rem;
    line-height: 4rem;
  }

  .title-medium {
    font-size: 3rem;
    line-height: 3rem;
  }

  .title-small {
    font-size: 2rem;
    line-height: 2rem;
  }

  .text-xlarge {
    font-size: 2rem;
    line-height: 1.7;
  }

  .text-large {
    font-size: 1.6rem;
    line-height: 1.6;
  }

  .text-medium {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .text-small {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

.countdown {
  max-height: 0;
  overflow: hidden;
  -webkit-transition-property: max-height;
  transition-property: max-height;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;

  &.active {
    max-height: 20rem;
  }

  .unit {
    margin-right: 1rem;
    font-size: 3rem;
    line-height: 1.4;
  }
}

.countdown-1 {
  .unit-type {
    opacity: 0.5;
  }

  &.center .unit {
    margin: 0 0.5rem 0.5rem;
    font-size: 3rem;
  }

  &.right .unit {
    margin: 0 0 0 1rem;
    font-size: 3rem;
  }
}

.countdown-2 {
  .unit {
    padding: 0.5rem 1rem;
    display: inline-block;
    border-radius: 0.3rem;
    line-height: 1;
    background: #f4f4f4;

    span {
      display: block;
    }
  }

  .unit-type {
    opacity: 0.5;
    font-size: 50%;
  }
}

.shop {
  .product-price .font-big-amount {
    opacity: 0.6;
    font-size: 2.476rem;
  }

  .product-price ins .font-big-amount {
    font-size: 2.476rem;
    font-weight: 400;
    color: #212325;
  }

  .product {
    .onsale,
    .outofstock {
      width: auto;
      height: auto;
      padding: 0.3rem 0.8rem;
      background-color: #666;
      color: #fff;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.8;
      text-transform: uppercase;
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 2;
    }
  }

  .cart-overview .checkout [class*="icon-"] {
    margin-right: 0.2rem;
  }

  .star-rating [class*="icon-"] {
    margin: 0;
  }

  .cart-overview {
    .product-remove a,
    a.product-remove {
      width: 3.2rem;
      height: 1.6rem;
      margin: 0;
      background-color: #eee;
      border-radius: 50%;
      font-size: 0.9rem;
      line-height: 1.7rem;
      text-align: center;
      color: #666;
    }
  }

  .quantity {
    max-width: 8rem;
  }

  .cart-overview td,
  .cart-totals th {
    border-left: none;
    border-bottom: 1px solid #eee;
  }
}

.single-product .review-comments,
.widget .cart-subtotal {
  border-left: none;
  border-bottom: 1px solid #eee;
}

.shop {
  .cart-overview .table {
    border-left: none;
    border-right: none;

    > thead {
      border-left: none;
      border-right: none;

      th {
        border-top: none;
        border-left: none;
      }
    }

    border-top: none;
    border-left: none;
  }

  .cart-totals td {
    text-align: right;
  }

  .cart-subtotal th {
    font-weight: 400;
  }

  .cart-order-total {
    font-weight: 700;
  }

  .grid-filter-options-inner {
    padding-top: 2rem;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    .form-element {
      margin-bottom: 2rem;
    }
  }

  .table {
    .product-details {
      img {
        margin-right: 1.5rem;
      }

      span > * {
        display: block;
      }
    }

    .product-thumbnail span > * {
      display: block;
    }
  }

  .products {
    .product {
      .product-details {
        margin-top: 2rem;
      }

      .product-title {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }

      .product-thumbnail .product-title {
        display: block;
        margin-bottom: 1rem;
      }

      .product-description {
        margin-bottom: 1rem;
      }

      .product-price {
        font-size: 1.2rem;
      }

      .product-thumbnail .product-price {
        font-weight: 400;
      }

      .product-price {
        del,
        ins {
          padding: 0 0.3rem;
        }
      }

      .button {
        margin-bottom: 0;
      }
    }

    .product-result-count {
      float: left;
    }

    .product .product-thumbnail {
      .product-actions {
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 101;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transform: translateY(4rem);
        transform: translateY(4rem);
      }

      &:hover .product-actions {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }

      .product-actions .add-to-cart-button {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 1140px) {
  .shop .table .product-details > * {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1140px) {
  .shop .table .product-details img {
    margin-bottom: 1rem;
  }
}

.mobile.shop .products .product .product-thumbnail .product-actions,
.shop .products .product .product-actions-mobile {
  display: none;
}

.mobile.shop .products .product .product-actions-mobile {
  margin-top: 1rem;
  display: block;
}

.widget {
  .product-list {
    li {
      padding-top: 1.3rem;
      padding-bottom: 1.5rem;

      a {
        display: block;
      }
    }

    .cart-item {
      position: relative;
    }
  }

  .cart-item .product-title {
    padding-right: 5rem;
  }

  .product-list {
    .product-thumbnail {
      width: 5rem;
      float: right;
    }

    .product-price {
      font-size: 1.1rem;
    }

    .product-title {
      margin-bottom: 0.4rem;
      display: block;
    }

    .product-price del {
      padding-right: 0.6rem;
    }

    .star-rating {
      display: block;
      line-height: 1;
    }
  }

  .cart-overview a.product-remove {
    margin: 0.3rem 0.8rem 0 0;
    color: inherit;
    display: inline;
    float: left;
  }

  .cart-subtotal .amount,
  .product-list .cart-actions .checkout {
    float: right;
  }

  .cart-subtotal {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 700;
  }

  .product-list .cart-actions a {
    display: inline-block;
  }

  .product-tag-cloud a {
    padding: 0.4rem 0.8rem;
    margin: 0 0.5rem 1rem 0;
    display: inline-block;
    border: 1px solid #eee;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

.single-product {
  .product-images .product-thumbnails {
    .grid {
      min-height: 0;
      margin-bottom: 0;
    }

    .grid-item {
      width: 25%;
    }
  }

  .product-summary {
    .product-price {
      del,
      ins {
        display: inline;
        font-size: 2rem;
        line-height: 1;
      }

      del {
        padding-right: 1rem;
      }

      margin-bottom: 2rem;
    }

    .product-rating {
      font-size: 1.2rem;
      float: right;
    }

    .quantity {
      margin-right: 1rem;
    }

    .add-to-cart-button,
    .quantity {
      float: left;
    }

    .product-meta > span {
      display: table;
      table-layout: fixed;
    }

    .product-addtional-info li:first-child a {
      border-top: 1px solid #eee;
    }
  }

  .review-comments {
    margin-bottom: 3rem;
    padding-bottom: 3rem;

    .comments-title {
      margin-bottom: 3rem;
    }

    .comment + ul {
      margin-left: 0;
      list-style: none;
    }

    .comment-list {
      margin-left: 0;
      list-style: none;

      > li {
        margin-bottom: 4rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .user-avatar {
      max-width: 7rem;
      float: left;

      img {
        border-radius: 50%;
      }
    }

    .comment-content {
      margin-left: 10rem;
    }

    .name {
      font-size: 1.6rem;
      margin-bottom: 0.6rem;
    }

    .comment-meta {
      width: 100%;
      margin-bottom: 2rem;
      line-height: 1;
      margin: 0 0 2rem;
      display: inline-block;
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
      font-weight: 700;

      span {
        margin: 0;
      }
    }

    .review-star-rating {
      float: right;
    }

    .comment-meta a:first-child {
      margin-left: 0;
    }
  }
}

.nav-block {
  .cart-indication .badge {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    font-size: 0.9rem;
    line-height: 1.6rem;
    letter-spacing: 0;
    text-align: center;
    background: #232323;
    color: #fff;
    position: absolute;
  }

  .cart-overview {
    .cart-item {
      padding: 1rem 0;
      text-align: left;
      border: none;
    }

    .cart-actions a,
    .cart-item a {
      letter-spacing: 0;
    }

    .cart-actions {
      &:after,
      &:before {
        height: 0;
        content: ".";
        display: block;
        overflow: hidden;
      }
    }

    .cart-item {
      &:after,
      &:before {
        height: 0;
        content: ".";
        display: block;
        overflow: hidden;
      }
    }

    .cart-actions:after,
    .cart-item:after {
      clear: both;
    }

    li:first-child {
      padding-top: 0;
    }

    .product-thumbnail {
      width: 5rem;
      margin-right: 1.3em;
      float: left;
    }

    .product-details {
      position: relative;
      display: block;
      overflow: auto;
    }

    .product-title {
      padding-right: 1.5rem;
      display: block;
      background: 0 0;
      font-size: 1.2rem;
      line-height: 1;
      font-weight: 400;
    }

    .product-price,
    .product-quantity {
      font-size: 1.1rem;
    }

    a.product-remove {
      position: absolute;
      top: 0;
      right: 0;
    }

    .cart-subtotal {
      padding: 1rem 0;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;

      > a:hover {
        background: 0 0;
      }

      .amount {
        float: right;
      }
    }

    .cart-actions {
      padding-top: 2rem;
      border-top: 1px solid #444;
    }

    .checkout,
    .view-cart {
      display: inline-block;
      float: left;
    }

    .checkout:not(.button),
    .view-cart:not(.button) {
      padding: 0;
      background: 0 0;
    }

    .checkout {
      float: right;
    }
  }
}

.cart .cart-overview {
  .product-thumbnail a {
    width: 8rem;
    display: block;
  }

  .form-element {
    margin-bottom: 0;
  }

  .cart-actions td {
    padding: 3rem 0 0;
  }

  .cart-coupon-form {
    .form-element {
      max-width: 17rem;
      float: left;
    }

    float: left;
  }

  .update-cart {
    float: right;
  }
}

@media only screen and (max-width: 600px) {
  .cart .cart-overview {
    .cart-coupon-form .form-element {
      margin-bottom: 1rem;
    }

    .product-quantity input {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .shop .cart-overview .product-thumbnail {
    display: none;
  }
}

.checkout .checkout-payment p {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 0.5rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mt-15 {
  margin-top: 1.5rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-60 {
  margin-top: 6rem;
}

.mt-70 {
  margin-top: 7rem;
}

.mt-80 {
  margin-top: 8rem;
}

.mt-90 {
  margin-top: 9rem;
}

.mt-100 {
  margin-top: 10rem;
}

.mt-110 {
  margin-top: 11rem;
}

.mt-120 {
  margin-top: 12rem;
}

.mt-130 {
  margin-top: 13rem;
}

.mt-140 {
  margin-top: 14rem;
}

.mt-150 {
  margin-top: 15rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 0.5rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.mb-30 {
  margin-bottom: 3rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-50 {
  margin-bottom: 5rem;
}

.mb-60 {
  margin-bottom: 6rem;
}

.mb-70 {
  margin-bottom: 7rem;
}

.mb-80 {
  margin-bottom: 8rem;
}

.mb-90 {
  margin-bottom: 9rem;
}

.mb-100 {
  margin-bottom: 10rem;
}

.mb-110 {
  margin-bottom: 11rem;
}

.mb-120 {
  margin-bottom: 12rem;
}

.mb-130 {
  margin-bottom: 13rem;
}

.mb-140 {
  margin-bottom: 14rem;
}

.mb-150 {
  margin-bottom: 15rem;
}

.pt-0,
.section-block.pt-0 {
  padding-top: 0;
}

.pt-5,
.section-block.pt-5 {
  padding-top: 0.5rem;
}

.pt-10,
.section-block.pt-10 {
  padding-top: 1rem;
}

.pt-20,
.section-block.pt-20 {
  padding-top: 2rem;
}

.pt-30,
.section-block.pt-30 {
  padding-top: 3rem;
}

.pt-40,
.section-block.pt-40 {
  padding-top: 4rem;
}

.pt-50,
.section-block.pt-50 {
  padding-top: 5rem;
}

.pt-60,
.section-block.pt-60 {
  padding-top: 6rem;
}

.pt-70,
.section-block.pt-70 {
  padding-top: 7rem;
}

.pt-80,
.section-block.pt-80 {
  padding-top: 8rem;
}

.pt-90,
.section-block.pt-90 {
  padding-top: 9rem;
}

.pt-100,
.section-block.pt-100 {
  padding-top: 10rem;
}

.pt-110,
.section-block.pt-110 {
  padding-top: 11rem;
}

.pt-120,
.section-block.pt-120 {
  padding-top: 12rem;
}

.pt-130,
.section-block.pt-130 {
  padding-top: 13rem;
}

.pt-140,
.section-block.pt-140 {
  padding-top: 14rem;
}

.pt-150,
.section-block.pt-150 {
  padding-top: 15rem;
}

.pb-0,
.section-block.pb-0 {
  padding-bottom: 0;
}

.pb-5,
.section-block.pb-5 {
  padding-bottom: 0.5rem;
}

.pb-10,
.section-block.pb-10 {
  padding-bottom: 1rem;
}

.pb-20,
.section-block.pb-20 {
  padding-bottom: 2rem;
}

.pb-30,
.section-block.pb-30 {
  padding-bottom: 3rem;
}

.pb-40,
.section-block.pb-40 {
  padding-bottom: 4rem;
}

.pb-50,
.section-block.pb-50 {
  padding-bottom: 5rem;
}

.pb-60,
.section-block.pb-60 {
  padding-bottom: 6rem;
}

.pb-70,
.section-block.pb-70 {
  padding-bottom: 7rem;
}

.pb-80,
.section-block.pb-80 {
  padding-bottom: 8rem;
}

.pb-90,
.section-block.pb-90 {
  padding-bottom: 9rem;
}

.pb-100,
.section-block.pb-100 {
  padding-bottom: 10rem;
}

.pb-110,
.section-block.pb-110 {
  padding-bottom: 11rem;
}

.pb-120,
.section-block.pb-120 {
  padding-bottom: 12rem;
}

.pb-130,
.section-block.pb-130 {
  padding-bottom: 13rem;
}

.pb-140,
.section-block.pb-140 {
  padding-bottom: 14rem;
}

.pb-150,
.section-block.pb-150 {
  padding-bottom: 15rem;
}

[class*="pu-"],
[class*="pd-"] {
  position: relative;
}

.pd-10 {
  top: 1rem;
}

.pd-20 {
  top: 2rem;
}

.pd-30 {
  top: 3rem;
}

.pd-40 {
  top: 4rem;
}

.pd-50 {
  top: 5rem;
}

.pd-60 {
  top: 6rem;
}

.pd-70 {
  top: 7rem;
}

.pd-80 {
  top: 8rem;
}

.pd-90 {
  top: 9rem;
}

.pd-100 {
  top: 10rem;
}

.pd-110 {
  top: 11rem;
}

.pd-120 {
  top: 12rem;
}

.pd-130 {
  top: 13rem;
}

.pd-140 {
  top: 14rem;
}

.pd-150 {
  top: 15rem;
}

.pu-10 {
  top: -1rem;
}

.pu-20 {
  top: -2rem;
}

.pu-30 {
  top: -3rem;
}

.pu-40 {
  top: -4rem;
}

.pu-50 {
  top: -5rem;
}

.pu-60 {
  top: -6rem;
}

.pu-70 {
  top: -7rem;
}

.pu-80 {
  top: -8rem;
}

.pu-90 {
  top: -9rem;
}

.pu-100 {
  top: -10rem;
}

.pu-110 {
  top: -11rem;
}

.pu-120 {
  top: -12rem;
}

.pu-130 {
  top: -13rem;
}

.pu-140 {
  top: -14rem;
}

.pu-150 {
  top: -15rem;
}

@media only screen and (max-width: 768px) {
  .mt-mobile-0 {
    margin-top: 0;
  }

  .mt-mobile-5 {
    margin-top: 0.5rem;
  }

  .mt-mobile-10 {
    margin-top: 1rem;
  }

  .mt-mobile-20 {
    margin-top: 2rem;
  }

  .mt-mobile-30 {
    margin-top: 3rem;
  }

  .mt-mobile-40 {
    margin-top: 4rem;
  }

  .mt-mobile-50 {
    margin-top: 5rem;
  }

  .mt-mobile-60 {
    margin-top: 6rem;
  }

  .mt-mobile-70 {
    margin-top: 7rem;
  }

  .mt-mobile-80 {
    margin-top: 8rem;
  }

  .mt-mobile-90 {
    margin-top: 9rem;
  }

  .mt-mobile-100 {
    margin-top: 10rem;
  }

  .mt-mobile-110 {
    margin-top: 11rem;
  }

  .mt-mobile-120 {
    margin-top: 12rem;
  }

  .mt-mobile-130 {
    margin-top: 13rem;
  }

  .mt-mobile-140 {
    margin-top: 14rem;
  }

  .mt-mobile-150 {
    margin-top: 15rem;
  }

  .mb-mobile-0 {
    margin-bottom: 0;
  }

  .mb-mobile-5 {
    margin-bottom: 0.5rem;
  }

  .mb-mobile-10 {
    margin-bottom: 1rem;
  }

  .mb-mobile-20 {
    margin-bottom: 2rem;
  }

  .mb-mobile-30 {
    margin-bottom: 3rem;
  }

  .mb-mobile-40 {
    margin-bottom: 4rem;
  }

  .mb-mobile-50 {
    margin-bottom: 5rem;
  }

  .mb-mobile-60 {
    margin-bottom: 6rem;
  }

  .mb-mobile-70 {
    margin-bottom: 7rem;
  }

  .mb-mobile-80 {
    margin-bottom: 8rem;
  }

  .mb-mobile-90 {
    margin-bottom: 9rem;
  }

  .mb-mobile-100 {
    margin-bottom: 10rem;
  }

  .mb-mobile-110 {
    margin-bottom: 11rem;
  }

  .mb-mobile-120 {
    margin-bottom: 12rem;
  }

  .mb-mobile-130 {
    margin-bottom: 13rem;
  }

  .mb-mobile-140 {
    margin-bottom: 14rem;
  }

  .mb-mobile-150 {
    margin-bottom: 15rem;
  }

  [class*="pu-"],
  [class*="pd-"] {
    top: 0;
  }
}

.signInputErrorMsg {
  margin-left: 2rem;
  font-size: 1.2rem;
  color: red;
}

.mypageInputErrorMsg {
  font-size: 1.2rem;
  color: red;
  margin-left: 0;
  margin-bottom: 2rem;
}

.emailConfirmButton {
  width: 10rem;
  height: 4.7rem;
  padding: 0.875rem;
  margin: 0 0 0 0.0625rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  line-height: 1.1375rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(103, 115, 241, 1);
  border-radius: 0.1875rem;

  &:hover {
    background-color: rgba(164, 171, 247, 1);
    color: rgba(255, 255, 255, 1);
  }

  &:disabled {
    background-color: rgba(164, 171, 247, 1);
    cursor: not-allowed;
  }
}

.containButton {
  display: flex;
  flex-direction: row;
}

@media (max-width: 960px) {
  .containButton {
    flex-direction: column;
  }

  .emailConfirmButton {
    margin: 0;
    width: 100%;
  }
}

.authCodeInput {
  &:read-only {
    color: #666;
    cursor: not-allowed;
    &:focus {
      border: 1px solid #ecedf2;
      background-color: #fafbfc;
      color: #666;
      &::placeholder {
        color: #666;
      }
    }
  }
}

.margin-top-10 {
  margin-top: 10px !important;
}

.content-box {
  backface-visibility: hidden;
  transition:
    -webkit-transform 300ms cubic-bezier(0.11, 0.69, 0.66, 1.01) 0ms,
    opacity;
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  opacity: 1;
  visibility: visible;
  transform-origin: 50% 50% 0px;
}

.mypage-avatar {
  width: 70px;
  height: 70px;
  max-width: 70px;
  flex-shrink: 0;
  border-radius: 70px;
}

.mypage-userhandle {
  font-size: 32.9px;
  font-weight: 300;
  line-height: 39.53px;
  color: #212325;
  margin-left: 1.5625rem;
}

.month-containter {
  display: flex;
  height: 440px;
  padding: 0 15px 54px 15px;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.month-border {
  display: flex;
  width: 348px;
  height: fit-content;
  padding: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.0006rem;
  border-radius: 3px;
  border: 1px solid #ecedf2;
}

.plan-header {
  display: flex;
  width: 100%;
  height: 130px;
  padding: 30px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 3px;
  background: #fff;
}

.plan-name {
  display: flex;
  width: 58px;
  flex-direction: row;
  justify-content: center;
  color: #1b2126;
  text-align: center;
  font-size: 18.6px;
  font-weight: 500;
  line-height: 1.3962rem;
}

.plan-sub {
  width: 60px;
  height: 70px;
}

.plan-sub > .price-container {
  display: flex;
  flex-direction: row;
}

.price-container > .won {
  display: flex;
  width: 9.38px;
  height: 19.2px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #1b2126;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.price-container > .price {
  display: flex;
  width: 28px;
  height: 54px;
  flex-direction: column;
  flex-shrink: 0;
  color: #1b2126;
  font-size: 45px;
  font-weight: 300;
  line-height: 54px;
  margin-left: 20px;
}

.payment-thead {
  background: #f5f7f9;
  color: rgba(102, 102, 102, 1);
  text-align: right;
}

.payment-tbody {
  background: #fff;
}

.mypage-category {
  height: 80px !important;
}

.product-category {
  width: 17.3125rem;
}

.product-number {
  width: 15.0625rem;
}

.product-date,
.product-deadline {
  width: 11.625rem;
}

.product-price-table {
  width: 12.5rem;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.payment-tr {
  height: 74px;
}

.text-black {
  color: #000 !important;
}

.agreement-font-resize > h3 {
  font-weight: 400;
}

@media only screen and (max-width: 1140px) and (min-width: 965px) {
  .agreement-font-resize > h3 {
    font-size: 2.3rem;
  }
}

@media only screen and (max-width: 964px) and (min-width: 769px) {
  .agreement-font-resize > h3 {
    font-size: 1.8rem;
  }
}

.pass-table-title {
  font-size: 2.8rem;
  font-weight: 500;
  text-align: center;
  padding-bottom: 5rem;
  color: #000;
}

.pass-info {
  padding-top: 5rem !important;
  padding-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.market-pagination {
  padding: 0 0 0 2rem;
  width: 100%;
}

.color-dark {
  color: #666;
}

.only-dark-logo > .dark-logo {
  display: block !important;
  visibility: visible !important;
}

.bottom-border {
  border-bottom: 1px solid #eee !important;
}

.font-400 {
  font-weight: 400;
}

.hidden {
  visibility: hidden;
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
  .font-size-tablet {
    font-size: 1.8rem !important;
  }
}

.color-white {
  color: #ffffff !important;
}

.dropdown-div {
  cursor: pointer;
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
  .banner-h1 {
    font-size: 3.6rem;
  }
}

.iframe-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  object-fit: contain;
}

.iframe-container video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}

@media only screen and (min-width: 1345px) {
  .iframe-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    object-fit: contain;
    aspect-ratio: 22 / 12;
  }

  .iframe-container video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -15rem;
    left: -2.5rem;
    object-fit: contain;
  }
}

@media only screen and (max-width: 714px) {
  .iframe-container video {
    object-fit: fill;
    height: 450px;
    top: 0;
    left: -30rem;
  }
  .iframe-container {
    height: 450px;
    aspect-ratio: 16 / 6;
  }
}

@media only screen and (max-width: 813px) and (min-width: 715px) {
  .iframe-container video {
    object-fit: fill;
    height: 500px;
    left: -10rem;
    top: 0;
  }
  .iframe-container {
    height: 500px;
    aspect-ratio: 16 / 8;
  }
}

.slide-style {
  transition: -webkit-transform 1000ms ease 0ms;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  transform-origin: 50% 50% 0px;
  visibility: visible;
}

.caption-style {
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50% 0px;
  visibility: visible;
  transition-property: -webkit-transform;
  transition-duration: 800ms;
  transition-delay: 400ms;
  transition-timing-function: cubic-bezier(0.11, 0.69, 0.66, 1.01);
}

.tabs.rounded .tab-nav > li:first-child a {
  border-radius: 0.3rem 0 0;
}

.tabs .tab-nav > li.active a {
  border-left: 1px solid #ecedf2;
  border-right: 1px solid #ecedf2;
  border-top: 1px solid #ecedf2;
  border-bottom: 1px solid transparent;
  background-color: #fff;
}

.node-table {
  padding: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
  .node-tab-panes {
    padding: 0.5rem !important;
  }
  .node-table-td {
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .node-tab-panes {
    padding: 0.5rem !important;
  }
  .node-table-td {
    padding-right: 0 !important;
  }
}

@keyframes nodeSlideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.payment-tbody .slide-out {
  animation: nodeSlideOutLeft 0.5s forwards;
}

.min-width-729 {
  min-width: 729px;
}

.justify-between {
  justify-content: space-between;
}

.add-button {
  padding: 1.3rem 5rem !important;
  font-size: 1.3rem;
  margin: 0 0 1rem 0 !important;
}

.prod-detail-page {
  border: 1px solid #eee !important;
  color: #959faa !important;
}

@media only screen and (max-width: 960px) {
  .prod-detail-page {
    color: #fff !important;
    border-color: transparent !important;
  }
}

.prod-detail-page:hover {
  color: #fff !important;
}

@media only screen and (max-width: 350px) {
  .login-form-container .row {
    margin-left: 0 !important;
  }
  .register-form-container .row {
    margin-left: 0 !important;
  }
  .signInputErrorMsg {
    font-size: 1.1rem;
  }
  .signup-form-container .row {
    margin-left: 0 !important;
  }
}

.layer1 {
  position: relative;
  width: 100%;
  height: 100vh;
}

.layer1 .video_area {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
  .welcome-logo-image {
    width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .welcome-logo-image {
    width: 180px;
  }
}

@media only screen and (max-width: 473px) {
  .welcome-banner-h1 {
    text-size-adjust: auto;
    font-size: calc(1.5rem + 2vw);
  }
  .welcome-logo-image {
    width: 150px;
  }
}

.welcome-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 320px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}

.welcome-content h2 {
  color: #fff;
  font-weight: 400;
}

.welcome-notice-container {
  flex: 1;
  padding: 20px 0px 20px 20px;
  text-align: left;
  width: 655px;
}
.welcome-event-container {
  flex: 2;
  padding: 20px 0px 20px 20px;
  text-align: left;
  margin: auto 0;
  width: "66.67vw";
}

.welcome-notice {
  color: #fff;
}

.welcome-notice:hover {
  color: #969696;
}

@media only screen and (min-width: 1442px) {
  .welcome-notice {
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 1320px) and (max-width: 1441px) {
  .welcome-notice {
    font-size: 1.55rem;
  }
}

.slick-slide img {
  width: 100%;
  height: auto;
}

.carousel-container {
  position: relative;
  width: 63.5vw;
  height: 220px;
  margin: 0;
}

.pagination {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.slick-dots li {
  width: 5px !important;
  height: 5px !important;
}

.slick-dots li button:before {
  color: #fff !important;
  opacity: 0.25;
  margin: 0 0 0 0.8rem;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
  opacity: 1;
  margin: 0 0 0 0.8rem;
}

.slick-prev {
  left: 2px !important;
  z-index: 999;
  width: 4rem !important;
  height: 4rem !important;
}

.slick-next {
  right: 0 !important;
  z-index: 999;
  width: 4rem !important;
  height: 4rem !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 35px;
}

.slider-image {
  width: 350px !important;
  height: 180px !important;
  border-radius: 5px;
  object-fit: cover;
  margin: 0.3rem 10px 0 0;
}

.welcome-no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.welcome-title-container {
  margin: auto 0;
}

.tab-nav {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-nav li {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.tab-nav::-webkit-scrollbar {
  height: 6px;
}

.tab-nav::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.tab-nav::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 480px) {
  .network-tab li {
    width: 82px !important;
  }
}

.network-tab li {
  text-align: center;
}

.date-container,
.date-input {
  display: flex;
  justify-content: center;
  gap: 10px;
}

@media only screen and (max-width: 960px) {
  .date-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
  .amount {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .amount {
    font-size: 1.2rem;
  }
  .item-date {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1140px) {
  .point-thead tr th {
    font-size: 1.25rem !important;
  }
}

@media only screen and (max-width: 480px) {
  .point-tab li {
    width: 130px;
  }
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.table {
  min-width: 430px;
  width: 100%;
}

@media only screen and (max-width: 350px) {
  .total-point {
    flex-direction: column;
  }

  .total-point-number {
    font-size: 1.9rem !important;
  }
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .right-align {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .right-align {
    display: flex;
    justify-content: center;
  }

  .tilde {
    display: none;
  }

  .date-input {
    flex-direction: column;
  }
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
  .event-card-title {
    font-size: 1.5rem !important;
  }
}

@media only screen and (width: 769px) {
  .event-card-container {
    padding-left: 7rem !important;
  }
}

.avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #6773f1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

.avatar:hover {
  background-color: #717dfb;
}

.hover-717dfb:hover {
  background-color: #717dfb !important;
  border-color: #717dfb !important;
}

.hover-717dfb-color-white:hover {
  background-color: #717dfb !important;
  border-color: #717dfb !important;
  color: #fff !important;
}

.icon-down {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.user-avatar-handle.hover-effect:hover {
  color: #555;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.cart-table {
  min-width: 300px;
}

.cart-checkout {
  color: #fff !important;
}
